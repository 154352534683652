/*-----------------------------------------------------------------------------------

    Template Name: Template Name
    Version: 1.0

-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    01. Template default CSS

-----------------------------------------------------------------------------------*/
@import "./bootstrap/functions";
@import "variables";
@import "./bootstrap/bootstrap";

@import "common";
@import "component";
@import "header";
@import "page";
@import "widget";
@import "footer";
