/*----------------------------------------*/
/*  06. Footer CSS
/*----------------------------------------*/

// Footer Section 
.footer-section{}


// Footer Logo
.footer-top{
    background-color: #e9e1f7;
    padding-top: 10px;
    padding-bottom: 40px;
}

// Footer Logo
.footer-logo{
    margin-top: 30px;

    & a{
        & img{}
    }
}

// Footer Contact & Payment
.footer-contact-payment{
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media #{$small-mobile}{
        display: block;
    }

    & .footer-contact{
        display: flex;
        margin-top: 30px;
        
        & .contact-icon{
            & img{}
        }
        & .contact-content{
            flex: 1;
            padding-left: 20px;
            margin-bottom: -5px;

            & .title{
                font-size: 24px;
                color: $secondary;
                font-weight: 600;
                line-height: 1.1;
                margin-bottom: 0;
            }
            & p{
                font-size: 24px;
                color: $secondary;
                font-weight: 600;
                font-family: $headings-font-family;
                line-height: 1.1;
            }
        }
    }
    & .footer-payment{
        margin-top: 30px;
    }
}


// Footer Subscribe 
.footer-subscribe{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    
    @media #{$small-mobile}{
        display: block;
    }

    & .title{
        font-size: 24px;
        font-weight: 600;
        font-family: $headings-font-family;
        color: $secondary;
        white-space: nowrap;
        padding-right: 15px;
    }
    & .subscribe-form{
        position: relative;
        max-width: 276px;
        width: 100%;

        @media #{$desktop-device, $tablet-device,  $large-mobile}{
            max-width: 100%;
        }

        & input{
            width: 100%;
            height: 46px;
            padding: 0 15px;
            padding-right: 60px;
            background-color: #f4edff;
            font-family: $headings-font-family;
            font-size: 14px;
            color: #919090;
            border: 1px solid #f4edff;
            font-weight: 600;
            outline: none;
            transition: $transition-base;

            @include placeholder{
                opacity: 1;
                color: #919090;
            }
            &:focus{
                border-color: $primary;
            }
        }
        & button{
            width: 46px;
            height: 46px;
            line-height: 50px;
            padding: 0;
            border: 0;
            background-color: $primary;
            color: $white;
            font-size: 26px;
            text-align: center;
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}


// Footer Copyright
.copyright{
    background-color: $white;
}

.copyright-text{
    text-align: center;
    padding: 12px 0;

    & p{
        color: #8690a1;
        font-size: 16px;
        font-weight: 300;

        & span{
            color: $primary;
            text-transform: uppercase;
        }

        & i{
            color: #ff0000;
        }

        & a{
            color: $primary;
            transition: $transition-base;
            font-weight: 500;

            &:hover{
                color: $primary;
            }
        }
    }
}


// Back to Top
.back-to-top{
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 30px;
    width: 40px;
    height: 40px;
    line-height: 45px;
    border-radius: 50%;
    z-index: 99;
    text-align: center;
    display: none;
    box-shadow: 0px 0px 30px 0px rgba(156,156,156,0.25);    
    transition: all 0.3s linear;
    color: $white;
    background-color: $primary;

    &:hover{
        color: $white;
        background-color: $dark;    
    }
}

