/*----------------------------------------*/
/*  04. Page CSS
/*----------------------------------------*/

@import "page/slider";
@import "page/banner";
@import "page/product";
@import "page/call-to-action";
@import "page/benefit";
@import "page/products-banner";
@import "page/blog";
@import "page/countdown";
@import "page/marker";
@import "page/brand";
@import "page/page-banner";
@import "page/history";
@import "page/gallery";
@import "page/counter";
@import "page/testimonial";
@import "page/team";
@import "page/shop";
@import "page/product-details";
@import "page/cart";
@import "page/compare";
@import "page/checkout";
@import "page/my-account";
@import "page/login";
@import "page/blog-details";
@import "page/contact";








