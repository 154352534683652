/*--
/*  4.8 - Countdown CSS
/*----------------------------------------*/

// Countdown Content 
.countdown-content{
    margin-top: 45px;

    & .title{
        font-weight: 700;
        font-size: 48px;
        color: #303030;
        margin-bottom: 0;

        @media #{$desktop-device}{
            font-size: 42px;
        }
        @media #{$small-mobile}{
            font-size: 28px;
        }

        & span{
            color: $primary;
        }
    }
    & p{
        max-width: 470px;
        color: #6a6a69;
        font-size: 14px;
        padding-top: 15px;
    }

    & .btn{
        padding: 0 40px;
        height: 50px;
        line-height: 50px;
        font-weight: 500;
        margin-top: 40px;
    }
}


// Countdown Wapper
.countdown-wrapper{
    & .countdown{
        display: flex;
        
        & .single-countdown{
            text-align: center;
            position: relative;
            margin-top: 5px;

            & + .single-countdown{
                margin-left: 70px;

                @media #{$desktop-device}{
                    margin-left: 60px;
                }
                @media #{$small-mobile}{
                    margin-left: 40px;
                }

                &::before{
                    position: absolute;
                    top: 0;
                    left: -40px;
                    content: ':';
                    font-family: $headings-font-family;
                    font-weight: 700;
                    color: #303030;
                    font-size: 60px;
                    line-height: 1.3;

                    @media #{$desktop-device}{
                        font-size: 50px;
                        left: -37px;
                    }
                    @media #{$small-mobile}{
                        font-size: 30px;
                        left: -24px;
                    }
                }
            }

            & .count{
                font-size: 60px;
                font-weight: 700;
                font-family: $headings-font-family;
                color: #303030;
                display: block;
                line-height: 1.3;

                @media #{$desktop-device}{
                    font-size: 50px;
                }
                @media #{$small-mobile}{
                    font-size: 30px;
                }
            }
            & .value{
                font-size: 16px;
                font-weight: 600;
                font-family: $headings-font-family;
                text-transform: uppercase;
                color: #303030;
                display: block;
            }
        }
    }
}

// Countdown Images
.countdown-images{
    position: relative;
    z-index: 1;
    text-align: right;
    margin-top: 50px;

    & .shape-1{
        border-radius: 50%;
        background-color: #ffb300;
        position: absolute;
        left: 50px;
        top: 0px;
        width: 327px;
        height: 327px;
        z-index: -1;
    }
    & .shape-2{
        background: -moz-linear-gradient( 0deg, rgb(248,163,36) 0%, rgb(239,73,35) 100%);
        background: -webkit-linear-gradient( 0deg, rgb(248,163,36) 0%, rgb(239,73,35) 100%);
        background: -ms-linear-gradient( 0deg, rgb(248,163,36) 0%, rgb(239,73,35) 100%);
        box-shadow: 0px 0px 24px 6px rgba(104, 104, 104, 0.16);
        position: absolute;
        left: 140px;
        top: 55px;
        width: 58px;
        height: 58px;
        z-index: -1;
        border-radius: 50%;
    }
    & .shape-3{
        border-radius: 50%;
        background-color: #ffa987;
        position: absolute;
        right: 50px;
        bottom: -176px;
        width: 278px;
        height: 278px;
        z-index: -1;
    }

    & .image-box{
        & img{
            
        }
    }
}

  







