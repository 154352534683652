/*--
/*  4.16 - Product Details CSS
/*----------------------------------------*/

// Product Details Images 
.product-details-images{
    margin-top: 50px;
}

// Details Gallery Images 
.details-gallery-images{
    & .single-img{
        & img{
            width: 100%;
        }
    }
}

// Details Gallery Thumbs 
.details-gallery-thumbs{
    position: relative;

    @media #{$small-mobile}{
        padding: 0 30px;
    }

    & .swiper-container{
        max-width: 410px;
        margin: 20px auto 0;

        @media #{$small-mobile}{
            max-width: 100%;
        }

        & img{
            width: 100%;
        }
    }

    & .swiper-slide{
        opacity: 0.2;
        cursor: pointer;
        transition: $transition-base;

        &.swiper-slide-thumb-active{
            opacity: 1;
        }
    }

    & .swiper-button-next,
    & .swiper-button-prev{
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border: 1px solid #e7e7e7;
        background-color: #f8f8f8;
        display: block;
        color: #e6e6e6;
        border-radius: 50%;
        font-size: 28px;
        transition: $transition-base;
        outline: none;
        opacity: 1;
        margin-top: 0;
        transform: translateY(-50%);

        @media #{$small-mobile}{
            width: 25px;
            height: 25px;
            line-height: 25px;
            font-size: 20px;
        }

        &:hover{
            color: $white;
            background-color: $primary;
            border-color: $primary;
        }

        &::after{
            display: none;
        }
    }

    & .swiper-button-next{
        right: 0;
    }
    & .swiper-button-prev{
        left: 0;
    }
}


// Product Details Images 
.product-details-description{
    margin-top: 50px;

    &.product-details-sticky {
        position: -webkit-sticky;
        position: sticky;
        top: 130px;
        left: 0;
        width: 100%;
    }

    & .product-name{
        font-size: 30px;
        font-weight: 600;
        color: $black;
        margin-bottom: 0;

        @media #{$small-mobile}{
            font-size: 24px;
        }
    }
    & .price{
        display: flex;
        align-items: center;

        & .sale-price{
            font-size: 24px;
            color: $primary;
            margin-top: 5px;      
            
            @media #{$small-mobile}{
                font-size: 20px;
            }
        }
        & .old-price{
            font-size: 20px;
            color: #a4a4a4;
            margin-top: 5px;
            margin-left: 10px;
            text-decoration: line-through;

            @media #{$small-mobile}{
                font-size: 16px;
            }
        }
    }
    & .review-wrapper{
        display: flex;
        flex-wrap: wrap;
        padding-top: 12px;

        & .review-star{
            position: relative;
            display: inline-block;
            margin-right: 8px;

            &::before{
                content: "\f005" "\f005" "\f005" "\f005" "\f005";
                font-family: FontAwesome;
                display: inline-block;
                color: #dbdbdb;
                font-weight: 400;
                line-height: 1;
                vertical-align: middle;
                font-size: 16px;
                letter-spacing: 10px;
                @media #{$small-mobile}{
                    letter-spacing: 5px;
                }
            }
            & .star{
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                z-index: 2;
                overflow: hidden;

                &::before{
                    content: "\f005" "\f005" "\f005" "\f005" "\f005";
                    font-family: FontAwesome;
                    display: inline-block;
                    color: #ffc600;
                    font-weight: 400;
                    line-height: 1;
                    vertical-align: middle;
                    font-size: 16px;
                    letter-spacing: 10px;
                    @media #{$small-mobile}{
                        letter-spacing: 5px;
                    }
                }
            }
        }
        & p{
            font-size: 16px;
            font-family: $headings-font-family;
            font-weight: 600;
            color: #9f9e9e;
            margin-top: 0;
            margin-bottom: 0;
        }
    }
    & .lable{
        color: #3f3d3d;
        font-size: 16px;
        font-family: $headings-font-family;
        font-weight: 600;
        margin-right: 5px;
    }
    & .value{
        color: #9f9e9e;
        font-size: 16px;
        font-family: $headings-font-family;
        font-weight: 600;
    }
    & .product-color{
        display: flex;
        padding-top: 25px;

        & ul{
            display: flex;

            & li{
                margin-left: 15px;

                & input[type="checkbox"],
                & input[type="radio"]{
                    display: none;

                    & + label{
                        margin-bottom: 0;
                        font-weight: 600;
                        font-size: 16px;
                        color: #666666;
                        font-family: $headings-font-family;
                        cursor: pointer;
                        display: block;

                        & span{
                            width: 15px;
                            height: 15px;
                            border-radius: 3px;
                            border: 2px solid #888888;
                            display: inline-block;
                            position: relative;
                            top: 2px;
                            transition: $transition-base;

                            &::before{
                                position: absolute;
                                content: "\f107";
                                font-family: FontAwesome;
                                font-size: 14px;
                                width: 15px;
                                height: 15px;
                                line-height: 15px;
                                top: -3px;
                                left: -2px;
                                text-align: center;
                                color: $white;
                                opacity: 0;
                                visibility: hidden;
                                transition: $transition-base;
                            }
                        }
                    }
                    &:checked{
                        & + label{
                            & span{
                                background-color: #ffb300;
                                border-color: #ffb300;

                                &::before{
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
    & p{
        line-height: 1.8;
        color: #6c788c;
        margin-bottom: 0;
        margin-top: 25px;
    }

    & .product-meta{
        display: flex;
        padding-top: 30px;
        flex-wrap: wrap;

        & > *{
            margin-right: 10px;
        }
    }
    & .product-quantity{
        position: relative;
        margin-top: 10px;
        background-color: $dark;
        border-radius: 5px;

        & button{
            background: none;
            color: $white;
            height: 50px;
            border: 0;
            font-weight: 500;
            font-size: 16px;
            padding: 0;
        }
        & input{
            width: 35px;
            height: 50px;
            border: 0;
            padding: 0 5px;
            background: none;
            font-size: 14px;
            font-weight: 500;
            outline: none;
            color: $white;
            text-align: center;
        }

        & > * {
            &:first-child{
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                padding-left: 16px;
            }
            &:last-child{
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                padding-right: 16px;
            }
        }
    }
    & .meta-action{
        & .btn{
            height: 50px;
            line-height: 50px;
            padding: 0 30px;
            font-weight: 500;
            font-size: 14px;
            margin-top: 10px;
        }
        & .action{
            width: 50px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 23px;
            color: $white;
            background-color: $dark;
            display: block;
            border-radius: 5px;
            margin-top: 10px;
            transition: $transition-base;

            &:hover{
                background-color: $primary;
            }
        }
    }

    & .product-info{
        padding-top: 33px;

        & .single-info{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 8px;
        }
    }
    & .social{
        display: flex;
        margin-left: 10px;

        & li{
            margin-right: 25px;

            & a{
                font-size: 16px;
                color: #9f9e9e;
                transition: $transition-base;

                &:hover{
                    color: $primary;
                }
            }
        }
    }

    & .product-table{
        margin-top: 40px;

        & .table{
            margin-bottom: 0;

            & tbody{
                & tr{
                    & td{
                        border: 1px solid #e1e1e1;
                        vertical-align: middle;
                        text-align: center;
                        font-size: 14px;
                        font-weight: 400;
                        color: $body-color;
                        width: 33.33%;

                        & .product-quantity{
                            margin-top: 0;
                        }

                        & .amount{
                            font-weight: 500;
                            color: $primary;
                        }
                    }
                }
            }
        }
    }    
    
}


// Product Details tabs 
.product-details-tabs{
    & .nav{
        border-bottom: 1px solid #d7d7d7;
        padding-bottom: 8px;

        @media #{$small-mobile}{
            flex-direction: column;
            align-items: center;
        }

        & li{
            margin: 0 5px;

            & button{
                color: #cccccc;
                font-size: 24px;
                font-weight: 600;
                font-family: $headings-font-family;
                border: 0;
                background: none;
                padding: 0 10px;
                position: relative;
                transition: $transition-base;

                &::before{
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 2px;
                    background-color: $primary;
                    bottom: -9px;
                    left: 0;
                    opacity: 0;
                    visibility: hidden;
                    transition: $transition-base;

                    @media #{$small-mobile}{
                        display: none;
                    }
                }

                &.active{
                    color: $primary;

                    &::before{
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }

    & .tab-content{
        max-width: 970px;
        margin: 0 auto;
    }
}

// Information Content 
.information-content{
    padding-top: 35px;
    
    & .title{
        font-size: 20px;
        font-weight: 600;
        color: $black;
    }
    & p{}
    & ul{
        list-style: disc;
        list-style-position: inside;
        margin-bottom: 1rem;
        
        & li{
            font-size: 15px;
            line-height: 1.8;
        }
    }
    
    & > *{
        &:last-child{
            margin-bottom: 0;
        }
    }
}

// Description Content 
.description-content{
    text-align: center;
    margin-top: 35px;
    
    & p{}
}

// Review Content 
.reviews-content{
    padding-top: 10px;

    & .reviews-title{
        font-weight: 700;
        font-size: 24px;
        color: $black;
    }
}


// Single Reviews 
.single-reviews{
    display: flex;
    margin-top: 30px;

    @media #{$small-mobile}{
        display: block;
    }

    & .comment-author{
        & img{
            border-radius: 50%;
        }
    }
    & .comment-content{
        flex: 1;
        padding-left: 30px;

        @media #{$small-mobile}{
            padding-top: 25px;
            padding-left: 0;
        }

        & .author-name-rating{
            display: flex;
            align-items: center;

            & .name{
                font-weight: 600;
                text-transform: capitalize;
                font-size: 16px;
                color: #333333;    
                margin-bottom: 0;
                line-height: 1;  
                margin-right: 10px;          
            }
            & .review-star{
                position: relative;
                display: inline-block;
    
                &::before{
                    content: "\f005" "\f005" "\f005" "\f005" "\f005";
                    font-family: FontAwesome;
                    display: inline-block;
                    color: #dbdbdb;
                    font-weight: 400;
                    line-height: 1;
                    vertical-align: middle;
                    font-size: 12px;
                    letter-spacing: 2px;
                }
                & .star{
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    z-index: 2;
                    overflow: hidden;
    
                    &::before{
                        content: "\f005" "\f005" "\f005" "\f005" "\f005";
                        font-family: FontAwesome;
                        display: inline-block;
                        color: #ffc600;
                        font-weight: 400;
                        line-height: 1;
                        vertical-align: middle;
                        font-size: 12px;
                        letter-spacing: 2px;
                    }
                }
            }
        }
        & .date{
            color: #adadad;
            font-size: 14px;
            margin-top: 5px;
        }
        & p{
            margin-top: 20px;
        }
    }
}

// Review Form 
.reviews-form{
    margin-top: 85px;

    & .review-rating{
        display: flex;
        align-items: center;
        padding-top: 35px;

        & .title {
            font-weight: 600;
            font-size: 16px;
            font-family: $headings-font-family;
            color: $dark;
            margin-bottom: 0;
            display: inline-block;
            white-space: nowrap;
            margin-right: 10px;
        }

        & ul{
            display: flex;
            padding: 0;
            margin: 0;
            list-style: none;

            & li{
                margin: 0 2px;
                font-size: 16px;
                cursor: pointer;
                
                & i{
                    transition: $transition-base;
                    color: #ffc600;

                    &.hover{
                        &::before {
                            content: "\f005" !important;                            
                        }
                    }
                }
            }
        }
    }

    & .form-title{
        font-size: 24px;
        font-weight: 400;
        color: $dark;
    }
    & .form-check{
        margin-top: 30px;

        & .form-check-input{
            margin-top: 3px;
        }
    }
}