/*-----------------------------------------------------------------------------------

    Template Name: Template Name
    Version: 1.0

-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    01. Template default CSS

-----------------------------------------------------------------------------------*/
/*!
 * Bootstrap v5.0.0-beta2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&family=Rajdhani:wght@300;400;500;600;700&family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
:root {
  --bs-blue: #0d6efd;
  --bs-dark-blue: #004a80;
  --bs-lower-blue: #8194d9;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #f2a100;
  --bs-secondary: #252c51;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #494949;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Rubik", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6d707f;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Rajdhani", sans-serif;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}

@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #f2a100;
  text-decoration: underline;
}

a:hover {
  color: #c28100;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 15px);
  padding-left: var(--bs-gutter-x, 15px);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1200px;
  }
}

@media (min-width: 1600px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1200px;
  }
}

.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.3125rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.3125rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.625rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.625rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 0.9375rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 0.9375rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.25rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.25rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 1.625rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 1.625rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 1.875rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 1.875rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 2.1875rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 2.1875rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 2.5rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 2.5rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 2.8125rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 2.8125rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 3.125rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 3.125rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 1.625rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 1.625rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 3.125rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 1.625rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 1.625rem;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 3.125rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 1.625rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 1.625rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 3.125rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 1.625rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 1.625rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 3.125rem;
  }
}

@media (min-width: 1600px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 1.625rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 1.625rem;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 3.125rem;
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-striped-color: #6d707f;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #6d707f;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #6d707f;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #6d707f;
  vertical-align: top;
  border-color: #dee2e6;
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #fceccc;
  --bs-table-striped-bg: #efe0c2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e3d4b8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e9dabd;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e3d4b8;
}

.table-secondary {
  --bs-table-bg: #d3d5dc;
  --bs-table-striped-bg: #c8cad1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bec0c6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c3c5cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bec0c6;
}

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd0c7;
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3;
}

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dbb9;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4;
}

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1;
}

.table-dark {
  --bs-table-bg: #494949;
  --bs-table-striped-bg: #525252;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #5b5b5b;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #575757;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #5b5b5b;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1599.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0 1rem;
  font-family: "Rubik", sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6d707f;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #6d707f;
  background-color: #fff;
  border-color: #f9d080;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(242, 161, 0, 0);
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0 1rem;
  margin: 0 -1rem;
  margin-inline-end: 1rem;
  color: #6d707f;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 0;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control::-webkit-file-upload-button {
  padding: 0 1rem;
  margin: 0 -1rem;
  margin-inline-end: 1rem;
  color: #6d707f;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 0;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #6d707f;
  background-color: transparent;
  border: solid transparent;
  border-width: 0 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: 1.5em;
}

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem);
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem);
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0 3rem 0 1rem;
  font-family: "Rubik", sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6d707f;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  border: 0 solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}

.form-select:focus {
  border-color: #f9d080;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(242, 161, 0, 0);
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1rem;
  background-image: none;
}

.form-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #6d707f;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #f9d080;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(242, 161, 0, 0);
}

.form-check-input:checked {
  background-color: #f2a100;
  border-color: #f2a100;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #f2a100;
  border-color: #f2a100;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23f9d080'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(242, 161, 0, 0);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(242, 161, 0, 0);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #f2a100;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #fbe3b3;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #f2a100;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #fbe3b3;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control,
.form-floating > .form-select {
  height: 3.5rem;
  padding: 1rem 1rem;
}

.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 1rem;
  pointer-events: none;
  border: 0 solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control::placeholder {
  color: transparent;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6d707f;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 0 solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 4rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: 1.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.375em center;
  background-size: 0.75em 0.75em;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0 rgba(25, 135, 84, 0);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: 1.5em;
  background-position: top 0.375em right 0.375em;
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
  padding-right: 5.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 1rem center, center right 3rem;
  background-size: 16px 12px, 0.75em 0.75em;
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0 rgba(25, 135, 84, 0);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0 rgba(25, 135, 84, 0);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: 1.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.375em center;
  background-size: 0.75em 0.75em;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: 1.5em;
  background-position: top 0.375em right 0.375em;
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
  padding-right: 5.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 1rem center, center right 3rem;
  background-size: 16px 12px, 0.75em 0.75em;
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.btn {
  display: inline-block;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 1.875rem;
  color: #6d707f;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 0 solid transparent;
  padding: 0 1rem;
  font-size: 0.75rem;
  border-radius: 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #6d707f;
}

.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0;
}

.btn:disabled, .btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #000;
  background-color: #f2a100;
  border-color: #f2a100;
}

.btn-primary:hover {
  color: #000;
  background-color: #f4af26;
  border-color: #f3aa1a;
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #000;
  background-color: #f4af26;
  border-color: #f3aa1a;
  box-shadow: 0 0 0 0 rgba(206, 137, 0, 0.5);
}

.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: #000;
  background-color: #f5b433;
  border-color: #f3aa1a;
}

.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(206, 137, 0, 0.5);
}

.btn-primary:disabled, .btn-primary.disabled {
  color: #000;
  background-color: #f2a100;
  border-color: #f2a100;
}

.btn-secondary {
  color: #fff;
  background-color: #252c51;
  border-color: #252c51;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #1f2545;
  border-color: #1e2341;
}

.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #1f2545;
  border-color: #1e2341;
  box-shadow: 0 0 0 0 rgba(70, 76, 107, 0.5);
}

.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #1e2341;
  border-color: #1c213d;
}

.btn-check:checked + .btn-secondary:focus,
.btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(70, 76, 107, 0.5);
}

.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #252c51;
  border-color: #252c51;
}

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-success:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
}

.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
  box-shadow: 0 0 0 0 rgba(60, 153, 110, 0.5);
}

.btn-check:checked + .btn-success,
.btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f;
}

.btn-check:checked + .btn-success:focus,
.btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(60, 153, 110, 0.5);
}

.btn-success:disabled, .btn-success.disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-info:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
}

.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: 0 0 0 0 rgba(11, 172, 204, 0.5);
}

.btn-check:checked + .btn-info,
.btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
.show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}

.btn-check:checked + .btn-info:focus,
.btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(11, 172, 204, 0.5);
}

.btn-info:disabled, .btn-info.disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
}

.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: 0 0 0 0 rgba(217, 164, 6, 0.5);
}

.btn-check:checked + .btn-warning,
.btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffc720;
}

.btn-check:checked + .btn-warning:focus,
.btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(217, 164, 6, 0.5);
}

.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}

.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: 0 0 0 0 rgba(225, 83, 97, 0.5);
}

.btn-check:checked + .btn-danger,
.btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}

.btn-check:checked + .btn-danger:focus,
.btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(225, 83, 97, 0.5);
}

.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
  box-shadow: 0 0 0 0 rgba(211, 212, 213, 0.5);
}

.btn-check:checked + .btn-light,
.btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
.show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-check:checked + .btn-light:focus,
.btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(211, 212, 213, 0.5);
}

.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-dark {
  color: #fff;
  background-color: #494949;
  border-color: #494949;
}

.btn-dark:hover {
  color: #fff;
  background-color: #3e3e3e;
  border-color: #3a3a3a;
}

.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #3e3e3e;
  border-color: #3a3a3a;
  box-shadow: 0 0 0 0 rgba(100, 100, 100, 0.5);
}

.btn-check:checked + .btn-dark,
.btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #3a3a3a;
  border-color: #373737;
}

.btn-check:checked + .btn-dark:focus,
.btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(100, 100, 100, 0.5);
}

.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #494949;
  border-color: #494949;
}

.btn-outline-primary {
  color: #f2a100;
  border-color: #f2a100;
}

.btn-outline-primary:hover {
  color: #000;
  background-color: #f2a100;
  border-color: #f2a100;
}

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0 rgba(242, 161, 0, 0.5);
}

.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #000;
  background-color: #f2a100;
  border-color: #f2a100;
}

.btn-check:checked + .btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(242, 161, 0, 0.5);
}

.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #f2a100;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #252c51;
  border-color: #252c51;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #252c51;
  border-color: #252c51;
}

.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0 rgba(37, 44, 81, 0.5);
}

.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #252c51;
  border-color: #252c51;
}

.btn-check:checked + .btn-outline-secondary:focus,
.btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(37, 44, 81, 0.5);
}

.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #252c51;
  background-color: transparent;
}

.btn-outline-success {
  color: #198754;
  border-color: #198754;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0 rgba(25, 135, 84, 0.5);
}

.btn-check:checked + .btn-outline-success,
.btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-check:checked + .btn-outline-success:focus,
.btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(25, 135, 84, 0.5);
}

.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #198754;
  background-color: transparent;
}

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0 rgba(13, 202, 240, 0.5);
}

.btn-check:checked + .btn-outline-info,
.btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-check:checked + .btn-outline-info:focus,
.btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(13, 202, 240, 0.5);
}

.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5);
}

.btn-check:checked + .btn-outline-warning,
.btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-check:checked + .btn-outline-warning:focus,
.btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5);
}

.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5);
}

.btn-check:checked + .btn-outline-danger,
.btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-check:checked + .btn-outline-danger:focus,
.btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5);
}

.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0 rgba(248, 249, 250, 0.5);
}

.btn-check:checked + .btn-outline-light,
.btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-check:checked + .btn-outline-light:focus,
.btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(248, 249, 250, 0.5);
}

.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-dark {
  color: #494949;
  border-color: #494949;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #494949;
  border-color: #494949;
}

.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0 rgba(73, 73, 73, 0.5);
}

.btn-check:checked + .btn-outline-dark,
.btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #494949;
  border-color: #494949;
}

.btn-check:checked + .btn-outline-dark:focus,
.btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(73, 73, 73, 0.5);
}

.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #494949;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #f2a100;
  text-decoration: underline;
}

.btn-link:hover {
  color: #c28100;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #6d707f;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu[data-bs-popper] {
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto /* rtl:ignore */;
  left: 0 /* rtl:ignore */;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0 /* rtl:ignore */;
  left: auto /* rtl:ignore */;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 1600px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
}

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
}

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #f2a100;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}

.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #f2a100;
}

.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}

.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: 0;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: 0;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #f2a100;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar > .container,
.navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 5px;
  transition: box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1600px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1rem /* rtl:ignore */;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #6d707f;
  text-align: left;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button.collapsed {
  border-bottom-width: 0;
}

.accordion-button:not(.collapsed) {
  color: #da9100;
  background-color: #fef6e6;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23da9100'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(180deg);
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236d707f'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #f9d080;
  outline: 0;
  box-shadow: 0;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-width: 1px;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-width: 1px;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-collapse {
  border: solid rgba(0, 0, 0, 0.125);
  border-width: 0 1px;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-button {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item:first-of-type .accordion-button {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion-flush .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #f2a100;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: #c28100;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  color: #c28100;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(242, 161, 0, 0);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #f2a100;
  border-color: #f2a100;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #916100;
  background-color: #fceccc;
  border-color: #fbe3b3;
}

.alert-primary .alert-link {
  color: #744e00;
}

.alert-secondary {
  color: #161a31;
  background-color: #d3d5dc;
  border-color: #bec0cb;
}

.alert-secondary .alert-link {
  color: #121527;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}

.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}

.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  color: #636464;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  color: #2c2c2c;
  background-color: #dbdbdb;
  border-color: #c8c8c8;
}

.alert-dark .alert-link {
  color: #232323;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #f2a100;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #6d707f;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #f2a100;
  border-color: #f2a100;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1600px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #916100;
  background-color: #fceccc;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #916100;
  background-color: #e3d4b8;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #916100;
  border-color: #916100;
}

.list-group-item-secondary {
  color: #161a31;
  background-color: #d3d5dc;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #161a31;
  background-color: #bec0c6;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #161a31;
  border-color: #161a31;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #2c2c2c;
  background-color: #dbdbdb;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #2c2c2c;
  background-color: #c5c5c5;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #2c2c2c;
  border-color: #2c2c2c;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(242, 161, 0, 0);
  opacity: 1;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.toast:not(.showing):not(.show) {
  opacity: 0;
}

.toast.hide {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container > :not(:last-child) {
  margin-bottom: 15px;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1599.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}

.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid #d8d8d8;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #6d707f;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #f2a100;
}

.link-primary:hover, .link-primary:focus {
  color: #f5b433;
}

.link-secondary {
  color: #252c51;
}

.link-secondary:hover, .link-secondary:focus {
  color: #1e2341;
}

.link-success {
  color: #198754;
}

.link-success:hover, .link-success:focus {
  color: #146c43;
}

.link-info {
  color: #0dcaf0;
}

.link-info:hover, .link-info:focus {
  color: #3dd5f3;
}

.link-warning {
  color: #ffc107;
}

.link-warning:hover, .link-warning:focus {
  color: #ffcd39;
}

.link-danger {
  color: #dc3545;
}

.link-danger:hover, .link-danger:focus {
  color: #b02a37;
}

.link-light {
  color: #f8f9fa;
}

.link-light:hover, .link-light:focus {
  color: #f9fafb;
}

.link-dark {
  color: #494949;
}

.link-dark:hover, .link-dark:focus {
  color: #3a3a3a;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1600px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #f2a100 !important;
}

.border-secondary {
  border-color: #252c51 !important;
}

.border-success {
  border-color: #198754 !important;
}

.border-info {
  border-color: #0dcaf0 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #494949 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-0 {
  border-width: 0 !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.3125rem !important;
}

.gap-2 {
  gap: 0.625rem !important;
}

.gap-3 {
  gap: 0.9375rem !important;
}

.gap-4 {
  gap: 1.25rem !important;
}

.gap-5 {
  gap: 1.625rem !important;
}

.gap-6 {
  gap: 1.875rem !important;
}

.gap-7 {
  gap: 2.1875rem !important;
}

.gap-8 {
  gap: 2.5rem !important;
}

.gap-9 {
  gap: 2.8125rem !important;
}

.gap-10 {
  gap: 3.125rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.3125rem !important;
}

.m-2 {
  margin: 0.625rem !important;
}

.m-3 {
  margin: 0.9375rem !important;
}

.m-4 {
  margin: 1.25rem !important;
}

.m-5 {
  margin: 1.625rem !important;
}

.m-6 {
  margin: 1.875rem !important;
}

.m-7 {
  margin: 2.1875rem !important;
}

.m-8 {
  margin: 2.5rem !important;
}

.m-9 {
  margin: 2.8125rem !important;
}

.m-10 {
  margin: 3.125rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.3125rem !important;
  margin-left: 0.3125rem !important;
}

.mx-2 {
  margin-right: 0.625rem !important;
  margin-left: 0.625rem !important;
}

.mx-3 {
  margin-right: 0.9375rem !important;
  margin-left: 0.9375rem !important;
}

.mx-4 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}

.mx-5 {
  margin-right: 1.625rem !important;
  margin-left: 1.625rem !important;
}

.mx-6 {
  margin-right: 1.875rem !important;
  margin-left: 1.875rem !important;
}

.mx-7 {
  margin-right: 2.1875rem !important;
  margin-left: 2.1875rem !important;
}

.mx-8 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-9 {
  margin-right: 2.8125rem !important;
  margin-left: 2.8125rem !important;
}

.mx-10 {
  margin-right: 3.125rem !important;
  margin-left: 3.125rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.3125rem !important;
  margin-bottom: 0.3125rem !important;
}

.my-2 {
  margin-top: 0.625rem !important;
  margin-bottom: 0.625rem !important;
}

.my-3 {
  margin-top: 0.9375rem !important;
  margin-bottom: 0.9375rem !important;
}

.my-4 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-5 {
  margin-top: 1.625rem !important;
  margin-bottom: 1.625rem !important;
}

.my-6 {
  margin-top: 1.875rem !important;
  margin-bottom: 1.875rem !important;
}

.my-7 {
  margin-top: 2.1875rem !important;
  margin-bottom: 2.1875rem !important;
}

.my-8 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-9 {
  margin-top: 2.8125rem !important;
  margin-bottom: 2.8125rem !important;
}

.my-10 {
  margin-top: 3.125rem !important;
  margin-bottom: 3.125rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.3125rem !important;
}

.mt-2 {
  margin-top: 0.625rem !important;
}

.mt-3 {
  margin-top: 0.9375rem !important;
}

.mt-4 {
  margin-top: 1.25rem !important;
}

.mt-5 {
  margin-top: 1.625rem !important;
}

.mt-6 {
  margin-top: 1.875rem !important;
}

.mt-7 {
  margin-top: 2.1875rem !important;
}

.mt-8 {
  margin-top: 2.5rem !important;
}

.mt-9 {
  margin-top: 2.8125rem !important;
}

.mt-10 {
  margin-top: 3.125rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.3125rem !important;
}

.me-2 {
  margin-right: 0.625rem !important;
}

.me-3 {
  margin-right: 0.9375rem !important;
}

.me-4 {
  margin-right: 1.25rem !important;
}

.me-5 {
  margin-right: 1.625rem !important;
}

.me-6 {
  margin-right: 1.875rem !important;
}

.me-7 {
  margin-right: 2.1875rem !important;
}

.me-8 {
  margin-right: 2.5rem !important;
}

.me-9 {
  margin-right: 2.8125rem !important;
}

.me-10 {
  margin-right: 3.125rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.3125rem !important;
}

.mb-2 {
  margin-bottom: 0.625rem !important;
}

.mb-3 {
  margin-bottom: 0.9375rem !important;
}

.mb-4 {
  margin-bottom: 1.25rem !important;
}

.mb-5 {
  margin-bottom: 1.625rem !important;
}

.mb-6 {
  margin-bottom: 1.875rem !important;
}

.mb-7 {
  margin-bottom: 2.1875rem !important;
}

.mb-8 {
  margin-bottom: 2.5rem !important;
}

.mb-9 {
  margin-bottom: 2.8125rem !important;
}

.mb-10 {
  margin-bottom: 3.125rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.3125rem !important;
}

.ms-2 {
  margin-left: 0.625rem !important;
}

.ms-3 {
  margin-left: 0.9375rem !important;
}

.ms-4 {
  margin-left: 1.25rem !important;
}

.ms-5 {
  margin-left: 1.625rem !important;
}

.ms-6 {
  margin-left: 1.875rem !important;
}

.ms-7 {
  margin-left: 2.1875rem !important;
}

.ms-8 {
  margin-left: 2.5rem !important;
}

.ms-9 {
  margin-left: 2.8125rem !important;
}

.ms-10 {
  margin-left: 3.125rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.3125rem !important;
}

.m-n2 {
  margin: -0.625rem !important;
}

.m-n3 {
  margin: -0.9375rem !important;
}

.m-n4 {
  margin: -1.25rem !important;
}

.m-n5 {
  margin: -1.625rem !important;
}

.m-n6 {
  margin: -1.875rem !important;
}

.m-n7 {
  margin: -2.1875rem !important;
}

.m-n8 {
  margin: -2.5rem !important;
}

.m-n9 {
  margin: -2.8125rem !important;
}

.m-n10 {
  margin: -3.125rem !important;
}

.mx-n1 {
  margin-right: -0.3125rem !important;
  margin-left: -0.3125rem !important;
}

.mx-n2 {
  margin-right: -0.625rem !important;
  margin-left: -0.625rem !important;
}

.mx-n3 {
  margin-right: -0.9375rem !important;
  margin-left: -0.9375rem !important;
}

.mx-n4 {
  margin-right: -1.25rem !important;
  margin-left: -1.25rem !important;
}

.mx-n5 {
  margin-right: -1.625rem !important;
  margin-left: -1.625rem !important;
}

.mx-n6 {
  margin-right: -1.875rem !important;
  margin-left: -1.875rem !important;
}

.mx-n7 {
  margin-right: -2.1875rem !important;
  margin-left: -2.1875rem !important;
}

.mx-n8 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.mx-n9 {
  margin-right: -2.8125rem !important;
  margin-left: -2.8125rem !important;
}

.mx-n10 {
  margin-right: -3.125rem !important;
  margin-left: -3.125rem !important;
}

.my-n1 {
  margin-top: -0.3125rem !important;
  margin-bottom: -0.3125rem !important;
}

.my-n2 {
  margin-top: -0.625rem !important;
  margin-bottom: -0.625rem !important;
}

.my-n3 {
  margin-top: -0.9375rem !important;
  margin-bottom: -0.9375rem !important;
}

.my-n4 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.my-n5 {
  margin-top: -1.625rem !important;
  margin-bottom: -1.625rem !important;
}

.my-n6 {
  margin-top: -1.875rem !important;
  margin-bottom: -1.875rem !important;
}

.my-n7 {
  margin-top: -2.1875rem !important;
  margin-bottom: -2.1875rem !important;
}

.my-n8 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n9 {
  margin-top: -2.8125rem !important;
  margin-bottom: -2.8125rem !important;
}

.my-n10 {
  margin-top: -3.125rem !important;
  margin-bottom: -3.125rem !important;
}

.mt-n1 {
  margin-top: -0.3125rem !important;
}

.mt-n2 {
  margin-top: -0.625rem !important;
}

.mt-n3 {
  margin-top: -0.9375rem !important;
}

.mt-n4 {
  margin-top: -1.25rem !important;
}

.mt-n5 {
  margin-top: -1.625rem !important;
}

.mt-n6 {
  margin-top: -1.875rem !important;
}

.mt-n7 {
  margin-top: -2.1875rem !important;
}

.mt-n8 {
  margin-top: -2.5rem !important;
}

.mt-n9 {
  margin-top: -2.8125rem !important;
}

.mt-n10 {
  margin-top: -3.125rem !important;
}

.me-n1 {
  margin-right: -0.3125rem !important;
}

.me-n2 {
  margin-right: -0.625rem !important;
}

.me-n3 {
  margin-right: -0.9375rem !important;
}

.me-n4 {
  margin-right: -1.25rem !important;
}

.me-n5 {
  margin-right: -1.625rem !important;
}

.me-n6 {
  margin-right: -1.875rem !important;
}

.me-n7 {
  margin-right: -2.1875rem !important;
}

.me-n8 {
  margin-right: -2.5rem !important;
}

.me-n9 {
  margin-right: -2.8125rem !important;
}

.me-n10 {
  margin-right: -3.125rem !important;
}

.mb-n1 {
  margin-bottom: -0.3125rem !important;
}

.mb-n2 {
  margin-bottom: -0.625rem !important;
}

.mb-n3 {
  margin-bottom: -0.9375rem !important;
}

.mb-n4 {
  margin-bottom: -1.25rem !important;
}

.mb-n5 {
  margin-bottom: -1.625rem !important;
}

.mb-n6 {
  margin-bottom: -1.875rem !important;
}

.mb-n7 {
  margin-bottom: -2.1875rem !important;
}

.mb-n8 {
  margin-bottom: -2.5rem !important;
}

.mb-n9 {
  margin-bottom: -2.8125rem !important;
}

.mb-n10 {
  margin-bottom: -3.125rem !important;
}

.ms-n1 {
  margin-left: -0.3125rem !important;
}

.ms-n2 {
  margin-left: -0.625rem !important;
}

.ms-n3 {
  margin-left: -0.9375rem !important;
}

.ms-n4 {
  margin-left: -1.25rem !important;
}

.ms-n5 {
  margin-left: -1.625rem !important;
}

.ms-n6 {
  margin-left: -1.875rem !important;
}

.ms-n7 {
  margin-left: -2.1875rem !important;
}

.ms-n8 {
  margin-left: -2.5rem !important;
}

.ms-n9 {
  margin-left: -2.8125rem !important;
}

.ms-n10 {
  margin-left: -3.125rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.3125rem !important;
}

.p-2 {
  padding: 0.625rem !important;
}

.p-3 {
  padding: 0.9375rem !important;
}

.p-4 {
  padding: 1.25rem !important;
}

.p-5 {
  padding: 1.625rem !important;
}

.p-6 {
  padding: 1.875rem !important;
}

.p-7 {
  padding: 2.1875rem !important;
}

.p-8 {
  padding: 2.5rem !important;
}

.p-9 {
  padding: 2.8125rem !important;
}

.p-10 {
  padding: 3.125rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.3125rem !important;
  padding-left: 0.3125rem !important;
}

.px-2 {
  padding-right: 0.625rem !important;
  padding-left: 0.625rem !important;
}

.px-3 {
  padding-right: 0.9375rem !important;
  padding-left: 0.9375rem !important;
}

.px-4 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}

.px-5 {
  padding-right: 1.625rem !important;
  padding-left: 1.625rem !important;
}

.px-6 {
  padding-right: 1.875rem !important;
  padding-left: 1.875rem !important;
}

.px-7 {
  padding-right: 2.1875rem !important;
  padding-left: 2.1875rem !important;
}

.px-8 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-9 {
  padding-right: 2.8125rem !important;
  padding-left: 2.8125rem !important;
}

.px-10 {
  padding-right: 3.125rem !important;
  padding-left: 3.125rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.3125rem !important;
  padding-bottom: 0.3125rem !important;
}

.py-2 {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

.py-3 {
  padding-top: 0.9375rem !important;
  padding-bottom: 0.9375rem !important;
}

.py-4 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-5 {
  padding-top: 1.625rem !important;
  padding-bottom: 1.625rem !important;
}

.py-6 {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important;
}

.py-7 {
  padding-top: 2.1875rem !important;
  padding-bottom: 2.1875rem !important;
}

.py-8 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-9 {
  padding-top: 2.8125rem !important;
  padding-bottom: 2.8125rem !important;
}

.py-10 {
  padding-top: 3.125rem !important;
  padding-bottom: 3.125rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.3125rem !important;
}

.pt-2 {
  padding-top: 0.625rem !important;
}

.pt-3 {
  padding-top: 0.9375rem !important;
}

.pt-4 {
  padding-top: 1.25rem !important;
}

.pt-5 {
  padding-top: 1.625rem !important;
}

.pt-6 {
  padding-top: 1.875rem !important;
}

.pt-7 {
  padding-top: 2.1875rem !important;
}

.pt-8 {
  padding-top: 2.5rem !important;
}

.pt-9 {
  padding-top: 2.8125rem !important;
}

.pt-10 {
  padding-top: 3.125rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.3125rem !important;
}

.pe-2 {
  padding-right: 0.625rem !important;
}

.pe-3 {
  padding-right: 0.9375rem !important;
}

.pe-4 {
  padding-right: 1.25rem !important;
}

.pe-5 {
  padding-right: 1.625rem !important;
}

.pe-6 {
  padding-right: 1.875rem !important;
}

.pe-7 {
  padding-right: 2.1875rem !important;
}

.pe-8 {
  padding-right: 2.5rem !important;
}

.pe-9 {
  padding-right: 2.8125rem !important;
}

.pe-10 {
  padding-right: 3.125rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.3125rem !important;
}

.pb-2 {
  padding-bottom: 0.625rem !important;
}

.pb-3 {
  padding-bottom: 0.9375rem !important;
}

.pb-4 {
  padding-bottom: 1.25rem !important;
}

.pb-5 {
  padding-bottom: 1.625rem !important;
}

.pb-6 {
  padding-bottom: 1.875rem !important;
}

.pb-7 {
  padding-bottom: 2.1875rem !important;
}

.pb-8 {
  padding-bottom: 2.5rem !important;
}

.pb-9 {
  padding-bottom: 2.8125rem !important;
}

.pb-10 {
  padding-bottom: 3.125rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.3125rem !important;
}

.ps-2 {
  padding-left: 0.625rem !important;
}

.ps-3 {
  padding-left: 0.9375rem !important;
}

.ps-4 {
  padding-left: 1.25rem !important;
}

.ps-5 {
  padding-left: 1.625rem !important;
}

.ps-6 {
  padding-left: 1.875rem !important;
}

.ps-7 {
  padding-left: 2.1875rem !important;
}

.ps-8 {
  padding-left: 2.5rem !important;
}

.ps-9 {
  padding-left: 2.8125rem !important;
}

.ps-10 {
  padding-left: 3.125rem !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-primary {
  color: #f2a100 !important;
}

.text-secondary {
  color: #252c51 !important;
}

.text-success {
  color: #198754 !important;
}

.text-info {
  color: #0dcaf0 !important;
}

.text-warning {
  color: #ffc107 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.text-light {
  color: #f8f9fa !important;
}

.text-dark {
  color: #494949 !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #6d707f !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.bg-primary {
  background-color: #f2a100 !important;
}

.bg-secondary {
  background-color: #252c51 !important;
}

.bg-success {
  background-color: #198754 !important;
}

.bg-info {
  background-color: #0dcaf0 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.bg-dark {
  background-color: #494949 !important;
}

.bg-body {
  background-color: #fff !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.3125rem !important;
  }
  .gap-sm-2 {
    gap: 0.625rem !important;
  }
  .gap-sm-3 {
    gap: 0.9375rem !important;
  }
  .gap-sm-4 {
    gap: 1.25rem !important;
  }
  .gap-sm-5 {
    gap: 1.625rem !important;
  }
  .gap-sm-6 {
    gap: 1.875rem !important;
  }
  .gap-sm-7 {
    gap: 2.1875rem !important;
  }
  .gap-sm-8 {
    gap: 2.5rem !important;
  }
  .gap-sm-9 {
    gap: 2.8125rem !important;
  }
  .gap-sm-10 {
    gap: 3.125rem !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.3125rem !important;
  }
  .m-sm-2 {
    margin: 0.625rem !important;
  }
  .m-sm-3 {
    margin: 0.9375rem !important;
  }
  .m-sm-4 {
    margin: 1.25rem !important;
  }
  .m-sm-5 {
    margin: 1.625rem !important;
  }
  .m-sm-6 {
    margin: 1.875rem !important;
  }
  .m-sm-7 {
    margin: 2.1875rem !important;
  }
  .m-sm-8 {
    margin: 2.5rem !important;
  }
  .m-sm-9 {
    margin: 2.8125rem !important;
  }
  .m-sm-10 {
    margin: 3.125rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-sm-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-sm-5 {
    margin-right: 1.625rem !important;
    margin-left: 1.625rem !important;
  }
  .mx-sm-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-sm-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important;
  }
  .mx-sm-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-sm-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important;
  }
  .mx-sm-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-sm-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-sm-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-sm-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-sm-5 {
    margin-top: 1.625rem !important;
    margin-bottom: 1.625rem !important;
  }
  .my-sm-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-sm-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important;
  }
  .my-sm-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-sm-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important;
  }
  .my-sm-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.625rem !important;
  }
  .mt-sm-3 {
    margin-top: 0.9375rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.25rem !important;
  }
  .mt-sm-5 {
    margin-top: 1.625rem !important;
  }
  .mt-sm-6 {
    margin-top: 1.875rem !important;
  }
  .mt-sm-7 {
    margin-top: 2.1875rem !important;
  }
  .mt-sm-8 {
    margin-top: 2.5rem !important;
  }
  .mt-sm-9 {
    margin-top: 2.8125rem !important;
  }
  .mt-sm-10 {
    margin-top: 3.125rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.3125rem !important;
  }
  .me-sm-2 {
    margin-right: 0.625rem !important;
  }
  .me-sm-3 {
    margin-right: 0.9375rem !important;
  }
  .me-sm-4 {
    margin-right: 1.25rem !important;
  }
  .me-sm-5 {
    margin-right: 1.625rem !important;
  }
  .me-sm-6 {
    margin-right: 1.875rem !important;
  }
  .me-sm-7 {
    margin-right: 2.1875rem !important;
  }
  .me-sm-8 {
    margin-right: 2.5rem !important;
  }
  .me-sm-9 {
    margin-right: 2.8125rem !important;
  }
  .me-sm-10 {
    margin-right: 3.125rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.25rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 1.625rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 1.875rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 2.1875rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 2.5rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 2.8125rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 3.125rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.3125rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.625rem !important;
  }
  .ms-sm-3 {
    margin-left: 0.9375rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.25rem !important;
  }
  .ms-sm-5 {
    margin-left: 1.625rem !important;
  }
  .ms-sm-6 {
    margin-left: 1.875rem !important;
  }
  .ms-sm-7 {
    margin-left: 2.1875rem !important;
  }
  .ms-sm-8 {
    margin-left: 2.5rem !important;
  }
  .ms-sm-9 {
    margin-left: 2.8125rem !important;
  }
  .ms-sm-10 {
    margin-left: 3.125rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.3125rem !important;
  }
  .m-sm-n2 {
    margin: -0.625rem !important;
  }
  .m-sm-n3 {
    margin: -0.9375rem !important;
  }
  .m-sm-n4 {
    margin: -1.25rem !important;
  }
  .m-sm-n5 {
    margin: -1.625rem !important;
  }
  .m-sm-n6 {
    margin: -1.875rem !important;
  }
  .m-sm-n7 {
    margin: -2.1875rem !important;
  }
  .m-sm-n8 {
    margin: -2.5rem !important;
  }
  .m-sm-n9 {
    margin: -2.8125rem !important;
  }
  .m-sm-n10 {
    margin: -3.125rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-sm-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-sm-n5 {
    margin-right: -1.625rem !important;
    margin-left: -1.625rem !important;
  }
  .mx-sm-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-sm-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important;
  }
  .mx-sm-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-sm-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important;
  }
  .mx-sm-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-sm-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-sm-n5 {
    margin-top: -1.625rem !important;
    margin-bottom: -1.625rem !important;
  }
  .my-sm-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-sm-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important;
  }
  .my-sm-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-sm-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important;
  }
  .my-sm-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.3125rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.625rem !important;
  }
  .mt-sm-n3 {
    margin-top: -0.9375rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.25rem !important;
  }
  .mt-sm-n5 {
    margin-top: -1.625rem !important;
  }
  .mt-sm-n6 {
    margin-top: -1.875rem !important;
  }
  .mt-sm-n7 {
    margin-top: -2.1875rem !important;
  }
  .mt-sm-n8 {
    margin-top: -2.5rem !important;
  }
  .mt-sm-n9 {
    margin-top: -2.8125rem !important;
  }
  .mt-sm-n10 {
    margin-top: -3.125rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.3125rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.625rem !important;
  }
  .me-sm-n3 {
    margin-right: -0.9375rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.25rem !important;
  }
  .me-sm-n5 {
    margin-right: -1.625rem !important;
  }
  .me-sm-n6 {
    margin-right: -1.875rem !important;
  }
  .me-sm-n7 {
    margin-right: -2.1875rem !important;
  }
  .me-sm-n8 {
    margin-right: -2.5rem !important;
  }
  .me-sm-n9 {
    margin-right: -2.8125rem !important;
  }
  .me-sm-n10 {
    margin-right: -3.125rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.625rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.25rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -1.625rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -1.875rem !important;
  }
  .mb-sm-n7 {
    margin-bottom: -2.1875rem !important;
  }
  .mb-sm-n8 {
    margin-bottom: -2.5rem !important;
  }
  .mb-sm-n9 {
    margin-bottom: -2.8125rem !important;
  }
  .mb-sm-n10 {
    margin-bottom: -3.125rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.3125rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.625rem !important;
  }
  .ms-sm-n3 {
    margin-left: -0.9375rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.25rem !important;
  }
  .ms-sm-n5 {
    margin-left: -1.625rem !important;
  }
  .ms-sm-n6 {
    margin-left: -1.875rem !important;
  }
  .ms-sm-n7 {
    margin-left: -2.1875rem !important;
  }
  .ms-sm-n8 {
    margin-left: -2.5rem !important;
  }
  .ms-sm-n9 {
    margin-left: -2.8125rem !important;
  }
  .ms-sm-n10 {
    margin-left: -3.125rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.3125rem !important;
  }
  .p-sm-2 {
    padding: 0.625rem !important;
  }
  .p-sm-3 {
    padding: 0.9375rem !important;
  }
  .p-sm-4 {
    padding: 1.25rem !important;
  }
  .p-sm-5 {
    padding: 1.625rem !important;
  }
  .p-sm-6 {
    padding: 1.875rem !important;
  }
  .p-sm-7 {
    padding: 2.1875rem !important;
  }
  .p-sm-8 {
    padding: 2.5rem !important;
  }
  .p-sm-9 {
    padding: 2.8125rem !important;
  }
  .p-sm-10 {
    padding: 3.125rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-sm-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-sm-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-sm-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-sm-5 {
    padding-right: 1.625rem !important;
    padding-left: 1.625rem !important;
  }
  .px-sm-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-sm-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important;
  }
  .px-sm-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-sm-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important;
  }
  .px-sm-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-sm-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-sm-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-sm-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-sm-5 {
    padding-top: 1.625rem !important;
    padding-bottom: 1.625rem !important;
  }
  .py-sm-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-sm-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important;
  }
  .py-sm-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-sm-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important;
  }
  .py-sm-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.625rem !important;
  }
  .pt-sm-3 {
    padding-top: 0.9375rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.25rem !important;
  }
  .pt-sm-5 {
    padding-top: 1.625rem !important;
  }
  .pt-sm-6 {
    padding-top: 1.875rem !important;
  }
  .pt-sm-7 {
    padding-top: 2.1875rem !important;
  }
  .pt-sm-8 {
    padding-top: 2.5rem !important;
  }
  .pt-sm-9 {
    padding-top: 2.8125rem !important;
  }
  .pt-sm-10 {
    padding-top: 3.125rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.3125rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.625rem !important;
  }
  .pe-sm-3 {
    padding-right: 0.9375rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.25rem !important;
  }
  .pe-sm-5 {
    padding-right: 1.625rem !important;
  }
  .pe-sm-6 {
    padding-right: 1.875rem !important;
  }
  .pe-sm-7 {
    padding-right: 2.1875rem !important;
  }
  .pe-sm-8 {
    padding-right: 2.5rem !important;
  }
  .pe-sm-9 {
    padding-right: 2.8125rem !important;
  }
  .pe-sm-10 {
    padding-right: 3.125rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.25rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 1.625rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 1.875rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 2.1875rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 2.5rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 2.8125rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 3.125rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.3125rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.625rem !important;
  }
  .ps-sm-3 {
    padding-left: 0.9375rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.25rem !important;
  }
  .ps-sm-5 {
    padding-left: 1.625rem !important;
  }
  .ps-sm-6 {
    padding-left: 1.875rem !important;
  }
  .ps-sm-7 {
    padding-left: 2.1875rem !important;
  }
  .ps-sm-8 {
    padding-left: 2.5rem !important;
  }
  .ps-sm-9 {
    padding-left: 2.8125rem !important;
  }
  .ps-sm-10 {
    padding-left: 3.125rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.3125rem !important;
  }
  .gap-md-2 {
    gap: 0.625rem !important;
  }
  .gap-md-3 {
    gap: 0.9375rem !important;
  }
  .gap-md-4 {
    gap: 1.25rem !important;
  }
  .gap-md-5 {
    gap: 1.625rem !important;
  }
  .gap-md-6 {
    gap: 1.875rem !important;
  }
  .gap-md-7 {
    gap: 2.1875rem !important;
  }
  .gap-md-8 {
    gap: 2.5rem !important;
  }
  .gap-md-9 {
    gap: 2.8125rem !important;
  }
  .gap-md-10 {
    gap: 3.125rem !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.3125rem !important;
  }
  .m-md-2 {
    margin: 0.625rem !important;
  }
  .m-md-3 {
    margin: 0.9375rem !important;
  }
  .m-md-4 {
    margin: 1.25rem !important;
  }
  .m-md-5 {
    margin: 1.625rem !important;
  }
  .m-md-6 {
    margin: 1.875rem !important;
  }
  .m-md-7 {
    margin: 2.1875rem !important;
  }
  .m-md-8 {
    margin: 2.5rem !important;
  }
  .m-md-9 {
    margin: 2.8125rem !important;
  }
  .m-md-10 {
    margin: 3.125rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-md-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-md-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-md-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-md-5 {
    margin-right: 1.625rem !important;
    margin-left: 1.625rem !important;
  }
  .mx-md-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-md-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important;
  }
  .mx-md-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-md-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important;
  }
  .mx-md-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-md-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-md-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-md-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-md-5 {
    margin-top: 1.625rem !important;
    margin-bottom: 1.625rem !important;
  }
  .my-md-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-md-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important;
  }
  .my-md-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-md-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important;
  }
  .my-md-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-md-2 {
    margin-top: 0.625rem !important;
  }
  .mt-md-3 {
    margin-top: 0.9375rem !important;
  }
  .mt-md-4 {
    margin-top: 1.25rem !important;
  }
  .mt-md-5 {
    margin-top: 1.625rem !important;
  }
  .mt-md-6 {
    margin-top: 1.875rem !important;
  }
  .mt-md-7 {
    margin-top: 2.1875rem !important;
  }
  .mt-md-8 {
    margin-top: 2.5rem !important;
  }
  .mt-md-9 {
    margin-top: 2.8125rem !important;
  }
  .mt-md-10 {
    margin-top: 3.125rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.3125rem !important;
  }
  .me-md-2 {
    margin-right: 0.625rem !important;
  }
  .me-md-3 {
    margin-right: 0.9375rem !important;
  }
  .me-md-4 {
    margin-right: 1.25rem !important;
  }
  .me-md-5 {
    margin-right: 1.625rem !important;
  }
  .me-md-6 {
    margin-right: 1.875rem !important;
  }
  .me-md-7 {
    margin-right: 2.1875rem !important;
  }
  .me-md-8 {
    margin-right: 2.5rem !important;
  }
  .me-md-9 {
    margin-right: 2.8125rem !important;
  }
  .me-md-10 {
    margin-right: 3.125rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-md-3 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.25rem !important;
  }
  .mb-md-5 {
    margin-bottom: 1.625rem !important;
  }
  .mb-md-6 {
    margin-bottom: 1.875rem !important;
  }
  .mb-md-7 {
    margin-bottom: 2.1875rem !important;
  }
  .mb-md-8 {
    margin-bottom: 2.5rem !important;
  }
  .mb-md-9 {
    margin-bottom: 2.8125rem !important;
  }
  .mb-md-10 {
    margin-bottom: 3.125rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.3125rem !important;
  }
  .ms-md-2 {
    margin-left: 0.625rem !important;
  }
  .ms-md-3 {
    margin-left: 0.9375rem !important;
  }
  .ms-md-4 {
    margin-left: 1.25rem !important;
  }
  .ms-md-5 {
    margin-left: 1.625rem !important;
  }
  .ms-md-6 {
    margin-left: 1.875rem !important;
  }
  .ms-md-7 {
    margin-left: 2.1875rem !important;
  }
  .ms-md-8 {
    margin-left: 2.5rem !important;
  }
  .ms-md-9 {
    margin-left: 2.8125rem !important;
  }
  .ms-md-10 {
    margin-left: 3.125rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.3125rem !important;
  }
  .m-md-n2 {
    margin: -0.625rem !important;
  }
  .m-md-n3 {
    margin: -0.9375rem !important;
  }
  .m-md-n4 {
    margin: -1.25rem !important;
  }
  .m-md-n5 {
    margin: -1.625rem !important;
  }
  .m-md-n6 {
    margin: -1.875rem !important;
  }
  .m-md-n7 {
    margin: -2.1875rem !important;
  }
  .m-md-n8 {
    margin: -2.5rem !important;
  }
  .m-md-n9 {
    margin: -2.8125rem !important;
  }
  .m-md-n10 {
    margin: -3.125rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-md-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-md-n5 {
    margin-right: -1.625rem !important;
    margin-left: -1.625rem !important;
  }
  .mx-md-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-md-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important;
  }
  .mx-md-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-md-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important;
  }
  .mx-md-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .my-md-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }
  .my-md-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-md-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-md-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-md-n5 {
    margin-top: -1.625rem !important;
    margin-bottom: -1.625rem !important;
  }
  .my-md-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-md-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important;
  }
  .my-md-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-md-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important;
  }
  .my-md-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.3125rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.625rem !important;
  }
  .mt-md-n3 {
    margin-top: -0.9375rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.25rem !important;
  }
  .mt-md-n5 {
    margin-top: -1.625rem !important;
  }
  .mt-md-n6 {
    margin-top: -1.875rem !important;
  }
  .mt-md-n7 {
    margin-top: -2.1875rem !important;
  }
  .mt-md-n8 {
    margin-top: -2.5rem !important;
  }
  .mt-md-n9 {
    margin-top: -2.8125rem !important;
  }
  .mt-md-n10 {
    margin-top: -3.125rem !important;
  }
  .me-md-n1 {
    margin-right: -0.3125rem !important;
  }
  .me-md-n2 {
    margin-right: -0.625rem !important;
  }
  .me-md-n3 {
    margin-right: -0.9375rem !important;
  }
  .me-md-n4 {
    margin-right: -1.25rem !important;
  }
  .me-md-n5 {
    margin-right: -1.625rem !important;
  }
  .me-md-n6 {
    margin-right: -1.875rem !important;
  }
  .me-md-n7 {
    margin-right: -2.1875rem !important;
  }
  .me-md-n8 {
    margin-right: -2.5rem !important;
  }
  .me-md-n9 {
    margin-right: -2.8125rem !important;
  }
  .me-md-n10 {
    margin-right: -3.125rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.625rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.25rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -1.625rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -1.875rem !important;
  }
  .mb-md-n7 {
    margin-bottom: -2.1875rem !important;
  }
  .mb-md-n8 {
    margin-bottom: -2.5rem !important;
  }
  .mb-md-n9 {
    margin-bottom: -2.8125rem !important;
  }
  .mb-md-n10 {
    margin-bottom: -3.125rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.3125rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.625rem !important;
  }
  .ms-md-n3 {
    margin-left: -0.9375rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.25rem !important;
  }
  .ms-md-n5 {
    margin-left: -1.625rem !important;
  }
  .ms-md-n6 {
    margin-left: -1.875rem !important;
  }
  .ms-md-n7 {
    margin-left: -2.1875rem !important;
  }
  .ms-md-n8 {
    margin-left: -2.5rem !important;
  }
  .ms-md-n9 {
    margin-left: -2.8125rem !important;
  }
  .ms-md-n10 {
    margin-left: -3.125rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.3125rem !important;
  }
  .p-md-2 {
    padding: 0.625rem !important;
  }
  .p-md-3 {
    padding: 0.9375rem !important;
  }
  .p-md-4 {
    padding: 1.25rem !important;
  }
  .p-md-5 {
    padding: 1.625rem !important;
  }
  .p-md-6 {
    padding: 1.875rem !important;
  }
  .p-md-7 {
    padding: 2.1875rem !important;
  }
  .p-md-8 {
    padding: 2.5rem !important;
  }
  .p-md-9 {
    padding: 2.8125rem !important;
  }
  .p-md-10 {
    padding: 3.125rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-md-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-md-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-md-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-md-5 {
    padding-right: 1.625rem !important;
    padding-left: 1.625rem !important;
  }
  .px-md-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-md-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important;
  }
  .px-md-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-md-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important;
  }
  .px-md-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-md-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-md-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-md-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-md-5 {
    padding-top: 1.625rem !important;
    padding-bottom: 1.625rem !important;
  }
  .py-md-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-md-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important;
  }
  .py-md-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-md-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important;
  }
  .py-md-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-md-2 {
    padding-top: 0.625rem !important;
  }
  .pt-md-3 {
    padding-top: 0.9375rem !important;
  }
  .pt-md-4 {
    padding-top: 1.25rem !important;
  }
  .pt-md-5 {
    padding-top: 1.625rem !important;
  }
  .pt-md-6 {
    padding-top: 1.875rem !important;
  }
  .pt-md-7 {
    padding-top: 2.1875rem !important;
  }
  .pt-md-8 {
    padding-top: 2.5rem !important;
  }
  .pt-md-9 {
    padding-top: 2.8125rem !important;
  }
  .pt-md-10 {
    padding-top: 3.125rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.3125rem !important;
  }
  .pe-md-2 {
    padding-right: 0.625rem !important;
  }
  .pe-md-3 {
    padding-right: 0.9375rem !important;
  }
  .pe-md-4 {
    padding-right: 1.25rem !important;
  }
  .pe-md-5 {
    padding-right: 1.625rem !important;
  }
  .pe-md-6 {
    padding-right: 1.875rem !important;
  }
  .pe-md-7 {
    padding-right: 2.1875rem !important;
  }
  .pe-md-8 {
    padding-right: 2.5rem !important;
  }
  .pe-md-9 {
    padding-right: 2.8125rem !important;
  }
  .pe-md-10 {
    padding-right: 3.125rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-md-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.25rem !important;
  }
  .pb-md-5 {
    padding-bottom: 1.625rem !important;
  }
  .pb-md-6 {
    padding-bottom: 1.875rem !important;
  }
  .pb-md-7 {
    padding-bottom: 2.1875rem !important;
  }
  .pb-md-8 {
    padding-bottom: 2.5rem !important;
  }
  .pb-md-9 {
    padding-bottom: 2.8125rem !important;
  }
  .pb-md-10 {
    padding-bottom: 3.125rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.3125rem !important;
  }
  .ps-md-2 {
    padding-left: 0.625rem !important;
  }
  .ps-md-3 {
    padding-left: 0.9375rem !important;
  }
  .ps-md-4 {
    padding-left: 1.25rem !important;
  }
  .ps-md-5 {
    padding-left: 1.625rem !important;
  }
  .ps-md-6 {
    padding-left: 1.875rem !important;
  }
  .ps-md-7 {
    padding-left: 2.1875rem !important;
  }
  .ps-md-8 {
    padding-left: 2.5rem !important;
  }
  .ps-md-9 {
    padding-left: 2.8125rem !important;
  }
  .ps-md-10 {
    padding-left: 3.125rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.3125rem !important;
  }
  .gap-lg-2 {
    gap: 0.625rem !important;
  }
  .gap-lg-3 {
    gap: 0.9375rem !important;
  }
  .gap-lg-4 {
    gap: 1.25rem !important;
  }
  .gap-lg-5 {
    gap: 1.625rem !important;
  }
  .gap-lg-6 {
    gap: 1.875rem !important;
  }
  .gap-lg-7 {
    gap: 2.1875rem !important;
  }
  .gap-lg-8 {
    gap: 2.5rem !important;
  }
  .gap-lg-9 {
    gap: 2.8125rem !important;
  }
  .gap-lg-10 {
    gap: 3.125rem !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.3125rem !important;
  }
  .m-lg-2 {
    margin: 0.625rem !important;
  }
  .m-lg-3 {
    margin: 0.9375rem !important;
  }
  .m-lg-4 {
    margin: 1.25rem !important;
  }
  .m-lg-5 {
    margin: 1.625rem !important;
  }
  .m-lg-6 {
    margin: 1.875rem !important;
  }
  .m-lg-7 {
    margin: 2.1875rem !important;
  }
  .m-lg-8 {
    margin: 2.5rem !important;
  }
  .m-lg-9 {
    margin: 2.8125rem !important;
  }
  .m-lg-10 {
    margin: 3.125rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-lg-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-lg-5 {
    margin-right: 1.625rem !important;
    margin-left: 1.625rem !important;
  }
  .mx-lg-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-lg-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important;
  }
  .mx-lg-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-lg-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important;
  }
  .mx-lg-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-lg-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-lg-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-lg-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-lg-5 {
    margin-top: 1.625rem !important;
    margin-bottom: 1.625rem !important;
  }
  .my-lg-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-lg-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important;
  }
  .my-lg-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-lg-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important;
  }
  .my-lg-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.625rem !important;
  }
  .mt-lg-3 {
    margin-top: 0.9375rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.25rem !important;
  }
  .mt-lg-5 {
    margin-top: 1.625rem !important;
  }
  .mt-lg-6 {
    margin-top: 1.875rem !important;
  }
  .mt-lg-7 {
    margin-top: 2.1875rem !important;
  }
  .mt-lg-8 {
    margin-top: 2.5rem !important;
  }
  .mt-lg-9 {
    margin-top: 2.8125rem !important;
  }
  .mt-lg-10 {
    margin-top: 3.125rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.3125rem !important;
  }
  .me-lg-2 {
    margin-right: 0.625rem !important;
  }
  .me-lg-3 {
    margin-right: 0.9375rem !important;
  }
  .me-lg-4 {
    margin-right: 1.25rem !important;
  }
  .me-lg-5 {
    margin-right: 1.625rem !important;
  }
  .me-lg-6 {
    margin-right: 1.875rem !important;
  }
  .me-lg-7 {
    margin-right: 2.1875rem !important;
  }
  .me-lg-8 {
    margin-right: 2.5rem !important;
  }
  .me-lg-9 {
    margin-right: 2.8125rem !important;
  }
  .me-lg-10 {
    margin-right: 3.125rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.25rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 1.625rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 1.875rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 2.1875rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 2.5rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 2.8125rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 3.125rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.3125rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.625rem !important;
  }
  .ms-lg-3 {
    margin-left: 0.9375rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.25rem !important;
  }
  .ms-lg-5 {
    margin-left: 1.625rem !important;
  }
  .ms-lg-6 {
    margin-left: 1.875rem !important;
  }
  .ms-lg-7 {
    margin-left: 2.1875rem !important;
  }
  .ms-lg-8 {
    margin-left: 2.5rem !important;
  }
  .ms-lg-9 {
    margin-left: 2.8125rem !important;
  }
  .ms-lg-10 {
    margin-left: 3.125rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.3125rem !important;
  }
  .m-lg-n2 {
    margin: -0.625rem !important;
  }
  .m-lg-n3 {
    margin: -0.9375rem !important;
  }
  .m-lg-n4 {
    margin: -1.25rem !important;
  }
  .m-lg-n5 {
    margin: -1.625rem !important;
  }
  .m-lg-n6 {
    margin: -1.875rem !important;
  }
  .m-lg-n7 {
    margin: -2.1875rem !important;
  }
  .m-lg-n8 {
    margin: -2.5rem !important;
  }
  .m-lg-n9 {
    margin: -2.8125rem !important;
  }
  .m-lg-n10 {
    margin: -3.125rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-lg-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-lg-n5 {
    margin-right: -1.625rem !important;
    margin-left: -1.625rem !important;
  }
  .mx-lg-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-lg-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important;
  }
  .mx-lg-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-lg-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important;
  }
  .mx-lg-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-lg-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-lg-n5 {
    margin-top: -1.625rem !important;
    margin-bottom: -1.625rem !important;
  }
  .my-lg-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-lg-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important;
  }
  .my-lg-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-lg-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important;
  }
  .my-lg-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.3125rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.625rem !important;
  }
  .mt-lg-n3 {
    margin-top: -0.9375rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.25rem !important;
  }
  .mt-lg-n5 {
    margin-top: -1.625rem !important;
  }
  .mt-lg-n6 {
    margin-top: -1.875rem !important;
  }
  .mt-lg-n7 {
    margin-top: -2.1875rem !important;
  }
  .mt-lg-n8 {
    margin-top: -2.5rem !important;
  }
  .mt-lg-n9 {
    margin-top: -2.8125rem !important;
  }
  .mt-lg-n10 {
    margin-top: -3.125rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.3125rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.625rem !important;
  }
  .me-lg-n3 {
    margin-right: -0.9375rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.25rem !important;
  }
  .me-lg-n5 {
    margin-right: -1.625rem !important;
  }
  .me-lg-n6 {
    margin-right: -1.875rem !important;
  }
  .me-lg-n7 {
    margin-right: -2.1875rem !important;
  }
  .me-lg-n8 {
    margin-right: -2.5rem !important;
  }
  .me-lg-n9 {
    margin-right: -2.8125rem !important;
  }
  .me-lg-n10 {
    margin-right: -3.125rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.625rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.25rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -1.625rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -1.875rem !important;
  }
  .mb-lg-n7 {
    margin-bottom: -2.1875rem !important;
  }
  .mb-lg-n8 {
    margin-bottom: -2.5rem !important;
  }
  .mb-lg-n9 {
    margin-bottom: -2.8125rem !important;
  }
  .mb-lg-n10 {
    margin-bottom: -3.125rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.3125rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.625rem !important;
  }
  .ms-lg-n3 {
    margin-left: -0.9375rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.25rem !important;
  }
  .ms-lg-n5 {
    margin-left: -1.625rem !important;
  }
  .ms-lg-n6 {
    margin-left: -1.875rem !important;
  }
  .ms-lg-n7 {
    margin-left: -2.1875rem !important;
  }
  .ms-lg-n8 {
    margin-left: -2.5rem !important;
  }
  .ms-lg-n9 {
    margin-left: -2.8125rem !important;
  }
  .ms-lg-n10 {
    margin-left: -3.125rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.3125rem !important;
  }
  .p-lg-2 {
    padding: 0.625rem !important;
  }
  .p-lg-3 {
    padding: 0.9375rem !important;
  }
  .p-lg-4 {
    padding: 1.25rem !important;
  }
  .p-lg-5 {
    padding: 1.625rem !important;
  }
  .p-lg-6 {
    padding: 1.875rem !important;
  }
  .p-lg-7 {
    padding: 2.1875rem !important;
  }
  .p-lg-8 {
    padding: 2.5rem !important;
  }
  .p-lg-9 {
    padding: 2.8125rem !important;
  }
  .p-lg-10 {
    padding: 3.125rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-lg-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-lg-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-lg-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-lg-5 {
    padding-right: 1.625rem !important;
    padding-left: 1.625rem !important;
  }
  .px-lg-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-lg-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important;
  }
  .px-lg-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-lg-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important;
  }
  .px-lg-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-lg-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-lg-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-lg-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-lg-5 {
    padding-top: 1.625rem !important;
    padding-bottom: 1.625rem !important;
  }
  .py-lg-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-lg-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important;
  }
  .py-lg-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-lg-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important;
  }
  .py-lg-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.625rem !important;
  }
  .pt-lg-3 {
    padding-top: 0.9375rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.25rem !important;
  }
  .pt-lg-5 {
    padding-top: 1.625rem !important;
  }
  .pt-lg-6 {
    padding-top: 1.875rem !important;
  }
  .pt-lg-7 {
    padding-top: 2.1875rem !important;
  }
  .pt-lg-8 {
    padding-top: 2.5rem !important;
  }
  .pt-lg-9 {
    padding-top: 2.8125rem !important;
  }
  .pt-lg-10 {
    padding-top: 3.125rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.3125rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.625rem !important;
  }
  .pe-lg-3 {
    padding-right: 0.9375rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.25rem !important;
  }
  .pe-lg-5 {
    padding-right: 1.625rem !important;
  }
  .pe-lg-6 {
    padding-right: 1.875rem !important;
  }
  .pe-lg-7 {
    padding-right: 2.1875rem !important;
  }
  .pe-lg-8 {
    padding-right: 2.5rem !important;
  }
  .pe-lg-9 {
    padding-right: 2.8125rem !important;
  }
  .pe-lg-10 {
    padding-right: 3.125rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.25rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 1.625rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 1.875rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 2.1875rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 2.5rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 2.8125rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 3.125rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.3125rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.625rem !important;
  }
  .ps-lg-3 {
    padding-left: 0.9375rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.25rem !important;
  }
  .ps-lg-5 {
    padding-left: 1.625rem !important;
  }
  .ps-lg-6 {
    padding-left: 1.875rem !important;
  }
  .ps-lg-7 {
    padding-left: 2.1875rem !important;
  }
  .ps-lg-8 {
    padding-left: 2.5rem !important;
  }
  .ps-lg-9 {
    padding-left: 2.8125rem !important;
  }
  .ps-lg-10 {
    padding-left: 3.125rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.3125rem !important;
  }
  .gap-xl-2 {
    gap: 0.625rem !important;
  }
  .gap-xl-3 {
    gap: 0.9375rem !important;
  }
  .gap-xl-4 {
    gap: 1.25rem !important;
  }
  .gap-xl-5 {
    gap: 1.625rem !important;
  }
  .gap-xl-6 {
    gap: 1.875rem !important;
  }
  .gap-xl-7 {
    gap: 2.1875rem !important;
  }
  .gap-xl-8 {
    gap: 2.5rem !important;
  }
  .gap-xl-9 {
    gap: 2.8125rem !important;
  }
  .gap-xl-10 {
    gap: 3.125rem !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.3125rem !important;
  }
  .m-xl-2 {
    margin: 0.625rem !important;
  }
  .m-xl-3 {
    margin: 0.9375rem !important;
  }
  .m-xl-4 {
    margin: 1.25rem !important;
  }
  .m-xl-5 {
    margin: 1.625rem !important;
  }
  .m-xl-6 {
    margin: 1.875rem !important;
  }
  .m-xl-7 {
    margin: 2.1875rem !important;
  }
  .m-xl-8 {
    margin: 2.5rem !important;
  }
  .m-xl-9 {
    margin: 2.8125rem !important;
  }
  .m-xl-10 {
    margin: 3.125rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-xl-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xl-5 {
    margin-right: 1.625rem !important;
    margin-left: 1.625rem !important;
  }
  .mx-xl-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-xl-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important;
  }
  .mx-xl-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xl-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important;
  }
  .mx-xl-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-xl-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-xl-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-xl-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xl-5 {
    margin-top: 1.625rem !important;
    margin-bottom: 1.625rem !important;
  }
  .my-xl-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-xl-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important;
  }
  .my-xl-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xl-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important;
  }
  .my-xl-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.625rem !important;
  }
  .mt-xl-3 {
    margin-top: 0.9375rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.25rem !important;
  }
  .mt-xl-5 {
    margin-top: 1.625rem !important;
  }
  .mt-xl-6 {
    margin-top: 1.875rem !important;
  }
  .mt-xl-7 {
    margin-top: 2.1875rem !important;
  }
  .mt-xl-8 {
    margin-top: 2.5rem !important;
  }
  .mt-xl-9 {
    margin-top: 2.8125rem !important;
  }
  .mt-xl-10 {
    margin-top: 3.125rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.3125rem !important;
  }
  .me-xl-2 {
    margin-right: 0.625rem !important;
  }
  .me-xl-3 {
    margin-right: 0.9375rem !important;
  }
  .me-xl-4 {
    margin-right: 1.25rem !important;
  }
  .me-xl-5 {
    margin-right: 1.625rem !important;
  }
  .me-xl-6 {
    margin-right: 1.875rem !important;
  }
  .me-xl-7 {
    margin-right: 2.1875rem !important;
  }
  .me-xl-8 {
    margin-right: 2.5rem !important;
  }
  .me-xl-9 {
    margin-right: 2.8125rem !important;
  }
  .me-xl-10 {
    margin-right: 3.125rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 1.625rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 1.875rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 2.1875rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 2.8125rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 3.125rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.3125rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.625rem !important;
  }
  .ms-xl-3 {
    margin-left: 0.9375rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.25rem !important;
  }
  .ms-xl-5 {
    margin-left: 1.625rem !important;
  }
  .ms-xl-6 {
    margin-left: 1.875rem !important;
  }
  .ms-xl-7 {
    margin-left: 2.1875rem !important;
  }
  .ms-xl-8 {
    margin-left: 2.5rem !important;
  }
  .ms-xl-9 {
    margin-left: 2.8125rem !important;
  }
  .ms-xl-10 {
    margin-left: 3.125rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.3125rem !important;
  }
  .m-xl-n2 {
    margin: -0.625rem !important;
  }
  .m-xl-n3 {
    margin: -0.9375rem !important;
  }
  .m-xl-n4 {
    margin: -1.25rem !important;
  }
  .m-xl-n5 {
    margin: -1.625rem !important;
  }
  .m-xl-n6 {
    margin: -1.875rem !important;
  }
  .m-xl-n7 {
    margin: -2.1875rem !important;
  }
  .m-xl-n8 {
    margin: -2.5rem !important;
  }
  .m-xl-n9 {
    margin: -2.8125rem !important;
  }
  .m-xl-n10 {
    margin: -3.125rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-xl-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-xl-n5 {
    margin-right: -1.625rem !important;
    margin-left: -1.625rem !important;
  }
  .mx-xl-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-xl-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important;
  }
  .mx-xl-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-xl-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important;
  }
  .mx-xl-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-xl-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-xl-n5 {
    margin-top: -1.625rem !important;
    margin-bottom: -1.625rem !important;
  }
  .my-xl-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-xl-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important;
  }
  .my-xl-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-xl-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important;
  }
  .my-xl-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.3125rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.625rem !important;
  }
  .mt-xl-n3 {
    margin-top: -0.9375rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.25rem !important;
  }
  .mt-xl-n5 {
    margin-top: -1.625rem !important;
  }
  .mt-xl-n6 {
    margin-top: -1.875rem !important;
  }
  .mt-xl-n7 {
    margin-top: -2.1875rem !important;
  }
  .mt-xl-n8 {
    margin-top: -2.5rem !important;
  }
  .mt-xl-n9 {
    margin-top: -2.8125rem !important;
  }
  .mt-xl-n10 {
    margin-top: -3.125rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.3125rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.625rem !important;
  }
  .me-xl-n3 {
    margin-right: -0.9375rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.25rem !important;
  }
  .me-xl-n5 {
    margin-right: -1.625rem !important;
  }
  .me-xl-n6 {
    margin-right: -1.875rem !important;
  }
  .me-xl-n7 {
    margin-right: -2.1875rem !important;
  }
  .me-xl-n8 {
    margin-right: -2.5rem !important;
  }
  .me-xl-n9 {
    margin-right: -2.8125rem !important;
  }
  .me-xl-n10 {
    margin-right: -3.125rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.625rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.25rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -1.625rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -1.875rem !important;
  }
  .mb-xl-n7 {
    margin-bottom: -2.1875rem !important;
  }
  .mb-xl-n8 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xl-n9 {
    margin-bottom: -2.8125rem !important;
  }
  .mb-xl-n10 {
    margin-bottom: -3.125rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.3125rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.625rem !important;
  }
  .ms-xl-n3 {
    margin-left: -0.9375rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.25rem !important;
  }
  .ms-xl-n5 {
    margin-left: -1.625rem !important;
  }
  .ms-xl-n6 {
    margin-left: -1.875rem !important;
  }
  .ms-xl-n7 {
    margin-left: -2.1875rem !important;
  }
  .ms-xl-n8 {
    margin-left: -2.5rem !important;
  }
  .ms-xl-n9 {
    margin-left: -2.8125rem !important;
  }
  .ms-xl-n10 {
    margin-left: -3.125rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.3125rem !important;
  }
  .p-xl-2 {
    padding: 0.625rem !important;
  }
  .p-xl-3 {
    padding: 0.9375rem !important;
  }
  .p-xl-4 {
    padding: 1.25rem !important;
  }
  .p-xl-5 {
    padding: 1.625rem !important;
  }
  .p-xl-6 {
    padding: 1.875rem !important;
  }
  .p-xl-7 {
    padding: 2.1875rem !important;
  }
  .p-xl-8 {
    padding: 2.5rem !important;
  }
  .p-xl-9 {
    padding: 2.8125rem !important;
  }
  .p-xl-10 {
    padding: 3.125rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-xl-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-xl-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-xl-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-xl-5 {
    padding-right: 1.625rem !important;
    padding-left: 1.625rem !important;
  }
  .px-xl-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-xl-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important;
  }
  .px-xl-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xl-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important;
  }
  .px-xl-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-xl-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-xl-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-xl-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-xl-5 {
    padding-top: 1.625rem !important;
    padding-bottom: 1.625rem !important;
  }
  .py-xl-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-xl-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important;
  }
  .py-xl-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xl-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important;
  }
  .py-xl-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.625rem !important;
  }
  .pt-xl-3 {
    padding-top: 0.9375rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.25rem !important;
  }
  .pt-xl-5 {
    padding-top: 1.625rem !important;
  }
  .pt-xl-6 {
    padding-top: 1.875rem !important;
  }
  .pt-xl-7 {
    padding-top: 2.1875rem !important;
  }
  .pt-xl-8 {
    padding-top: 2.5rem !important;
  }
  .pt-xl-9 {
    padding-top: 2.8125rem !important;
  }
  .pt-xl-10 {
    padding-top: 3.125rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.3125rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.625rem !important;
  }
  .pe-xl-3 {
    padding-right: 0.9375rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.25rem !important;
  }
  .pe-xl-5 {
    padding-right: 1.625rem !important;
  }
  .pe-xl-6 {
    padding-right: 1.875rem !important;
  }
  .pe-xl-7 {
    padding-right: 2.1875rem !important;
  }
  .pe-xl-8 {
    padding-right: 2.5rem !important;
  }
  .pe-xl-9 {
    padding-right: 2.8125rem !important;
  }
  .pe-xl-10 {
    padding-right: 3.125rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 1.625rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 1.875rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 2.1875rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 2.8125rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 3.125rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.3125rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.625rem !important;
  }
  .ps-xl-3 {
    padding-left: 0.9375rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.25rem !important;
  }
  .ps-xl-5 {
    padding-left: 1.625rem !important;
  }
  .ps-xl-6 {
    padding-left: 1.875rem !important;
  }
  .ps-xl-7 {
    padding-left: 2.1875rem !important;
  }
  .ps-xl-8 {
    padding-left: 2.5rem !important;
  }
  .ps-xl-9 {
    padding-left: 2.8125rem !important;
  }
  .ps-xl-10 {
    padding-left: 3.125rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1600px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.3125rem !important;
  }
  .gap-xxl-2 {
    gap: 0.625rem !important;
  }
  .gap-xxl-3 {
    gap: 0.9375rem !important;
  }
  .gap-xxl-4 {
    gap: 1.25rem !important;
  }
  .gap-xxl-5 {
    gap: 1.625rem !important;
  }
  .gap-xxl-6 {
    gap: 1.875rem !important;
  }
  .gap-xxl-7 {
    gap: 2.1875rem !important;
  }
  .gap-xxl-8 {
    gap: 2.5rem !important;
  }
  .gap-xxl-9 {
    gap: 2.8125rem !important;
  }
  .gap-xxl-10 {
    gap: 3.125rem !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.3125rem !important;
  }
  .m-xxl-2 {
    margin: 0.625rem !important;
  }
  .m-xxl-3 {
    margin: 0.9375rem !important;
  }
  .m-xxl-4 {
    margin: 1.25rem !important;
  }
  .m-xxl-5 {
    margin: 1.625rem !important;
  }
  .m-xxl-6 {
    margin: 1.875rem !important;
  }
  .m-xxl-7 {
    margin: 2.1875rem !important;
  }
  .m-xxl-8 {
    margin: 2.5rem !important;
  }
  .m-xxl-9 {
    margin: 2.8125rem !important;
  }
  .m-xxl-10 {
    margin: 3.125rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-xxl-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xxl-5 {
    margin-right: 1.625rem !important;
    margin-left: 1.625rem !important;
  }
  .mx-xxl-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-xxl-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important;
  }
  .mx-xxl-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xxl-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important;
  }
  .mx-xxl-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-xxl-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xxl-5 {
    margin-top: 1.625rem !important;
    margin-bottom: 1.625rem !important;
  }
  .my-xxl-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-xxl-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important;
  }
  .my-xxl-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xxl-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important;
  }
  .my-xxl-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.625rem !important;
  }
  .mt-xxl-3 {
    margin-top: 0.9375rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.25rem !important;
  }
  .mt-xxl-5 {
    margin-top: 1.625rem !important;
  }
  .mt-xxl-6 {
    margin-top: 1.875rem !important;
  }
  .mt-xxl-7 {
    margin-top: 2.1875rem !important;
  }
  .mt-xxl-8 {
    margin-top: 2.5rem !important;
  }
  .mt-xxl-9 {
    margin-top: 2.8125rem !important;
  }
  .mt-xxl-10 {
    margin-top: 3.125rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.3125rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.625rem !important;
  }
  .me-xxl-3 {
    margin-right: 0.9375rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.25rem !important;
  }
  .me-xxl-5 {
    margin-right: 1.625rem !important;
  }
  .me-xxl-6 {
    margin-right: 1.875rem !important;
  }
  .me-xxl-7 {
    margin-right: 2.1875rem !important;
  }
  .me-xxl-8 {
    margin-right: 2.5rem !important;
  }
  .me-xxl-9 {
    margin-right: 2.8125rem !important;
  }
  .me-xxl-10 {
    margin-right: 3.125rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 1.625rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 1.875rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 2.1875rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 2.8125rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 3.125rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.3125rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.625rem !important;
  }
  .ms-xxl-3 {
    margin-left: 0.9375rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.25rem !important;
  }
  .ms-xxl-5 {
    margin-left: 1.625rem !important;
  }
  .ms-xxl-6 {
    margin-left: 1.875rem !important;
  }
  .ms-xxl-7 {
    margin-left: 2.1875rem !important;
  }
  .ms-xxl-8 {
    margin-left: 2.5rem !important;
  }
  .ms-xxl-9 {
    margin-left: 2.8125rem !important;
  }
  .ms-xxl-10 {
    margin-left: 3.125rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.3125rem !important;
  }
  .m-xxl-n2 {
    margin: -0.625rem !important;
  }
  .m-xxl-n3 {
    margin: -0.9375rem !important;
  }
  .m-xxl-n4 {
    margin: -1.25rem !important;
  }
  .m-xxl-n5 {
    margin: -1.625rem !important;
  }
  .m-xxl-n6 {
    margin: -1.875rem !important;
  }
  .m-xxl-n7 {
    margin: -2.1875rem !important;
  }
  .m-xxl-n8 {
    margin: -2.5rem !important;
  }
  .m-xxl-n9 {
    margin: -2.8125rem !important;
  }
  .m-xxl-n10 {
    margin: -3.125rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -1.625rem !important;
    margin-left: -1.625rem !important;
  }
  .mx-xxl-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-xxl-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important;
  }
  .mx-xxl-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-xxl-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important;
  }
  .mx-xxl-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-xxl-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-xxl-n5 {
    margin-top: -1.625rem !important;
    margin-bottom: -1.625rem !important;
  }
  .my-xxl-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-xxl-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important;
  }
  .my-xxl-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-xxl-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important;
  }
  .my-xxl-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.3125rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.625rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -0.9375rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.25rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -1.625rem !important;
  }
  .mt-xxl-n6 {
    margin-top: -1.875rem !important;
  }
  .mt-xxl-n7 {
    margin-top: -2.1875rem !important;
  }
  .mt-xxl-n8 {
    margin-top: -2.5rem !important;
  }
  .mt-xxl-n9 {
    margin-top: -2.8125rem !important;
  }
  .mt-xxl-n10 {
    margin-top: -3.125rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.3125rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.625rem !important;
  }
  .me-xxl-n3 {
    margin-right: -0.9375rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.25rem !important;
  }
  .me-xxl-n5 {
    margin-right: -1.625rem !important;
  }
  .me-xxl-n6 {
    margin-right: -1.875rem !important;
  }
  .me-xxl-n7 {
    margin-right: -2.1875rem !important;
  }
  .me-xxl-n8 {
    margin-right: -2.5rem !important;
  }
  .me-xxl-n9 {
    margin-right: -2.8125rem !important;
  }
  .me-xxl-n10 {
    margin-right: -3.125rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.625rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.25rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -1.625rem !important;
  }
  .mb-xxl-n6 {
    margin-bottom: -1.875rem !important;
  }
  .mb-xxl-n7 {
    margin-bottom: -2.1875rem !important;
  }
  .mb-xxl-n8 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xxl-n9 {
    margin-bottom: -2.8125rem !important;
  }
  .mb-xxl-n10 {
    margin-bottom: -3.125rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.3125rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.625rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -0.9375rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.25rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -1.625rem !important;
  }
  .ms-xxl-n6 {
    margin-left: -1.875rem !important;
  }
  .ms-xxl-n7 {
    margin-left: -2.1875rem !important;
  }
  .ms-xxl-n8 {
    margin-left: -2.5rem !important;
  }
  .ms-xxl-n9 {
    margin-left: -2.8125rem !important;
  }
  .ms-xxl-n10 {
    margin-left: -3.125rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.3125rem !important;
  }
  .p-xxl-2 {
    padding: 0.625rem !important;
  }
  .p-xxl-3 {
    padding: 0.9375rem !important;
  }
  .p-xxl-4 {
    padding: 1.25rem !important;
  }
  .p-xxl-5 {
    padding: 1.625rem !important;
  }
  .p-xxl-6 {
    padding: 1.875rem !important;
  }
  .p-xxl-7 {
    padding: 2.1875rem !important;
  }
  .p-xxl-8 {
    padding: 2.5rem !important;
  }
  .p-xxl-9 {
    padding: 2.8125rem !important;
  }
  .p-xxl-10 {
    padding: 3.125rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-xxl-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-xxl-5 {
    padding-right: 1.625rem !important;
    padding-left: 1.625rem !important;
  }
  .px-xxl-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-xxl-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important;
  }
  .px-xxl-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xxl-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important;
  }
  .px-xxl-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-xxl-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-xxl-5 {
    padding-top: 1.625rem !important;
    padding-bottom: 1.625rem !important;
  }
  .py-xxl-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-xxl-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important;
  }
  .py-xxl-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xxl-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important;
  }
  .py-xxl-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.625rem !important;
  }
  .pt-xxl-3 {
    padding-top: 0.9375rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.25rem !important;
  }
  .pt-xxl-5 {
    padding-top: 1.625rem !important;
  }
  .pt-xxl-6 {
    padding-top: 1.875rem !important;
  }
  .pt-xxl-7 {
    padding-top: 2.1875rem !important;
  }
  .pt-xxl-8 {
    padding-top: 2.5rem !important;
  }
  .pt-xxl-9 {
    padding-top: 2.8125rem !important;
  }
  .pt-xxl-10 {
    padding-top: 3.125rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.3125rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.625rem !important;
  }
  .pe-xxl-3 {
    padding-right: 0.9375rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.25rem !important;
  }
  .pe-xxl-5 {
    padding-right: 1.625rem !important;
  }
  .pe-xxl-6 {
    padding-right: 1.875rem !important;
  }
  .pe-xxl-7 {
    padding-right: 2.1875rem !important;
  }
  .pe-xxl-8 {
    padding-right: 2.5rem !important;
  }
  .pe-xxl-9 {
    padding-right: 2.8125rem !important;
  }
  .pe-xxl-10 {
    padding-right: 3.125rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 1.625rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 1.875rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 2.1875rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 2.8125rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 3.125rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.3125rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.625rem !important;
  }
  .ps-xxl-3 {
    padding-left: 0.9375rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.25rem !important;
  }
  .ps-xxl-5 {
    padding-left: 1.625rem !important;
  }
  .ps-xxl-6 {
    padding-left: 1.875rem !important;
  }
  .ps-xxl-7 {
    padding-left: 2.1875rem !important;
  }
  .ps-xxl-8 {
    padding-left: 2.5rem !important;
  }
  .ps-xxl-9 {
    padding-left: 2.8125rem !important;
  }
  .ps-xxl-10 {
    padding-left: 3.125rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}

/*----------------------------------------*/
/*  01. Template default CSS
/*----------------------------------------*/
/*-- Google Font --*/
/*-- Common Style --*/
*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  position: relative;
  outline: none;
  visibility: visible;
  overflow-X: hidden;
  color: "";
}

body[dir="rtl"] {
  text-align: right;
}

a,
button {
  transition: all 0.3s ease 0s;
  outline: 0;
}

a:focus,
button:focus {
  outline: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

p {
  font-size: 15px;
  line-height: 1.8;
}

p:last-child {
  margin-bottom: 0;
}

ul, ol {
  padding: 0;
  list-style: none;
  margin: 0;
}

img {
  max-width: 100%;
}

/* Section Style */
.section,
.main-wrapper {
  float: left;
  width: 100%;
}

/* Section Padding */
.section-padding {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-padding-02 {
  padding-top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-02 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-02 {
    padding-top: 60px;
  }
}

/* Section Padding */
.section-title .title {
  font-size: 30px;
  font-weight: 700;
  color: #444;
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title .title {
    font-size: 24px;
  }
}

.section-title-02 .title {
  font-size: 36px;
  font-weight: 700;
  color: #131313;
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 3px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-02 .title {
    font-size: 24px;
  }
}

.section-title-03 .sub-title {
  font-size: 18px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: #8b8b8b;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .section-title-03 .sub-title {
    font-size: 16px;
  }
}

.section-title-03 .title {
  font-size: 48px;
  font-weight: 600;
  color: #4c5c76;
  margin-bottom: 0;
  margin-top: 5px;
  text-transform: uppercase;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-03 .title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-03 .title {
    font-size: 36px;
  }
}

@media only screen and (max-width: 575px) {
  .section-title-03 .title {
    font-size: 26px;
  }
}

/* tab Content */
.tab-content .tab-pane {
  display: block;
  overflow: hidden;
  height: 0;
  visibility: hidden;
  max-width: 100%;
  opacity: 0;
}

.tab-content .tab-pane.active {
  height: auto;
  visibility: visible;
  opacity: 1;
  overflow: visible;
}

.swiper-arrows {
  position: relative;
  display: flex;
  align-items: center;
}

.swiper-arrows .swiper-button-next,
.swiper-arrows .swiper-button-prev {
  position: relative;
  top: 0;
  margin-top: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #e6e6e6;
  display: block;
  left: 0;
  right: 0;
  margin: 0 3px;
  color: #e6e6e6;
  border-radius: 50%;
  font-size: 28px;
  transition: all 0.3s ease 0s;
  outline: none;
}

.swiper-arrows .swiper-button-next:hover,
.swiper-arrows .swiper-button-prev:hover {
  color: #fff;
  background-color: #f2a100;
  border-color: #f2a100;
}

.swiper-arrows .swiper-button-next::after,
.swiper-arrows .swiper-button-prev::after {
  display: none;
}

.bg-color-01 {
  background-color: #f8f8f8;
}

.bg-color-02 {
  background-color: #eaeaea;
}

.pagination {
  margin-top: 60px;
}

.pagination .page-item {
  margin: 0 6px;
}

.pagination .page-item .page-link {
  font-weight: 400;
  color: #000;
  padding: 0;
  width: 36px;
  height: 36px;
  line-height: 36px;
  background: #f6f6f6;
  font-size: 14px;
  display: inline-block;
  text-align: center;
  vertical-align: top;
  border: 0;
  transition: all 0.3s linear;
}

@media only screen and (max-width: 575px) {
  .pagination .page-item .page-link {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}

.pagination .page-item .page-link:hover, .pagination .page-item .page-link.active {
  background-color: #f2a100;
  color: #fff;
}

/*----------------------------------------*/
/*  02. Component CSS
/*----------------------------------------*/
/*--
/*  2.1 - Button CSS
/*----------------------------------------*/
.btn {
  position: relative;
  z-index: 2;
  overflow: hidden;
  text-transform: uppercase;
  letter-spacing: 2px;
}

@media only screen and (max-width: 767px) {
  .btn {
    font-size: 14px;
  }
}

.btn::before {
  width: 100%;
  height: 100%;
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease 0s;
  transform: scaleX(0);
}

.btn i {
  margin-left: 8px;
}

.btn:hover::before {
  transform: scaleX(1);
}

.btn-primary {
  color: #fff;
}

.btn-hover-primary:hover {
  border-color: #f2a100;
  color: #fff;
}

.btn-hover-primary:hover::before {
  background-color: #f2a100;
}

.btn-outline-primary {
  border: 1px solid #f2a100;
  color: #f2a100;
}

.btn-outline-primary:hover {
  background: #f2a100;
}

.btn-secondary {
  color: #fff;
}

.btn-hover-secondary:hover {
  border-color: #252c51;
  color: #fff;
}

.btn-hover-secondary:hover::before {
  background-color: #252c51;
}

.btn-outline-secondary {
  border: 1px solid #252c51;
  color: #252c51;
}

.btn-outline-secondary:hover {
  background: #252c51;
}

.btn-success {
  color: #fff;
}

.btn-hover-success:hover {
  border-color: #198754;
  color: #fff;
}

.btn-hover-success:hover::before {
  background-color: #198754;
}

.btn-outline-success {
  border: 1px solid #198754;
  color: #198754;
}

.btn-outline-success:hover {
  background: #198754;
}

.btn-info {
  color: #494949;
}

.btn-hover-info:hover {
  border-color: #0dcaf0;
  color: #fff;
}

.btn-hover-info:hover::before {
  background-color: #0dcaf0;
}

.btn-outline-info {
  border: 1px solid #0dcaf0;
  color: #0dcaf0;
}

.btn-outline-info:hover {
  background: #0dcaf0;
}

.btn-warning {
  color: #494949;
}

.btn-hover-warning:hover {
  border-color: #ffc107;
  color: #494949;
}

.btn-hover-warning:hover::before {
  background-color: #ffc107;
}

.btn-outline-warning {
  border: 1px solid #ffc107;
  color: #ffc107;
}

.btn-outline-warning:hover {
  background: #ffc107;
}

.btn-danger {
  color: #fff;
}

.btn-hover-danger:hover {
  border-color: #dc3545;
  color: #fff;
}

.btn-hover-danger:hover::before {
  background-color: #dc3545;
}

.btn-outline-danger {
  border: 1px solid #dc3545;
  color: #dc3545;
}

.btn-outline-danger:hover {
  background: #dc3545;
}

.btn-light {
  color: #494949;
}

.btn-hover-light:hover {
  border-color: #f8f9fa;
  color: #494949;
}

.btn-hover-light:hover::before {
  background-color: #f8f9fa;
}

.btn-outline-light {
  border: 1px solid #f8f9fa;
  color: #f8f9fa;
}

.btn-outline-light:hover {
  background: #f8f9fa;
}

.btn-dark {
  color: #fff;
}

.btn-hover-dark:hover {
  border-color: #494949;
  color: #fff;
}

.btn-hover-dark:hover::before {
  background-color: #494949;
}

.btn-outline-dark {
  border: 1px solid #494949;
  color: #494949;
}

.btn-outline-dark:hover {
  background: #494949;
}

/*--
/*  2.2 - Blockquote CSS
/*----------------------------------------*/
blockquote {
  position: relative;
  margin-bottom: 0;
  padding-left: 35px;
  margin-top: 40px;
}

blockquote::before {
  position: absolute;
  content: '';
  width: 2px;
  height: 100%;
  background-color: #f2a100;
  top: 0;
  left: 0;
}

blockquote p {
  font-size: 18px;
  font-style: italic;
  font-weight: 400 !important;
  line-height: 1.83;
  color: #6c788c;
  margin-bottom: 0;
  margin-top: 0 !important;
}

blockquote .blockquote-name {
  font-size: 20px;
  font-weight: 600;
  color: #6c788c;
  padding-left: 40px;
  position: relative;
  margin-top: 22px;
}

blockquote .blockquote-name::before {
  position: absolute;
  content: '';
  width: 30px;
  height: 2px;
  background-color: #f2a100;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

/*--
/*  2.3 - Form CSS
/*----------------------------------------*/
.single-form {
  margin-top: 30px;
}

.single-form input:not([type="checkbox"]):not([type="radio"]),
.single-form textarea {
  width: 100%;
  height: 50px;
  padding: 0 20px;
  font-size: 14px;
  font-style: italic;
  color: #999999;
  transition: all 0.3s ease 0s;
  border: 1px solid #d7d7d7;
  border-radius: 0;
  background-color: #fff;
}

.single-form input:not([type="checkbox"]):not([type="radio"])::-webkit-input-placeholder,
.single-form textarea::-webkit-input-placeholder {
  opacity: 1;
}

.single-form input:not([type="checkbox"]):not([type="radio"]):-moz-placeholder,
.single-form textarea:-moz-placeholder {
  opacity: 1;
}

.single-form input:not([type="checkbox"]):not([type="radio"])::-moz-placeholder,
.single-form textarea::-moz-placeholder {
  opacity: 1;
}

.single-form input:not([type="checkbox"]):not([type="radio"]):-ms-input-placeholder,
.single-form textarea:-ms-input-placeholder {
  opacity: 1;
}

.single-form input:not([type="checkbox"]):not([type="radio"]) + input,
.single-form textarea + input {
  margin-top: 15px;
}

.single-form input:not([type="checkbox"]):not([type="radio"]):focus,
.single-form textarea:focus {
  border-color: #f2a100;
  outline: none;
}

.single-form label {
  font-size: 15px;
  color: #252c51;
  margin-bottom: 12px;
}

.single-form input[type="checkbox"] {
  display: none;
}

.single-form input[type="checkbox"] + label {
  padding-left: 22px;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
  font-size: 15px;
}

.single-form input[type="checkbox"] + label span {
  width: 14px;
  height: 14px;
  border: 1px solid #e1e1e1;
  position: absolute;
  display: block;
  top: 4px;
  left: 0;
  transition: all 0.3s ease 0s;
}

.single-form input[type="checkbox"] + label span::before {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  content: "\f107";
  font-family: FontAwesome;
  font-size: 14px;
  line-height: 12px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
  color: #fff;
  text-align: center;
}

.single-form input[type="checkbox"]:checked + label span {
  background-color: #f2a100;
  border-color: #f2a100;
}

.single-form input[type="checkbox"]:checked + label span::before {
  opacity: 1;
  visibility: visible;
}

.single-form textarea {
  padding-top: 10px;
  height: 250px;
  resize: none;
}

.single-form .btn {
  height: 50px;
  line-height: 50px;
  padding: 0 40px;
  font-size: 14px;
  font-weight: 500;
}

.radio input[type="radio"] {
  display: none;
}

.radio input[type="radio"] + label {
  padding-left: 20px;
  position: relative;
  font-size: 14px;
  line-height: 27px;
}

.radio input[type="radio"] + label span {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: 1px solid #494949;
  display: block;
  position: absolute;
  top: 6px;
  left: 0;
}

.radio input[type="radio"] + label span::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #494949;
  top: 0;
  left: 0;
  transform: scale(0);
  transition: all 0.3s linear;
}

.radio input[type="radio"]:checked + label span::before {
  transform: scale(0.7);
}

.single-select2 {
  margin-top: 20px;
}

.single-select2 span {
  display: block;
}

.single-select2 span:focus {
  outline: none;
}

.single-select2 .form-select2 .select2 {
  width: 100% !important;
}

.single-select2 .form-select2 .select2-container--default .select2-selection--single {
  height: 50px;
  border-color: #d7d7d7;
  transition: all 0.3s linear;
  border-radius: 0;
  outline: none;
}

@media only screen and (max-width: 767px) {
  .single-select2 .form-select2 .select2-container--default .select2-selection--single {
    height: 40px;
  }
}

.single-select2 .form-select2 .select2-container--default .select2-selection--single .select2-selection__rendered {
  width: 100%;
  line-height: 48px;
  border-radius: 0px;
  padding-left: 20px;
  padding-right: 28px;
  font-style: italic;
  font-size: 14px;
  color: #999999;
}

@media only screen and (max-width: 767px) {
  .single-select2 .form-select2 .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 38px;
  }
}

.single-select2 .form-select2 .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 48px;
  width: 36px;
}

.single-select2 .form-select2 .select2-container--default.select2-container--open .select2-selection--single {
  border-color: #f2a100;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: #ebebeb;
}

.select2-container--default .select2-search--dropdown .select2-search__field:focus {
  outline: none;
}

.select2-dropdown {
  border-color: #ebebeb;
}

.select2-results__option {
  font-size: 14px;
}

.gj-picker {
  border: 1px solid #eeecec;
  padding: 10px;
}

.gj-picker div[role="navigator"] div:first-child,
.gj-picker div[role="navigator"] div:last-child {
  max-width: 42px;
}

.gj-picker table tr td.selected.gj-cursor-pointer div {
  color: #fff;
  background: #f2a100;
}

.gj-picker table tr td.today div {
  color: #f2a100;
}

.gj-picker table tr td div,
.gj-picker table tr th div {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 12px;
}

/*--
/*  2.4 - Quick View CSS
/*----------------------------------------*/
.modal.fade {
  display: block !important;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
}

.modal.fade.show {
  display: block !important;
  opacity: 1;
  visibility: visible;
}

.modal .modal-dialog {
  width: calc(100% - 30px);
  max-width: 64rem;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal .modal-dialog {
    max-width: 45rem;
  }
}

@media only screen and (max-width: 767px) {
  .modal .modal-dialog {
    max-width: 30rem;
  }
}

.modal .modal-dialog .modal-content {
  position: relative;
  border: 0;
}

.modal .modal-dialog .modal-content .btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 7;
}

.modal .modal-dialog .modal-content .btn-close:focus {
  box-shadow: none;
}

.modal .modal-dialog .modal-content .modal-body {
  padding: 30px;
  padding-bottom: 60px;
}

@media only screen and (max-width: 575px) {
  .modal .modal-dialog .modal-content .modal-body {
    padding: 20px;
    padding-bottom: 50px;
  }
}

.quick-view-images {
  margin-top: 30px;
}

.quick-gallery-images .single-img img {
  width: 100%;
}

.quick-gallery-thumbs {
  position: relative;
}

@media only screen and (max-width: 575px) {
  .quick-gallery-thumbs {
    padding: 0 30px;
  }
}

.quick-gallery-thumbs .swiper-container {
  max-width: 380px;
  margin: 20px auto 0;
}

@media only screen and (max-width: 575px) {
  .quick-gallery-thumbs .swiper-container {
    max-width: 100%;
  }
}

.quick-gallery-thumbs .swiper-container img {
  width: 100%;
}

.quick-gallery-thumbs .swiper-slide {
  opacity: 0.2;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}

.quick-gallery-thumbs .swiper-slide.swiper-slide-thumb-active {
  opacity: 1;
}

.quick-gallery-thumbs .swiper-button-next,
.quick-gallery-thumbs .swiper-button-prev {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #e7e7e7;
  background-color: #f8f8f8;
  display: block;
  color: #e6e6e6;
  border-radius: 50%;
  font-size: 28px;
  transition: all 0.3s ease 0s;
  outline: none;
  opacity: 1;
  margin-top: 0;
  transform: translateY(-50%);
}

@media only screen and (max-width: 575px) {
  .quick-gallery-thumbs .swiper-button-next,
  .quick-gallery-thumbs .swiper-button-prev {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 20px;
  }
}

.quick-gallery-thumbs .swiper-button-next:hover,
.quick-gallery-thumbs .swiper-button-prev:hover {
  color: #fff;
  background-color: #f2a100;
  border-color: #f2a100;
}

.quick-gallery-thumbs .swiper-button-next::after,
.quick-gallery-thumbs .swiper-button-prev::after {
  display: none;
}

.quick-gallery-thumbs .swiper-button-next {
  right: 0;
}

.quick-gallery-thumbs .swiper-button-prev {
  left: 0;
}

.quick-view-description {
  margin-top: 25px;
}

.quick-view-description .product-name {
  font-size: 24px;
  font-weight: 600;
  color: #000;
  margin-bottom: 0;
}

@media only screen and (max-width: 575px) {
  .quick-view-description .product-name {
    font-size: 20px;
  }
}

.quick-view-description .price {
  display: flex;
  align-items: center;
}

.quick-view-description .price .sale-price {
  font-size: 20px;
  color: #f2a100;
  margin-top: 5px;
}

@media only screen and (max-width: 575px) {
  .quick-view-description .price .sale-price {
    font-size: 18px;
  }
}

.quick-view-description .price .old-price {
  font-size: 18px;
  color: #a4a4a4;
  margin-top: 5px;
  margin-left: 10px;
  text-decoration: line-through;
}

@media only screen and (max-width: 575px) {
  .quick-view-description .price .old-price {
    font-size: 16px;
  }
}

.quick-view-description .review-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-top: 12px;
}

.quick-view-description .review-wrapper .review-star {
  position: relative;
  display: inline-block;
  margin-right: 8px;
}

.quick-view-description .review-wrapper .review-star::before {
  content: "\f005" "\f005" "\f005" "\f005" "\f005";
  font-family: FontAwesome;
  display: inline-block;
  color: #dbdbdb;
  font-weight: 400;
  line-height: 1;
  vertical-align: middle;
  font-size: 14px;
  letter-spacing: 5px;
}

@media only screen and (max-width: 575px) {
  .quick-view-description .review-wrapper .review-star::before {
    letter-spacing: 2px;
  }
}

.quick-view-description .review-wrapper .review-star .star {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 2;
  overflow: hidden;
}

.quick-view-description .review-wrapper .review-star .star::before {
  content: "\f005" "\f005" "\f005" "\f005" "\f005";
  font-family: FontAwesome;
  display: inline-block;
  color: #ffc600;
  font-weight: 400;
  line-height: 1;
  vertical-align: middle;
  font-size: 14px;
  letter-spacing: 5px;
}

@media only screen and (max-width: 575px) {
  .quick-view-description .review-wrapper .review-star .star::before {
    letter-spacing: 2px;
  }
}

.quick-view-description .review-wrapper p {
  font-size: 16px;
  font-family: "Rajdhani", sans-serif;
  font-weight: 600;
  color: #9f9e9e;
  margin-top: 0;
  margin-bottom: 0;
}

.quick-view-description .lable {
  color: #3f3d3d;
  font-size: 16px;
  font-family: "Rajdhani", sans-serif;
  font-weight: 600;
  margin-right: 5px;
}

.quick-view-description .value {
  color: #9f9e9e;
  font-size: 16px;
  font-family: "Rajdhani", sans-serif;
  font-weight: 600;
}

.quick-view-description .product-color {
  display: flex;
  padding-top: 25px;
}

.quick-view-description .product-color ul {
  display: flex;
}

.quick-view-description .product-color ul li {
  margin-left: 15px;
}

.quick-view-description .product-color ul li input[type="checkbox"],
.quick-view-description .product-color ul li input[type="radio"] {
  display: none;
}

.quick-view-description .product-color ul li input[type="checkbox"] + label,
.quick-view-description .product-color ul li input[type="radio"] + label {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 16px;
  color: #666666;
  font-family: "Rajdhani", sans-serif;
  cursor: pointer;
  display: block;
}

.quick-view-description .product-color ul li input[type="checkbox"] + label span,
.quick-view-description .product-color ul li input[type="radio"] + label span {
  width: 15px;
  height: 15px;
  border-radius: 3px;
  border: 2px solid #888888;
  display: inline-block;
  position: relative;
  top: 2px;
  transition: all 0.3s ease 0s;
}

.quick-view-description .product-color ul li input[type="checkbox"] + label span::before,
.quick-view-description .product-color ul li input[type="radio"] + label span::before {
  position: absolute;
  content: "\f107";
  font-family: FontAwesome;
  font-size: 14px;
  width: 15px;
  height: 15px;
  line-height: 15px;
  top: -3px;
  left: -2px;
  text-align: center;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
}

.quick-view-description .product-color ul li input[type="checkbox"]:checked + label span,
.quick-view-description .product-color ul li input[type="radio"]:checked + label span {
  background-color: #ffb300;
  border-color: #ffb300;
}

.quick-view-description .product-color ul li input[type="checkbox"]:checked + label span::before,
.quick-view-description .product-color ul li input[type="radio"]:checked + label span::before {
  opacity: 1;
  visibility: visible;
}

.quick-view-description p {
  line-height: 1.8;
  color: #6c788c;
  margin-bottom: 0;
  margin-top: 25px;
}

.quick-view-description .product-meta {
  display: flex;
  padding-top: 30px;
  flex-wrap: wrap;
}

.quick-view-description .product-meta > * {
  margin-right: 10px;
}

.quick-view-description .product-quantity {
  position: relative;
  margin-top: 10px;
  background-color: #494949;
  border-radius: 5px;
}

.quick-view-description .product-quantity button {
  background: none;
  color: #fff;
  height: 40px;
  border: 0;
  font-weight: 500;
  font-size: 16px;
  padding: 0;
}

.quick-view-description .product-quantity input {
  width: 35px;
  height: 40px;
  border: 0;
  padding: 0 5px;
  background: none;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  color: #fff;
  text-align: center;
}

.quick-view-description .product-quantity > *:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-left: 16px;
}

.quick-view-description .product-quantity > *:last-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-right: 16px;
}

.quick-view-description .meta-action .btn {
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  font-weight: 500;
  font-size: 14px;
  margin-top: 10px;
  letter-spacing: 0;
}

.quick-view-description .meta-action .action {
  width: 40px;
  height: 40px;
  line-height: 44px;
  text-align: center;
  font-size: 23px;
  color: #fff;
  background-color: #494949;
  display: block;
  border-radius: 5px;
  margin-top: 10px;
  transition: all 0.3s ease 0s;
}

.quick-view-description .meta-action .action:hover {
  background-color: #f2a100;
}

.quick-view-description .product-info {
  padding-top: 33px;
}

.quick-view-description .product-info .single-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 8px;
}

.quick-view-description .social {
  display: flex;
  margin-left: 10px;
}

.quick-view-description .social li {
  margin-right: 25px;
}

.quick-view-description .social li a {
  font-size: 16px;
  color: #9f9e9e;
  transition: all 0.3s ease 0s;
}

.quick-view-description .social li a:hover {
  color: #f2a100;
}

/*----------------------------------------*/
/*  03. Header CSS
/*----------------------------------------*/
/*--
/*  3.1 - Header Main CSS
/*----------------------------------------*/
.header-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  padding: 35px 0;
}

.header-area.header-white {
  background-color: #fff;
  padding: 15px 0;
}

.header-menu ul {
  display: flex;
  justify-content: center;
}

.header-menu ul li {
  padding: 28px 18px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-menu ul li {
    padding: 28px 15px;
  }
}

.header-menu ul li a {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: #383838;
  transition: all 0.3s ease 0s;
  display: block;
}

.header-menu ul li.active > a, .header-menu ul li:hover > a {
  color: #f2a100;
}

.header-menu ul li ul {
  display: block;
}

.header-menu ul li:hover > .sub-menu,
.header-menu ul li:hover > .mega-sub-menu {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-menu ul li:nth-last-of-type(2) .sub-menu li .sub-menu {
    left: auto;
    right: 100%;
  }
}

.header-menu .sub-menu {
  position: absolute;
  width: 255px;
  background-color: #fff;
  border-top: 2px solid #494949;
  padding: 10px 0px;
  box-shadow: 2px 2px 20px rgba(73, 73, 73, 0.1);
  top: 110%;
  left: 0;
  z-index: 9;
  transition: all 0.3s ease 0s;
  opacity: 0;
  visibility: hidden;
}

.header-menu .sub-menu li {
  padding: 0;
}

.header-menu .sub-menu li a {
  padding: 7px 20px;
}

.header-menu .sub-menu li .sub-menu {
  top: 0px;
  left: 100%;
  opacity: 0;
  visibility: hidden;
}

.header-menu .sub-menu li.menu-item-has-children > a::after {
  content: "\e684";
  font-family: 'Pe-icon-7-stroke';
  margin-left: 5px;
  font-size: 24px;
  line-height: 20px;
  display: block;
  float: right;
}

.header-menu .sub-menu li:hover > a {
  padding-left: 25px;
}

.header-menu .sub-menu li:hover .sub-menu {
  top: -12px;
  opacity: 1;
  visibility: visible;
}

.header-menu .mega-sub-menu {
  position: absolute;
  top: 110%;
  left: 0;
  right: 0;
  width: 100%;
  box-shadow: 2px 2px 20px rgba(73, 73, 73, 0.1);
  background-color: #fff;
  z-index: 9;
  padding: 30px 15px;
  transition: all 0.3s ease 0s;
  opacity: 0;
  visibility: hidden;
  display: flex;
  flex-wrap: wrap;
}

.header-menu .mega-sub-menu > li {
  width: 25%;
  padding: 0 15px;
}

.header-menu .mega-sub-menu > li.manu-banner {
  width: 50%;
  margin-top: 20px;
  position: relative;
}

.header-menu .mega-sub-menu > li.manu-banner img {
  width: 100%;
}

.header-menu .mega-sub-menu > li.manu-banner a::before {
  content: "";
  background: rgba(255, 255, 255, 0.3);
  bottom: 50%;
  top: 50%;
  left: 0;
  right: 0;
  pointer-events: none;
  -webkit-transition: all 900ms linear;
  -moz-transition: all 900ms linear;
  -ms-transition: all 900ms linear;
  -o-transition: all 900ms linear;
  transition: all 900ms linear;
}

.header-menu .mega-sub-menu > li.manu-banner a::after {
  content: "";
  background: rgba(255, 255, 255, 0.3);
  left: 51%;
  right: 50%;
  top: 0;
  bottom: 0;
  pointer-events: none;
  -webkit-transition: all 900ms linear;
  -moz-transition: all 900ms linear;
  -ms-transition: all 900ms linear;
  -o-transition: all 900ms linear;
  transition: all 900ms linear;
}

.header-menu .mega-sub-menu > li.manu-banner a:hover::before {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all 900ms linear;
  -moz-transition: all 900ms linear;
  -ms-transition: all 900ms linear;
  -o-transition: all 900ms linear;
  transition: all 900ms linear;
  opacity: 0;
}

.header-menu .mega-sub-menu > li.manu-banner a:hover::after {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all 900ms linear;
  -moz-transition: all 900ms linear;
  -ms-transition: all 900ms linear;
  -o-transition: all 900ms linear;
  transition: all 900ms linear;
  opacity: 0;
}

.header-menu .mega-sub-menu > li .menu-title {
  color: #494949;
  line-height: 1;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  display: block;
  padding-bottom: 20px;
  border-bottom: 1px solid #ebebeb;
}

.header-menu .mega-sub-menu > li .menu-title::after {
  display: none;
}

.header-menu .mega-sub-menu > li:hover > a {
  color: #f2a100;
}

.header-menu .mega-sub-menu > li .menu-item {
  flex-direction: column;
  margin-top: 20px;
}

.header-menu .mega-sub-menu > li .menu-item > li {
  padding: 0;
}

.header-menu .mega-sub-menu > li .menu-item > li > a {
  color: #6d707f;
  text-transform: capitalize;
  line-height: 32px;
  font-weight: 400;
  font-size: 14px;
  display: block;
}

.header-menu .mega-sub-menu > li .menu-item > li:hover > a {
  padding-left: 5px;
  color: #f2a100;
}

.header-meta {
  display: flex;
  justify-content: flex-end;
}

.header-meta > * {
  padding: 0 10px;
}

.header-meta .action {
  font-size: 24px;
  color: #000;
  position: relative;
  display: block;
}

@media only screen and (max-width: 575px) {
  .header-meta .action {
    font-size: 22px;
  }
}

.header-meta .action .number {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background-color: #f2a100;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  display: block;
  position: absolute;
  top: -2px;
  right: -9px;
  border-radius: 50%;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header-meta .action .number {
    right: 0;
  }
}

.header-meta .dropdown .dropdown-menu {
  border-radius: 0;
  border: 0;
  box-shadow: 0px 3px 25.5px 4.5px rgba(0, 0, 0, 0.06);
  transform: translate(0, 0) !important;
  top: 125% !important;
  left: 0 !important;
}

.header-meta .dropdown .dropdown-profile {
  min-width: 160px;
  padding: 8px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header-meta .dropdown .dropdown-profile {
    left: auto !important;
    right: 0 !important;
    top: 100% !important;
  }
}

.header-meta .dropdown .dropdown-profile li {
  line-height: 25px;
  font-size: 13px;
}

.header-meta .dropdown .dropdown-profile li a {
  display: block;
  width: 100%;
  padding: 3px 1.5rem;
  clear: both;
  font-weight: 400;
  color: #373a3c;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0;
}

.header-meta .dropdown .dropdown-profile li a:hover {
  color: #f2a100;
}

.header-meta .dropdown .dropdown-cart {
  left: auto !important;
  right: -15px !important;
  width: 350px;
}

.header-meta .dropdown .dropdown-cart .cart-content {
  max-height: 292px;
  overflow-y: scroll;
}

.header-meta .dropdown .dropdown-cart .cart-content::-webkit-scrollbar-track {
  background-color: #ebebeb;
  border-radius: 10px;
}

.header-meta .dropdown .dropdown-cart .cart-content::-webkit-scrollbar {
  width: 5px;
  background-color: #ebebeb;
  border-radius: 10px;
}

.header-meta .dropdown .dropdown-cart .cart-content::-webkit-scrollbar-thumb {
  background-color: #f2a100;
  border-radius: 10px;
}

.header-meta .dropdown .dropdown-cart .cart-content ul {
  padding: 0 30px;
}

.header-meta .dropdown .dropdown-cart .cart-content ul li {
  padding: 30px 0;
  border-bottom: 1px solid #e6e6e6;
  overflow: hidden;
}

.header-meta .dropdown .dropdown-cart .cart-price {
  overflow: hidden;
  padding: 30px;
}

.header-meta .dropdown .dropdown-cart .cart-price .price-inline {
  overflow: hidden;
  line-height: 25px;
  display: flex;
  justify-content: space-between;
}

.header-meta .dropdown .dropdown-cart .cart-price .price-inline .label {
  font-weight: 400;
  color: #a7a7a7;
  font-size: 14px;
  text-transform: uppercase;
  display: block;
}

.header-meta .dropdown .dropdown-cart .cart-price .price-inline .value {
  font-weight: 300;
  color: #a7a7a7;
  font-size: 14px;
  display: block;
}

.header-meta .dropdown .dropdown-cart .cart-price .cart-total .price-inline .label {
  font-weight: 600;
  color: #494949;
}

.header-meta .dropdown .dropdown-cart .cart-price .cart-total .price-inline .value {
  font-weight: 600;
  color: #494949;
}

.header-meta .dropdown .dropdown-cart .checkout-btn {
  padding: 0 30px 30px 30px;
}

.header-meta .dropdown .dropdown-cart .checkout-btn .btn {
  height: 50px;
  line-height: 50px;
  font-size: 16px;
}

.header-meta .dropdown .dropdown-search {
  left: 50% !important;
  right: auto !important;
  transform: translateX(-50%) !important;
  background: #fff;
  width: 360px;
  border-radius: 5px;
  padding: 15px;
}

.single-cart-item {
  display: flex;
}

.single-cart-item .cart-thumb {
  position: relative;
}

.single-cart-item .cart-thumb img {
  width: 85px;
}

.single-cart-item .cart-thumb .product-quantity {
  position: absolute;
  top: 5px;
  left: 5px;
  min-width: 20px;
  line-height: 20px;
  border-radius: 100%;
  padding: 0;
  text-align: center;
  color: #fff;
  font-size: 12px;
  display: inline-block;
  background-color: #f2a100;
}

.single-cart-item .cart-item-content {
  flex: 1;
  padding-left: 10px;
  padding-right: 20px;
  overflow: hidden;
  position: relative;
}

.single-cart-item .cart-item-content .product-name {
  display: block;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 20px;
  color: #4c4c4c;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
}

.single-cart-item .cart-item-content .product-price {
  display: block;
  margin: 5px 0 0;
  font-size: 14px;
  font-weight: 400;
  color: #f2a100;
}

.single-cart-item .cart-item-content .attributes-content {
  margin-top: 5px;
}

.single-cart-item .cart-item-content .attributes-content span {
  font-size: 14px;
  line-height: 20px;
  color: #a7a7a7;
  display: block;
  font-weight: 400;
}

.single-cart-item .cart-item-content .attributes-content span strong {
  font-weight: 400;
}

.single-cart-item .cart-item-content .cart-remove {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  color: #494949;
  font-size: 18px;
  line-height: 1.2;
  transition: all 0.3s linear;
}

.single-cart-item .cart-item-content .cart-remove:hover {
  color: #f2a100;
}

.header-search {
  position: relative;
  border-radius: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header-search {
    margin-bottom: 20px;
  }
}

.header-search input {
  padding: 10px 45px 10px 20px;
  width: 100%;
  height: 46px;
  font-size: 14px;
  background: #fff;
  color: #494949;
  border: 1px solid #f2a100;
  border-radius: 5px;
  outline: none;
}

.header-search button {
  position: absolute;
  top: 0;
  right: 0;
  height: 46px;
  line-height: 46px;
  text-align: center;
  width: 46px;
  background: none;
  text-align: center;
  color: #494949;
  padding: 0;
  border: 0;
  transition: all 0.3s ease 0s;
}

.header-search button i {
  font-size: 24px;
  display: block;
  line-height: 46px;
}

.header-mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 93;
  background-color: #fff;
}

.header-mobile-top {
  padding: 20px 0;
}

.header-toggle .mobile-menu-open {
  display: inline-block;
  background: none;
  border: 0;
  padding: 0;
}

.header-toggle .mobile-menu-open span {
  width: 23px;
  height: 2px;
  background-color: #494949;
  display: block;
}

.header-toggle .mobile-menu-open span:nth-of-type(2) {
  margin: 5px 0;
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  animation: sticky 1s;
  box-shadow: 2px 4px 8px rgba(73, 73, 73, 0.15);
  background-color: #fff;
  padding: 0 !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .sticky {
    padding: 12px 0 !important;
  }
}

@-webkit-keyframes sticky {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes sticky {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

/*--
/*  3.4 - Off Canvas CSS
/*----------------------------------------*/
.off-canvas-box {
  position: fixed;
  top: 0;
  left: -320px;
  width: 320px;
  height: 100%;
  background-color: #fff;
  z-index: 967;
  overflow-y: auto;
  transition: all 0.3s linear;
}

.off-canvas-box.open {
  left: 0;
}

.canvas-action {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 20px;
  border-bottom: 1px solid #ebebeb;
}

.canvas-action .action {
  font-weight: 400;
  color: #000;
  background: transparent;
  text-shadow: none;
  cursor: pointer;
  line-height: 20px;
  font-size: 13px;
  display: inline-block;
  text-transform: capitalize;
  text-decoration: none;
  margin: 0 15px;
  transition: all 0.3s linear;
}

.canvas-action .action:hover {
  color: #f2a100;
}

.canvas-close-bar {
  background-color: #252c51;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  height: 40px;
  line-height: 40px;
}

.canvas-close-bar a {
  text-decoration: none;
  color: #fff;
  font-size: 30px;
}

.canvas-close-bar a i {
  line-height: 40px;
}

.canvas-menu {
  padding-bottom: 30px;
}

.canvas-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.canvas-menu ul li {
  position: relative !important;
  display: block;
  line-height: 1;
}

.canvas-menu ul li a {
  display: block;
  color: #383838;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 20px;
  padding-right: 50px;
  line-height: 30px;
  text-transform: capitalize;
  border-top: 1px solid #ebebeb;
  text-decoration: none;
}

.canvas-menu ul li a img {
  margin-right: 5px;
}

.canvas-menu ul li .mobile-menu-expand {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 13px;
  right: 21px;
  cursor: pointer;
}

.canvas-menu ul li .mobile-menu-expand::before {
  position: absolute;
  content: '';
  width: 12px;
  height: 2px;
  background-color: #383838;
  top: 7px;
  left: 2px;
}

.canvas-menu ul li .mobile-menu-expand::after {
  position: absolute;
  content: '';
  width: 2px;
  height: 12px;
  background-color: #383838;
  left: 7px;
  bottom: 2px;
  transition: all 0.1s linear;
}

.canvas-menu ul li.active-expand > .mobile-menu-expand::after {
  height: 0;
}

.canvas-menu ul li ul li a {
  padding-left: 11%;
}

.canvas-menu ul li ul li ul li a {
  padding-left: 16%;
}

.canvas-menu ul li ul li ul li ul li a {
  padding-left: 21%;
}

.canvas-menu ul li ul li ul li ul li ul li a {
  padding-left: 26%;
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0;
  visibility: hidden;
  z-index: 99;
  transition: all 0.3s linear;
}

.menu-overlay.open {
  opacity: 0.6;
  visibility: visible;
}

/*----------------------------------------*/
/*  04. Page CSS
/*----------------------------------------*/
/*--
/*  4.1 - Slider CSS
/*----------------------------------------*/
.slider-section {
  background-color: #e9e1f7;
  position: relative;
  overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-section {
    padding-top: 150px;
  }
}

.slider-section .slider-shape {
  width: 700px;
  height: 700px;
  border-radius: 50px;
  background-color: #eee5ff;
  position: absolute;
  top: -150px;
  right: -90px;
  transform: rotate(45deg);
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-section .slider-shape {
    width: 550px;
    height: 550px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-section .slider-shape {
    width: 350px;
    height: 350px;
    top: 0;
  }
}

@media only screen and (max-width: 575px) {
  .slider-section .slider-shape {
    width: 300px;
    height: 300px;
  }
}

.slider-section-02 {
  background-color: #f8f6ec;
  position: relative;
  overflow: hidden;
}

.single-slider {
  height: 950px;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .single-slider {
    height: 880px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-slider {
    height: 750px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-slider {
    height: auto;
    padding-top: 115px;
    padding-bottom: 130px;
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .single-slider {
    height: auto;
    padding-top: 245px;
    display: block;
  }
}

@media only screen and (max-width: 575px) {
  .single-slider {
    padding-top: 195px;
  }
}

.single-slider.slider-02 {
  background-size: cover;
  background-position: center center;
  height: 940px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-slider.slider-02 {
    height: 750px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .single-slider.slider-02 {
    height: auto;
    padding-top: 275px;
    padding-bottom: 130px;
  }
}

@media only screen and (max-width: 575px) {
  .single-slider.slider-02 {
    padding-top: 215px;
    padding-bottom: 80px;
  }
}

.slider-images {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: -1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-images {
    width: 65%;
  }
}

@media only screen and (max-width: 767px) {
  .slider-images {
    position: relative;
    padding-bottom: 50px;
    margin-top: 50px;
    transform: translateY(0);
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    top: 0;
  }
}

@media only screen and (max-width: 575px) {
  .slider-images {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-images img {
    width: 507px;
  }
}

.slider-content {
  max-width: 570px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content {
    max-width: 370px;
  }
}

.slider-content .title {
  font-size: 80px;
  font-weight: 700;
  color: #313131;
  line-height: 1.15;
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content .title {
    font-size: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .slider-content .title {
    font-size: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-content .title {
    font-size: 36px;
  }
}

.slider-content p {
  font-size: 24px;
  color: #313131;
  margin-bottom: 0;
  margin-top: 12px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .slider-content p {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 575px) {
  .slider-content p {
    font-size: 14px;
  }
  .slider-content p br {
    display: none;
  }
}

.slider-content .btn {
  margin-top: 40px;
  font-weight: 500;
}

@media only screen and (min-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1599px) {
  .slider-content .btn {
    height: 60px;
    line-height: 58px;
    padding: 0 48px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .slider-content .btn {
    height: 50px;
    line-height: 48px;
    padding: 0 25px;
    font-size: 14px;
  }
}

.slider-content-02 .title {
  font-size: 80px;
  font-weight: 700;
  color: #313131;
  line-height: 1.15;
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-02 .title {
    font-size: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .slider-content-02 .title {
    font-size: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-content-02 .title {
    font-size: 36px;
  }
}

.slider-content-02 p {
  font-size: 24px;
  color: #313131;
  margin-bottom: 0;
  margin-top: 12px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .slider-content-02 p {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 575px) {
  .slider-content-02 p {
    font-size: 14px;
  }
  .slider-content-02 p br {
    display: none;
  }
}

.slider-content-02 .btn {
  margin-top: 40px;
  font-weight: 500;
}

@media only screen and (min-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1599px) {
  .slider-content-02 .btn {
    height: 60px;
    line-height: 58px;
    padding: 0 48px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .slider-content-02 .btn {
    height: 50px;
    line-height: 48px;
    padding: 0 25px;
    font-size: 14px;
  }
}

.animation-style-01 .slider-images,
.animation-style-01 .slider-content,
.animation-style-01 .slider-content-02 {
  opacity: 0;
}

.animation-style-01.swiper-slide-active .slider-content,
.animation-style-01.swiper-slide-active .slider-content-02 {
  opacity: 1;
}

.animation-style-01.swiper-slide-active .slider-content .title,
.animation-style-01.swiper-slide-active .slider-content-02 .title {
  animation-name: fadeInUpBig;
  animation-delay: 0.4s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-01.swiper-slide-active .slider-content p,
.animation-style-01.swiper-slide-active .slider-content-02 p {
  animation-name: fadeInUpBig;
  animation-delay: 0.8s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-01.swiper-slide-active .slider-content .btn,
.animation-style-01.swiper-slide-active .slider-content-02 .btn {
  animation-name: fadeInUpBig;
  animation-delay: 1.2s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-01.swiper-slide-active .slider-images {
  opacity: 1;
}

.animation-style-01.swiper-slide-active .slider-images img {
  animation-name: fadeInRightBig;
  animation-delay: 1.2s;
  animation-duration: 1.3s;
  animation-fill-mode: both;
}

.slider-active .swiper-pagination {
  text-align: left;
  bottom: 70px;
}

@media only screen and (max-width: 575px) {
  .slider-active .swiper-pagination {
    bottom: 20px;
  }
}

.slider-active .swiper-pagination .swiper-pagination-bullet {
  outline: none;
  background-color: #212121;
  transition: all 0.3s ease 0s;
  opacity: 1;
  width: 10px;
  height: 10px;
  display: inline-block;
  position: relative;
  margin: 0 8px;
}

.slider-active .swiper-pagination .swiper-pagination-bullet::before {
  position: absolute;
  content: '';
  width: 16px;
  height: 16px;
  border: 1px solid transparent;
  top: -3px;
  left: -3px;
  border-radius: 50%;
  transition: all 0.3s ease 0s;
}

.slider-active .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #f2a100;
}

.slider-active .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::before {
  border-color: #f2a100;
}

.slider-active .swiper-button-next,
.slider-active .swiper-button-prev {
  font-family: "Rajdhani", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #373737;
  text-transform: uppercase;
  outline: none;
  line-height: 1.2;
  width: auto;
  height: auto;
  padding-bottom: 8px;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .slider-active .swiper-button-next,
  .slider-active .swiper-button-prev {
    margin-top: 50px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-active .swiper-button-next,
  .slider-active .swiper-button-prev {
    top: auto;
    bottom: 20px;
  }
}

.slider-active .swiper-button-next::before,
.slider-active .swiper-button-prev::before {
  position: absolute;
  bottom: 0;
  content: '';
  width: 100px;
  height: 2px;
  background-color: #373737;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .slider-active .swiper-button-next::before,
  .slider-active .swiper-button-prev::before {
    width: 150%;
  }
}

.slider-active .swiper-button-next::after,
.slider-active .swiper-button-prev::after {
  display: none;
}

.slider-active .swiper-button-next:hover,
.slider-active .swiper-button-prev:hover {
  color: #f2a100;
}

.slider-active .swiper-button-next:hover::before,
.slider-active .swiper-button-prev:hover::before {
  background-color: #f2a100;
}

.slider-active .swiper-button-prev {
  left: calc((100% - 1400px) / 2);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-active .swiper-button-prev {
    left: calc((100% - 920px) / 2);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-active .swiper-button-prev {
    left: calc((100% - 720px) / 2);
  }
}

@media only screen and (max-width: 767px) {
  .slider-active .swiper-button-prev {
    left: calc((100% - 540px) / 2);
  }
}

@media only screen and (max-width: 575px) {
  .slider-active .swiper-button-prev {
    left: 15px;
  }
}

.slider-active .swiper-button-prev::before {
  left: 0;
}

.slider-active .swiper-button-next {
  right: calc((100% - 1400px) / 2);
}

.slider-active .swiper-button-next::before {
  right: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-active .swiper-button-next {
    right: calc((100% - 920px) / 2);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-active .swiper-button-next {
    right: calc((100% - 720px) / 2);
  }
}

@media only screen and (max-width: 767px) {
  .slider-active .swiper-button-next {
    right: calc((100% - 540px) / 2);
  }
}

@media only screen and (max-width: 575px) {
  .slider-active .swiper-button-next {
    right: 15px;
  }
}

/*--
/*  4.2 - Banner CSS
/*----------------------------------------*/
.single-banner {
  position: relative;
  overflow: hidden;
  margin-top: 30px;
}

.single-banner img {
  width: 100%;
  transition: all 0.3s ease 0s;
}

.single-banner .banner-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 40px;
  padding-top: 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .single-banner .banner-content {
    padding: 20px;
    padding-top: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-banner .banner-content {
    padding: 25px;
    padding-top: 20px;
  }
}

.single-banner .banner-content .title {
  margin-bottom: 0;
}

.single-banner .banner-content .title a {
  font-size: 30px;
  font-weight: 400;
  color: #1c1c1c;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .single-banner .banner-content .title a {
    font-size: 24px;
  }
}

.single-banner .banner-content .title a:hover {
  color: #f2a100;
}

.single-banner .banner-content .discount {
  font-size: 34px;
  line-height: 1.3;
  font-weight: 600;
  font-family: "Rajdhani", sans-serif;
  color: #f2a100;
  display: block;
  margin-top: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .single-banner .banner-content .discount {
    font-size: 26px;
  }
}

.single-banner .banner-content .btn {
  margin-top: 16px;
}

.single-banner .banner-content.content-02 .title a {
  color: #fff;
  transition: all 0.3s ease 0s;
}

.single-banner .banner-content.content-02 .title a:hover {
  color: #f2a100;
}

.single-banner .banner-content.content-02 .discount {
  color: #444444;
}

.single-banner:hover img {
  transform: scale(1.1);
}

.single-banner-02 {
  position: relative;
  overflow: hidden;
}

.single-banner-02 img {
  width: 100%;
  transition: all 0.3s ease 0s;
}

.single-banner-02 .banner-content {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 40px;
  padding-top: 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-banner-02 .banner-content {
    padding: 20px;
    padding-top: 15px;
  }
}

.single-banner-02 .banner-content .title {
  margin-bottom: 0;
}

.single-banner-02 .banner-content .title a {
  font-size: 36px;
  font-weight: 400;
  color: #fff;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-banner-02 .banner-content .title a {
    font-size: 30px;
  }
}

.single-banner-02 .banner-content .title a:hover {
  color: #f2a100;
}

.single-banner-02 .banner-content .discount {
  font-size: 54px;
  line-height: 1.3;
  font-weight: 700;
  font-family: "Rajdhani", sans-serif;
  color: #f2a100;
  display: block;
  margin-top: 6px;
  color: #fff;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-banner-02 .banner-content .discount {
    font-size: 34px;
  }
}

.single-banner-02 .banner-content .btn {
  margin-top: 20px;
}

.single-banner-02:hover img {
  transform: scale(1.1);
}

.single-banner-03 {
  position: relative;
  overflow: hidden;
  margin-top: 30px;
}

.single-banner-03 img {
  width: 100%;
  transition: all 0.3s ease 0s;
}

.single-banner-03 .banner-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 60px;
  padding-top: 55px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 767px) {
  .single-banner-03 .banner-content {
    padding: 40px;
    padding-top: 35px;
  }
}

@media only screen and (max-width: 575px) {
  .single-banner-03 .banner-content {
    padding: 20px;
    padding-top: 15px;
  }
}

.single-banner-03 .banner-content .sub-title {
  font-size: 20px;
  font-weight: 500;
  color: #1c1c1c;
  margin-bottom: 0;
}

@media only screen and (max-width: 575px) {
  .single-banner-03 .banner-content .sub-title {
    font-size: 16px;
  }
}

.single-banner-03 .banner-content .title {
  margin-bottom: 0;
}

.single-banner-03 .banner-content .title a {
  font-size: 40px;
  font-weight: 600;
  color: #f2a100;
  transition: all 0.3s ease 0s;
  margin-top: 5px;
  display: inline-block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-banner-03 .banner-content .title a {
    font-size: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .single-banner-03 .banner-content .title a {
    font-size: 24px;
  }
}

.single-banner-03 .banner-content .title a:hover {
  color: #f2a100;
}

.single-banner-03 .banner-content .btn {
  margin-top: 25px;
}

@media only screen and (max-width: 575px) {
  .single-banner-03 .banner-content .btn {
    margin-top: 15px;
  }
}

.single-banner-03:hover img {
  transform: scale(1.1);
}

/*--
/*  4.3 - Product CSS
/*----------------------------------------*/
.product-top-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
  border-bottom: 1px solid #e6e6e6;
}

@media only screen and (max-width: 767px) {
  .product-top-wrapper {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 575px) {
  .product-top-wrapper > *:first-child {
    width: 100%;
  }
}

.product-menu .nav li {
  margin: 0 16px;
}

@media only screen and (max-width: 575px) {
  .product-menu .nav li {
    margin-bottom: 10px;
  }
}

.product-menu .nav li:first-child {
  margin-left: 0;
}

.product-menu .nav li:last-child {
  margin-right: 0;
}

.product-menu .nav li button {
  padding: 0;
  border: 0;
  background: none;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  color: #a7a7a7;
}

@media only screen and (max-width: 575px) {
  .product-menu .nav li button {
    font-size: 13px;
  }
}

.product-menu .nav li button.active, .product-menu .nav li button:hover {
  color: #f2a100;
}

.single-product {
  position: relative;
  margin-top: 30px;
}

.single-product a {
  display: block;
}

.single-product a img {
  width: 100%;
}

.single-product .product-content {
  padding-top: 13px;
  padding-right: 45px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 767px) {
  .single-product .product-content {
    padding-right: 35px;
  }
}

@media only screen and (max-width: 575px) {
  .single-product .product-content {
    padding-right: 45px;
  }
}

.single-product .product-content .title {
  margin-bottom: 0;
}

.single-product .product-content .title a {
  font-size: 16px;
  font-weight: 400;
  font-family: "Rubik", sans-serif;
  color: #4c4c4c;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 767px) {
  .single-product .product-content .title a {
    font-size: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .single-product .product-content .title a {
    font-size: 16px;
  }
}

.single-product .product-content .title a:hover {
  color: #f2a100;
}

.single-product .product-content .price {
  display: flex;
  align-items: center;
}

.single-product .product-content .price .sale-price {
  font-size: 18px;
  color: #f2a100;
  margin-top: 3px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 767px) {
  .single-product .product-content .price .sale-price {
    font-size: 16px;
  }
}

@media only screen and (max-width: 575px) {
  .single-product .product-content .price .sale-price {
    font-size: 18px;
  }
}

.single-product .product-content .price .old-price {
  font-size: 16px;
  color: #a4a4a4;
  margin-top: 3px;
  margin-left: 10px;
  text-decoration: line-through;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 767px) {
  .single-product .product-content .price .old-price {
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .single-product .product-content .price .old-price {
    font-size: 16px;
  }
}

.single-product .product-meta {
  position: absolute;
  bottom: 0;
  right: 0;
}

.single-product .product-meta li {
  overflow: hidden;
}

.single-product .product-meta li + li {
  margin-top: 15px;
}

.single-product .product-meta li .action {
  width: 44px;
  height: 44px;
  line-height: 44px;
  border: 1px solid #ededed;
  text-align: center;
  font-size: 23px;
  color: #000;
  transition: all 0.3s ease 0s;
  background-color: #fff;
  transform: translateY(100%);
  opacity: 0;
  visibility: hidden;
  display: block;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 767px) {
  .single-product .product-meta li .action {
    width: 34px;
    height: 34px;
    line-height: 34px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .single-product .product-meta li .action {
    width: 44px;
    height: 44px;
    line-height: 44px;
    font-size: 23px;
  }
}

.single-product .product-meta li:last-child a {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  background-color: transparent;
}

.single-product:hover .product-meta li .action {
  border-color: #3d3d3d;
  background-color: #3d3d3d;
  color: #fff;
  position: relative;
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.single-product:hover .product-meta li .action:nth-last-of-type(1) {
  transition-delay: 0;
}

.single-product:hover .product-meta li .action:nth-last-of-type(2) {
  transition-delay: 0.3;
}

.single-product:hover .product-meta li .action:nth-last-of-type(3) {
  transition-delay: 0.6;
}

.single-product:hover .product-meta li .action:nth-last-of-type(4) {
  transition-delay: 0.9;
}

.single-product:hover .product-meta li .action:hover {
  border-color: #f2a100;
  background-color: #f2a100;
  color: #fff;
}

.single-product-02 {
  position: relative;
  margin-top: 30px;
}

.single-product-02 .product-images {
  position: relative;
}

.single-product-02 .product-images a {
  display: block;
}

.single-product-02 .product-images a img {
  width: 100%;
}

.single-product-02 .product-images .product-meta {
  position: absolute;
  bottom: 5px;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
}

.single-product-02 .product-images .product-meta li {
  overflow: hidden;
}

.single-product-02 .product-images .product-meta li + li {
  margin-left: 5px;
}

.single-product-02 .product-images .product-meta li .action {
  width: 44px;
  height: 44px;
  line-height: 44px;
  border: 1px solid #ededed;
  text-align: center;
  font-size: 23px;
  color: #000;
  transition: all 0.3s ease 0s;
  background-color: #fff;
  transform: translateY(100%);
  opacity: 0;
  visibility: hidden;
  display: block;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-product-02 .product-images .product-meta li .action {
    width: 34px;
    height: 34px;
    line-height: 34px;
    font-size: 20px;
  }
}

.single-product-02 .product-images .discount {
  position: absolute;
  top: 17px;
  right: 20px;
  font-size: 16px;
  color: #f2a100;
}

.single-product-02 .product-content {
  padding-top: 18px;
  text-align: center;
}

.single-product-02 .product-content .title {
  margin-bottom: 0;
}

.single-product-02 .product-content .title a {
  font-size: 16px;
  font-weight: 400;
  font-family: "Rubik", sans-serif;
  color: #4c4c4c;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-product-02 .product-content .title a {
    font-size: 15px;
  }
}

.single-product-02 .product-content .title a:hover {
  color: #f2a100;
}

.single-product-02 .product-content .price {
  display: flex;
  justify-content: center;
  align-items: center;
}

.single-product-02 .product-content .price .sale-price {
  font-size: 18px;
  color: #f2a100;
  margin-top: 3px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-product-02 .product-content .price .sale-price {
    font-size: 16px;
  }
}

.single-product-02 .product-content .price .old-price {
  font-size: 16px;
  color: #a4a4a4;
  margin-top: 3px;
  margin-left: 10px;
  text-decoration: line-through;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-product-02 .product-content .price .old-price {
    font-size: 14px;
  }
}

.single-product-02:hover .product-meta li .action {
  border-color: #3d3d3d;
  background-color: #3d3d3d;
  color: #fff;
  position: relative;
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.single-product-02:hover .product-meta li .action:nth-last-of-type(1) {
  transition-delay: 0;
}

.single-product-02:hover .product-meta li .action:nth-last-of-type(2) {
  transition-delay: 0.3;
}

.single-product-02:hover .product-meta li .action:nth-last-of-type(3) {
  transition-delay: 0.6;
}

.single-product-02:hover .product-meta li .action:nth-last-of-type(4) {
  transition-delay: 0.9;
}

.single-product-02:hover .product-meta li .action:hover {
  border-color: #f2a100;
  background-color: #f2a100;
  color: #fff;
}

.product-list {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .product-list {
    display: block;
  }
}

.product-list .product-images {
  position: relative;
  width: 320px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-list .product-images {
    width: 270px;
  }
}

@media only screen and (max-width: 767px) {
  .product-list .product-images {
    width: 100%;
  }
}

.product-list .product-content {
  padding-top: 0;
  padding-left: 30px;
  text-align: left;
  flex: 1;
  max-width: 570px;
  margin-top: -4px;
}

@media only screen and (max-width: 767px) {
  .product-list .product-content {
    margin-top: 0;
    padding-top: 25px;
    padding-left: 0;
  }
}

.product-list .product-content .title a {
  font-size: 20px;
}

.product-list .product-content .price {
  justify-content: flex-start;
  margin-top: 5px;
}

.product-list .product-content p {
  margin-bottom: 0;
  margin-top: 15px;
}

.product-wrapper-02 {
  padding-top: 20px;
}

.product-wrapper-02 .product-tabs-content {
  padding-top: 20px;
}

@media only screen and (max-width: 767px) {
  .product-wrapper-02 .product-tabs-content {
    padding-top: 0;
  }
}

/*--
/*  4.4 - Call to Action CSS
/*----------------------------------------*/
.call-to-action {
  background: #f1eee5;
  height: 696px;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 767px) {
  .call-to-action {
    height: 526px;
  }
}

.call-to-action-content .title {
  color: #ffb300;
  font-size: 72px;
  font-weight: 400;
  font-family: "Great Vibes", cursive;
}

@media only screen and (max-width: 767px) {
  .call-to-action-content .title {
    font-size: 52px;
  }
}

@media only screen and (max-width: 575px) {
  .call-to-action-content .title {
    font-size: 32px;
  }
}

.call-to-action-content p {
  font-size: 24px;
  font-weight: 400;
  color: #313131;
}

@media only screen and (max-width: 767px) {
  .call-to-action-content p {
    font-size: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .call-to-action-content p {
    font-size: 16px;
  }
}

.call-to-action-content .btn {
  margin-top: 40px;
  font-weight: 500;
}

@media only screen and (min-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1599px) {
  .call-to-action-content .btn {
    height: 60px;
    line-height: 58px;
    padding: 0 48px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .call-to-action-content .btn {
    height: 50px;
    line-height: 48px;
    padding: 0 25px;
    font-size: 14px;
  }
}

/*--
/*  4.5 - Call to Action CSS
/*----------------------------------------*/
.single-benefit {
  text-align: center;
  border: 1px solid #f2a100;
  padding: 60px 50px 55px;
  margin-top: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .single-benefit {
    padding: 40px 30px 35px;
  }
}

.single-benefit .title {
  font-size: 30px;
  font-weight: 400;
  color: #303030;
  margin-top: 20px;
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .single-benefit .title {
    font-size: 24px;
  }
}

.single-benefit p {
  font-size: 14px;
  color: #6a6a69;
  line-height: 1.8;
  margin-bottom: 0;
  margin-top: 12px;
}

/*--
/*  4.6 - Products Banner CSS
/*----------------------------------------*/
.single-products-banner {
  position: relative;
  overflow: hidden;
}

.single-products-banner img {
  width: 100%;
}

.single-products-banner .products-banner-content {
  position: absolute;
  padding: 10px;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(100%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
}

.single-products-banner .products-banner-content .banner-content-wrapper {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px 30px;
  padding-right: 50px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 767px) {
  .single-products-banner .products-banner-content .banner-content-wrapper {
    padding: 10px 15px;
  }
}

.single-products-banner .products-banner-content .banner-content-wrapper .title {
  margin-bottom: 0;
}

.single-products-banner .products-banner-content .banner-content-wrapper .title a {
  font-size: 30px;
  font-weight: 700;
  color: #343434;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 767px) {
  .single-products-banner .products-banner-content .banner-content-wrapper .title a {
    font-size: 26px;
  }
}

.single-products-banner .products-banner-content .banner-content-wrapper .title a:hover {
  color: #f2a100;
}

.single-products-banner .products-banner-content .banner-content-wrapper .products-count {
  font-family: "Rajdhani", sans-serif;
  font-size: 24px;
  color: #343434;
  display: block;
  line-height: 1.2;
  margin-top: 3px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 767px) {
  .single-products-banner .products-banner-content .banner-content-wrapper .products-count {
    font-size: 20px;
  }
}

.single-products-banner .products-banner-content .banner-content-wrapper .arrow {
  width: 40px;
  height: 40px;
  line-height: 43px;
  text-align: center;
  border-radius: 50%;
  background-color: #f2a100;
  display: inline-block;
  font-size: 36px;
  color: #fff;
  transition: all 0.3s ease 0s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 767px) {
  .single-products-banner .products-banner-content .banner-content-wrapper .arrow {
    width: 35px;
    height: 35px;
    line-height: 38px;
    right: 15px;
    font-size: 30px;
  }
}

.single-products-banner .products-banner-content .banner-content-wrapper .arrow:hover {
  background-color: #494949;
  color: #fff;
}

.single-products-banner:hover .products-banner-content {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

/*--
/*  4.7 - Blog CSS
/*----------------------------------------*/
.blog-top-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
  border-bottom: 1px solid #e6e6e6;
}

.single-blog {
  margin-top: 50px;
}

.single-blog > a {
  display: block;
}

.single-blog > a img {
  width: 100%;
}

.single-blog .gallery-active {
  position: relative;
}

.single-blog .gallery-active a {
  display: block;
}

.single-blog .gallery-active a img {
  width: 100%;
}

.single-blog .gallery-active .swiper-button-next,
.single-blog .gallery-active .swiper-button-prev {
  margin-top: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #ededed;
  display: block;
  color: #ededed;
  border-radius: 50%;
  font-size: 28px;
  transition: all 0.3s ease 0s;
  outline: none;
  transform: translateY(-50%);
}

.single-blog .gallery-active .swiper-button-next:hover,
.single-blog .gallery-active .swiper-button-prev:hover {
  color: #fff;
  background-color: #f2a100;
  border-color: #f2a100;
}

.single-blog .gallery-active .swiper-button-next::after,
.single-blog .gallery-active .swiper-button-prev::after {
  display: none;
}

.single-blog .blog-embed {
  height: 100%;
}

.single-blog .blog-embed .ratio-16x9 {
  --bs-aspect-ratio: calc(9.35 / 15 * 100%);
}

.single-blog .blog-content {
  padding-top: 28px;
}

.single-blog .blog-content .blog-meta {
  display: flex;
}

.single-blog .blog-content .blog-meta li {
  font-size: 15px;
  font-weight: 500;
  color: #545454;
  font-family: "Rajdhani", sans-serif;
  display: inherit;
}

.single-blog .blog-content .blog-meta li i {
  font-size: 18px;
  color: #000;
  margin-right: 8px;
}

.single-blog .blog-content .blog-meta li + li {
  margin-left: 20px;
}

.single-blog .blog-content .title {
  margin-bottom: 0;
}

.single-blog .blog-content .title a {
  margin-top: 10px;
  display: inline-block;
  color: #383838;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.4;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .single-blog .blog-content .title a {
    font-size: 20px;
  }
}

.single-blog .blog-content .title a:hover {
  color: #f2a100;
}

.single-blog .blog-content p {
  margin-top: 15px;
  margin-bottom: 0;
}

.single-blog .blog-content .btn {
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  margin-top: 30px;
}

/*--
/*  4.8 - Countdown CSS
/*----------------------------------------*/
.countdown-content {
  margin-top: 45px;
}

.countdown-content .title {
  font-weight: 700;
  font-size: 48px;
  color: #303030;
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .countdown-content .title {
    font-size: 42px;
  }
}

@media only screen and (max-width: 575px) {
  .countdown-content .title {
    font-size: 28px;
  }
}

.countdown-content .title span {
  color: #f2a100;
}

.countdown-content p {
  max-width: 470px;
  color: #6a6a69;
  font-size: 14px;
  padding-top: 15px;
}

.countdown-content .btn {
  padding: 0 40px;
  height: 50px;
  line-height: 50px;
  font-weight: 500;
  margin-top: 40px;
}

.countdown-wrapper .countdown {
  display: flex;
}

.countdown-wrapper .countdown .single-countdown {
  text-align: center;
  position: relative;
  margin-top: 5px;
}

.countdown-wrapper .countdown .single-countdown + .single-countdown {
  margin-left: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .countdown-wrapper .countdown .single-countdown + .single-countdown {
    margin-left: 60px;
  }
}

@media only screen and (max-width: 575px) {
  .countdown-wrapper .countdown .single-countdown + .single-countdown {
    margin-left: 40px;
  }
}

.countdown-wrapper .countdown .single-countdown + .single-countdown::before {
  position: absolute;
  top: 0;
  left: -40px;
  content: ':';
  font-family: "Rajdhani", sans-serif;
  font-weight: 700;
  color: #303030;
  font-size: 60px;
  line-height: 1.3;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .countdown-wrapper .countdown .single-countdown + .single-countdown::before {
    font-size: 50px;
    left: -37px;
  }
}

@media only screen and (max-width: 575px) {
  .countdown-wrapper .countdown .single-countdown + .single-countdown::before {
    font-size: 30px;
    left: -24px;
  }
}

.countdown-wrapper .countdown .single-countdown .count {
  font-size: 60px;
  font-weight: 700;
  font-family: "Rajdhani", sans-serif;
  color: #303030;
  display: block;
  line-height: 1.3;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .countdown-wrapper .countdown .single-countdown .count {
    font-size: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .countdown-wrapper .countdown .single-countdown .count {
    font-size: 30px;
  }
}

.countdown-wrapper .countdown .single-countdown .value {
  font-size: 16px;
  font-weight: 600;
  font-family: "Rajdhani", sans-serif;
  text-transform: uppercase;
  color: #303030;
  display: block;
}

.countdown-images {
  position: relative;
  z-index: 1;
  text-align: right;
  margin-top: 50px;
}

.countdown-images .shape-1 {
  border-radius: 50%;
  background-color: #ffb300;
  position: absolute;
  left: 50px;
  top: 0px;
  width: 327px;
  height: 327px;
  z-index: -1;
}

.countdown-images .shape-2 {
  background: -moz-linear-gradient(0deg, #f8a324 0%, #ef4923 100%);
  background: -webkit-linear-gradient(0deg, #f8a324 0%, #ef4923 100%);
  background: -ms-linear-gradient(0deg, #f8a324 0%, #ef4923 100%);
  box-shadow: 0px 0px 24px 6px rgba(104, 104, 104, 0.16);
  position: absolute;
  left: 140px;
  top: 55px;
  width: 58px;
  height: 58px;
  z-index: -1;
  border-radius: 50%;
}

.countdown-images .shape-3 {
  border-radius: 50%;
  background-color: #ffa987;
  position: absolute;
  right: 50px;
  bottom: -176px;
  width: 278px;
  height: 278px;
  z-index: -1;
}

/*--
/*  4.9 - Marker CSS
/*----------------------------------------*/
.marker-wrapper {
  height: 300px;
  position: relative;
  display: flex;
  align-items: center;
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 767px) {
  .marker-wrapper {
    height: 230px;
  }
}

@media only screen and (max-width: 575px) {
  .marker-wrapper {
    background-size: 160% 100%;
    background-position: center right;
  }
}

.marker-wrapper .marker-content {
  padding: 50px 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .marker-wrapper .marker-content {
    padding: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .marker-wrapper .marker-content {
    padding: 35px;
  }
}

@media only screen and (max-width: 575px) {
  .marker-wrapper .marker-content {
    display: none;
  }
}

.marker-wrapper .marker-content .sub-title {
  color: #4a4f52;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

.marker-wrapper .marker-content .title {
  color: #4a4f52;
  font-size: 48px;
  font-weight: 700;
  margin-top: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .marker-wrapper .marker-content .title {
    font-size: 38px;
  }
}

.marker-wrapper .image-pointer {
  position: absolute;
  cursor: pointer;
}

.marker-wrapper .image-pointer.pointer-01 {
  top: 50%;
  left: 44%;
}

@media only screen and (max-width: 767px) {
  .marker-wrapper .image-pointer.pointer-01 {
    top: 48%;
  }
}

@media only screen and (max-width: 575px) {
  .marker-wrapper .image-pointer.pointer-01 {
    left: 10%;
  }
}

@media only screen and (max-width: 575px) {
  .marker-wrapper .image-pointer.pointer-01 .pointer-box {
    left: -30px;
    transform: translateX(0);
  }
}

.marker-wrapper .image-pointer.pointer-02 {
  top: 58px;
  left: 55.5%;
}

@media only screen and (max-width: 767px) {
  .marker-wrapper .image-pointer.pointer-02 {
    top: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .marker-wrapper .image-pointer.pointer-02 {
    left: 27%;
  }
}

.marker-wrapper .image-pointer.pointer-03 {
  top: 40%;
  left: 67%;
}

@media only screen and (max-width: 575px) {
  .marker-wrapper .image-pointer.pointer-03 {
    left: 46%;
  }
}

.marker-wrapper .image-pointer.pointer-04 {
  top: 39%;
  left: 80.5%;
}

@media only screen and (max-width: 575px) {
  .marker-wrapper .image-pointer.pointer-04 {
    left: 67%;
  }
}

.marker-wrapper .image-pointer i {
  width: 30px;
  height: 30px;
  line-height: 26px;
  border: 2px solid #fff;
  text-align: center;
  font-size: 12px;
  display: inline-block;
  color: #fff;
  border-radius: 50%;
  background-color: rgba(255, 179, 0, 0.81);
}

.marker-wrapper .image-pointer .pointer-box {
  width: 200px;
  padding: 15px 20px;
  background-color: #fff;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
  box-shadow: 2px 4px 8px rgba(73, 73, 73, 0.15);
  z-index: 5;
}

.marker-wrapper .image-pointer .pointer-box .name {
  margin-bottom: 0;
}

.marker-wrapper .image-pointer .pointer-box .name a {
  font-size: 16px;
  font-weight: 400;
  font-family: "Rubik", sans-serif;
  color: #4c4c4c;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .marker-wrapper .image-pointer .pointer-box .name a {
    font-size: 15px;
  }
}

.marker-wrapper .image-pointer .pointer-box .name a:hover {
  color: #f2a100;
}

.marker-wrapper .image-pointer .pointer-box .price {
  font-size: 18px;
  color: #f2a100;
  margin-top: 3px;
  display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .marker-wrapper .image-pointer .pointer-box .price {
    font-size: 16px;
  }
}

.marker-wrapper .image-pointer:hover .pointer-box {
  opacity: 1;
  visibility: visible;
}

/*--
/*  4.10 - Brand CSS
/*----------------------------------------*/
.brand-row {
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-self: center;
}

@media only screen and (max-width: 767px) {
  .brand-row {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 575px) {
  .brand-row {
    grid-template-columns: 1fr 1fr;
  }
}

.brand-row .brand-col .single-brand {
  margin-left: -1px;
}

@media only screen and (max-width: 767px) {
  .brand-row .brand-col .single-brand {
    margin-top: -1px;
  }
}

.single-brand {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e1e1e1;
  padding: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .single-brand {
    height: 150px;
  }
}

/*--
/*  4.11 - Page Banner CSS
/*----------------------------------------*/
.page-banner-section {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 500px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .page-banner-section {
    height: 400px;
  }
}

.page-banner-content {
  padding-top: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .page-banner-content {
    padding-top: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .page-banner-content {
    padding-top: 145px;
  }
}

.page-banner-content .title {
  font-size: 36px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
}

.page-banner-content .breadcrumb {
  margin-bottom: 0;
}

.page-banner-content .breadcrumb li {
  font-size: 14px;
  color: #262c52;
  text-transform: uppercase;
  position: relative;
  display: flex;
}

.page-banner-content .breadcrumb li.active {
  color: #fff;
}

.page-banner-content .breadcrumb li + li::before {
  content: "\e684";
  font-family: 'Pe-icon-7-stroke';
  font-size: 22px;
  line-height: 0.8;
}

/*--
/*  4.12 - History CSS
/*----------------------------------------*/
.history-content p {
  font-size: 16px;
  margin-top: 25px;
}

.history-icon {
  margin-top: 40px;
  position: relative;
  z-index: 1;
}

.history-icon::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 1px;
  background-color: #fcdd93;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.history-icon img {
  border-radius: 50%;
  position: relative;
}

/*--
/*  4.13 - Gallery CSS
/*----------------------------------------*/
.image-gallery {
  margin-top: 30px;
}

.image-gallery img {
  width: 100%;
}

/*--
/*  4.13 - Counter CSS
/*----------------------------------------*/
.single-counter {
  text-align: center;
  border: 1px solid #e1e1e1;
  padding: 30px;
  padding-top: 35px;
  margin-top: 30px;
}

@media only screen and (max-width: 575px) {
  .single-counter {
    padding: 25px;
  }
}

.single-counter .count {
  color: #ffb300;
  font-weight: 600;
  font-family: "Rajdhani", sans-serif;
  display: block;
}

.single-counter .count span {
  font-size: 60px;
  line-height: 0.8;
  font-family: "Rajdhani", sans-serif;
}

@media only screen and (max-width: 575px) {
  .single-counter .count span {
    font-size: 30px;
  }
}

.single-counter .count sub {
  font-size: 30px;
  bottom: -11px;
}

@media only screen and (max-width: 575px) {
  .single-counter .count sub {
    font-size: 18px;
    bottom: -2px;
  }
}

.single-counter p {
  color: #565656;
  font-size: 16px;
  font-weight: 600;
  font-family: "Rajdhani", sans-serif;
}

@media only screen and (max-width: 575px) {
  .single-counter p {
    font-size: 14px;
    line-height: 1.3;
    margin-top: 5px;
  }
}

/*--
/*  4.14 - Testimonial CSS
/*----------------------------------------*/
.testimonial-wrapper {
  max-width: 830px;
  margin: 0 auto;
  padding: 0 15px;
}

.single-testimonial {
  text-align: center;
}

.single-testimonial p {
  font-size: 18px;
  font-weight: 500;
  font-family: "Rajdhani", sans-serif;
  color: #4d4d4d;
  line-height: 1.7;
  margin-top: 30px;
  margin-bottom: 0;
}

.single-testimonial .author-thumb {
  border-radius: 50%;
  margin-top: 40px;
}

.single-testimonial .name {
  font-size: 16px;
  font-weight: 600;
  color: #313131;
  text-transform: uppercase;
  margin-bottom: 0;
  margin-top: 25px;
}

.single-testimonial .designation {
  font-family: "Rajdhani", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #656565;
  display: block;
  text-transform: uppercase;
}

.testimonial-active .swiper-pagination {
  bottom: 0px;
  position: relative;
  margin-top: 35px;
  height: 18px;
}

.testimonial-active .swiper-pagination .swiper-pagination-bullet {
  outline: none;
  background-color: #393939;
  transition: all 0.3s ease 0s;
  opacity: 1;
  width: 16px;
  height: 10px;
  display: inline-block;
  margin: 0 5px;
  border-radius: 50px;
}

.testimonial-active .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #f2a100;
  width: 25px;
}

/*--
/*  4.14 - Team CSS
/*----------------------------------------*/
.team-wrapper {
  padding-top: 14px;
}

.single-team {
  margin-top: 30px;
}

.single-team .team-images {
  overflow: hidden;
}

.single-team .team-images img {
  width: 100%;
  transition: all 0.3s ease 0s;
}

.single-team .team-content {
  text-align: center;
  padding-top: 22px;
}

.single-team .team-content .name {
  font-size: 24px;
  font-weight: 600;
  color: #4c5c76;
  text-transform: uppercase;
  margin-bottom: 0;
}

@media only screen and (max-width: 575px) {
  .single-team .team-content .name {
    font-size: 20px;
  }
}

.single-team .team-content .designation {
  font-size: 18px;
  font-weight: 600;
  font-family: "Rajdhani", sans-serif;
  color: #656565;
  text-transform: uppercase;
}

@media only screen and (max-width: 575px) {
  .single-team .team-content .designation {
    font-size: 16px;
  }
}

.single-team:hover .team-images img {
  transform: scale(1.1) rotate(3deg);
}

/*--
/*  4.15 - Shop CSS
/*----------------------------------------*/
.shop-top-bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 43px;
}

@media only screen and (max-width: 575px) {
  .shop-top-bar > * {
    width: 100%;
    text-align: center;
  }
}

.shop-top-bar .shop-text p {
  font-size: 18px;
  font-family: "Rajdhani", sans-serif;
  font-weight: 500;
  color: #6c6c6c;
  text-transform: uppercase;
}

.shop-top-bar .shop-text p span {
  color: #ffb300;
}

@media only screen and (max-width: 575px) {
  .shop-top-bar .shop-tabs .nav {
    justify-content: center;
  }
}

.shop-top-bar .shop-tabs .nav li {
  padding: 0 6px;
}

.shop-top-bar .shop-tabs .nav li button {
  border: 0;
  padding: 0;
  background: none;
  color: #9e9e9e;
  font-size: 18px;
}

.shop-top-bar .shop-tabs .nav li button:hover, .shop-top-bar .shop-tabs .nav li button.active {
  color: #000;
}

.shop-top-bar .shop-sort .title {
  white-space: nowrap;
  font-size: 18px;
  font-weight: 500;
  font-family: "Rajdhani", sans-serif;
  color: #6c6c6c;
}

.shop-top-bar .shop-sort .nice-select {
  border-radius: 0;
  border: 0;
  display: inline-block;
  float: none;
  font-family: "Rajdhani", sans-serif;
  font-size: 18px;
  font-weight: 500;
  height: 28px;
  line-height: 26px;
  outline: none;
  padding-left: 0;
  padding-right: 15px;
  color: #000;
}

.shop-top-bar .shop-sort .nice-select::after {
  display: none;
}

.shop-top-bar .shop-sort .nice-select .list {
  border-radius: 0;
}

.shop-top-bar .shop-sort .nice-select .list .option {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  line-height: 28px;
  list-style: none;
  min-height: 28px;
  outline: none;
  padding-left: 15px;
  padding-right: 15px;
  color: #6c6c6c;
}

/*--
/*  4.16 - Product Details CSS
/*----------------------------------------*/
.product-details-images {
  margin-top: 50px;
}

.details-gallery-images .single-img img {
  width: 100%;
}

.details-gallery-thumbs {
  position: relative;
}

@media only screen and (max-width: 575px) {
  .details-gallery-thumbs {
    padding: 0 30px;
  }
}

.details-gallery-thumbs .swiper-container {
  max-width: 410px;
  margin: 20px auto 0;
}

@media only screen and (max-width: 575px) {
  .details-gallery-thumbs .swiper-container {
    max-width: 100%;
  }
}

.details-gallery-thumbs .swiper-container img {
  width: 100%;
}

.details-gallery-thumbs .swiper-slide {
  opacity: 0.2;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}

.details-gallery-thumbs .swiper-slide.swiper-slide-thumb-active {
  opacity: 1;
}

.details-gallery-thumbs .swiper-button-next,
.details-gallery-thumbs .swiper-button-prev {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #e7e7e7;
  background-color: #f8f8f8;
  display: block;
  color: #e6e6e6;
  border-radius: 50%;
  font-size: 28px;
  transition: all 0.3s ease 0s;
  outline: none;
  opacity: 1;
  margin-top: 0;
  transform: translateY(-50%);
}

@media only screen and (max-width: 575px) {
  .details-gallery-thumbs .swiper-button-next,
  .details-gallery-thumbs .swiper-button-prev {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 20px;
  }
}

.details-gallery-thumbs .swiper-button-next:hover,
.details-gallery-thumbs .swiper-button-prev:hover {
  color: #fff;
  background-color: #f2a100;
  border-color: #f2a100;
}

.details-gallery-thumbs .swiper-button-next::after,
.details-gallery-thumbs .swiper-button-prev::after {
  display: none;
}

.details-gallery-thumbs .swiper-button-next {
  right: 0;
}

.details-gallery-thumbs .swiper-button-prev {
  left: 0;
}

.product-details-description {
  margin-top: 50px;
}

.product-details-description.product-details-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 130px;
  left: 0;
  width: 100%;
}

.product-details-description .product-name {
  font-size: 30px;
  font-weight: 600;
  color: #000;
  margin-bottom: 0;
}

@media only screen and (max-width: 575px) {
  .product-details-description .product-name {
    font-size: 24px;
  }
}

.product-details-description .price {
  display: flex;
  align-items: center;
}

.product-details-description .price .sale-price {
  font-size: 24px;
  color: #f2a100;
  margin-top: 5px;
}

@media only screen and (max-width: 575px) {
  .product-details-description .price .sale-price {
    font-size: 20px;
  }
}

.product-details-description .price .old-price {
  font-size: 20px;
  color: #a4a4a4;
  margin-top: 5px;
  margin-left: 10px;
  text-decoration: line-through;
}

@media only screen and (max-width: 575px) {
  .product-details-description .price .old-price {
    font-size: 16px;
  }
}

.product-details-description .review-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-top: 12px;
}

.product-details-description .review-wrapper .review-star {
  position: relative;
  display: inline-block;
  margin-right: 8px;
}

.product-details-description .review-wrapper .review-star::before {
  content: "\f005" "\f005" "\f005" "\f005" "\f005";
  font-family: FontAwesome;
  display: inline-block;
  color: #dbdbdb;
  font-weight: 400;
  line-height: 1;
  vertical-align: middle;
  font-size: 16px;
  letter-spacing: 10px;
}

@media only screen and (max-width: 575px) {
  .product-details-description .review-wrapper .review-star::before {
    letter-spacing: 5px;
  }
}

.product-details-description .review-wrapper .review-star .star {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 2;
  overflow: hidden;
}

.product-details-description .review-wrapper .review-star .star::before {
  content: "\f005" "\f005" "\f005" "\f005" "\f005";
  font-family: FontAwesome;
  display: inline-block;
  color: #ffc600;
  font-weight: 400;
  line-height: 1;
  vertical-align: middle;
  font-size: 16px;
  letter-spacing: 10px;
}

@media only screen and (max-width: 575px) {
  .product-details-description .review-wrapper .review-star .star::before {
    letter-spacing: 5px;
  }
}

.product-details-description .review-wrapper p {
  font-size: 16px;
  font-family: "Rajdhani", sans-serif;
  font-weight: 600;
  color: #9f9e9e;
  margin-top: 0;
  margin-bottom: 0;
}

.product-details-description .lable {
  color: #3f3d3d;
  font-size: 16px;
  font-family: "Rajdhani", sans-serif;
  font-weight: 600;
  margin-right: 5px;
}

.product-details-description .value {
  color: #9f9e9e;
  font-size: 16px;
  font-family: "Rajdhani", sans-serif;
  font-weight: 600;
}

.product-details-description .product-color {
  display: flex;
  padding-top: 25px;
}

.product-details-description .product-color ul {
  display: flex;
}

.product-details-description .product-color ul li {
  margin-left: 15px;
}

.product-details-description .product-color ul li input[type="checkbox"],
.product-details-description .product-color ul li input[type="radio"] {
  display: none;
}

.product-details-description .product-color ul li input[type="checkbox"] + label,
.product-details-description .product-color ul li input[type="radio"] + label {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 16px;
  color: #666666;
  font-family: "Rajdhani", sans-serif;
  cursor: pointer;
  display: block;
}

.product-details-description .product-color ul li input[type="checkbox"] + label span,
.product-details-description .product-color ul li input[type="radio"] + label span {
  width: 15px;
  height: 15px;
  border-radius: 3px;
  border: 2px solid #888888;
  display: inline-block;
  position: relative;
  top: 2px;
  transition: all 0.3s ease 0s;
}

.product-details-description .product-color ul li input[type="checkbox"] + label span::before,
.product-details-description .product-color ul li input[type="radio"] + label span::before {
  position: absolute;
  content: "\f107";
  font-family: FontAwesome;
  font-size: 14px;
  width: 15px;
  height: 15px;
  line-height: 15px;
  top: -3px;
  left: -2px;
  text-align: center;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
}

.product-details-description .product-color ul li input[type="checkbox"]:checked + label span,
.product-details-description .product-color ul li input[type="radio"]:checked + label span {
  background-color: #ffb300;
  border-color: #ffb300;
}

.product-details-description .product-color ul li input[type="checkbox"]:checked + label span::before,
.product-details-description .product-color ul li input[type="radio"]:checked + label span::before {
  opacity: 1;
  visibility: visible;
}

.product-details-description p {
  line-height: 1.8;
  color: #6c788c;
  margin-bottom: 0;
  margin-top: 25px;
}

.product-details-description .product-meta {
  display: flex;
  padding-top: 30px;
  flex-wrap: wrap;
}

.product-details-description .product-meta > * {
  margin-right: 10px;
}

.product-details-description .product-quantity {
  position: relative;
  margin-top: 10px;
  background-color: #494949;
  border-radius: 5px;
}

.product-details-description .product-quantity button {
  background: none;
  color: #fff;
  height: 50px;
  border: 0;
  font-weight: 500;
  font-size: 16px;
  padding: 0;
}

.product-details-description .product-quantity input {
  width: 35px;
  height: 50px;
  border: 0;
  padding: 0 5px;
  background: none;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  color: #fff;
  text-align: center;
}

.product-details-description .product-quantity > *:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-left: 16px;
}

.product-details-description .product-quantity > *:last-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-right: 16px;
}

.product-details-description .meta-action .btn {
  height: 50px;
  line-height: 50px;
  padding: 0 30px;
  font-weight: 500;
  font-size: 14px;
  margin-top: 10px;
}

.product-details-description .meta-action .action {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 23px;
  color: #fff;
  background-color: #494949;
  display: block;
  border-radius: 5px;
  margin-top: 10px;
  transition: all 0.3s ease 0s;
}

.product-details-description .meta-action .action:hover {
  background-color: #f2a100;
}

.product-details-description .product-info {
  padding-top: 33px;
}

.product-details-description .product-info .single-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 8px;
}

.product-details-description .social {
  display: flex;
  margin-left: 10px;
}

.product-details-description .social li {
  margin-right: 25px;
}

.product-details-description .social li a {
  font-size: 16px;
  color: #9f9e9e;
  transition: all 0.3s ease 0s;
}

.product-details-description .social li a:hover {
  color: #f2a100;
}

.product-details-description .product-table {
  margin-top: 40px;
}

.product-details-description .product-table .table {
  margin-bottom: 0;
}

.product-details-description .product-table .table tbody tr td {
  border: 1px solid #e1e1e1;
  vertical-align: middle;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #6d707f;
  width: 33.33%;
}

.product-details-description .product-table .table tbody tr td .product-quantity {
  margin-top: 0;
}

.product-details-description .product-table .table tbody tr td .amount {
  font-weight: 500;
  color: #f2a100;
}

.product-details-tabs .nav {
  border-bottom: 1px solid #d7d7d7;
  padding-bottom: 8px;
}

@media only screen and (max-width: 575px) {
  .product-details-tabs .nav {
    flex-direction: column;
    align-items: center;
  }
}

.product-details-tabs .nav li {
  margin: 0 5px;
}

.product-details-tabs .nav li button {
  color: #cccccc;
  font-size: 24px;
  font-weight: 600;
  font-family: "Rajdhani", sans-serif;
  border: 0;
  background: none;
  padding: 0 10px;
  position: relative;
  transition: all 0.3s ease 0s;
}

.product-details-tabs .nav li button::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 2px;
  background-color: #f2a100;
  bottom: -9px;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 575px) {
  .product-details-tabs .nav li button::before {
    display: none;
  }
}

.product-details-tabs .nav li button.active {
  color: #f2a100;
}

.product-details-tabs .nav li button.active::before {
  opacity: 1;
  visibility: visible;
}

.product-details-tabs .tab-content {
  max-width: 970px;
  margin: 0 auto;
}

.information-content {
  padding-top: 35px;
}

.information-content .title {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.information-content ul {
  list-style: disc;
  list-style-position: inside;
  margin-bottom: 1rem;
}

.information-content ul li {
  font-size: 15px;
  line-height: 1.8;
}

.information-content > *:last-child {
  margin-bottom: 0;
}

.description-content {
  text-align: center;
  margin-top: 35px;
}

.reviews-content {
  padding-top: 10px;
}

.reviews-content .reviews-title {
  font-weight: 700;
  font-size: 24px;
  color: #000;
}

.single-reviews {
  display: flex;
  margin-top: 30px;
}

@media only screen and (max-width: 575px) {
  .single-reviews {
    display: block;
  }
}

.single-reviews .comment-author img {
  border-radius: 50%;
}

.single-reviews .comment-content {
  flex: 1;
  padding-left: 30px;
}

@media only screen and (max-width: 575px) {
  .single-reviews .comment-content {
    padding-top: 25px;
    padding-left: 0;
  }
}

.single-reviews .comment-content .author-name-rating {
  display: flex;
  align-items: center;
}

.single-reviews .comment-content .author-name-rating .name {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 16px;
  color: #333333;
  margin-bottom: 0;
  line-height: 1;
  margin-right: 10px;
}

.single-reviews .comment-content .author-name-rating .review-star {
  position: relative;
  display: inline-block;
}

.single-reviews .comment-content .author-name-rating .review-star::before {
  content: "\f005" "\f005" "\f005" "\f005" "\f005";
  font-family: FontAwesome;
  display: inline-block;
  color: #dbdbdb;
  font-weight: 400;
  line-height: 1;
  vertical-align: middle;
  font-size: 12px;
  letter-spacing: 2px;
}

.single-reviews .comment-content .author-name-rating .review-star .star {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 2;
  overflow: hidden;
}

.single-reviews .comment-content .author-name-rating .review-star .star::before {
  content: "\f005" "\f005" "\f005" "\f005" "\f005";
  font-family: FontAwesome;
  display: inline-block;
  color: #ffc600;
  font-weight: 400;
  line-height: 1;
  vertical-align: middle;
  font-size: 12px;
  letter-spacing: 2px;
}

.single-reviews .comment-content .date {
  color: #adadad;
  font-size: 14px;
  margin-top: 5px;
}

.single-reviews .comment-content p {
  margin-top: 20px;
}

.reviews-form {
  margin-top: 85px;
}

.reviews-form .review-rating {
  display: flex;
  align-items: center;
  padding-top: 35px;
}

.reviews-form .review-rating .title {
  font-weight: 600;
  font-size: 16px;
  font-family: "Rajdhani", sans-serif;
  color: #494949;
  margin-bottom: 0;
  display: inline-block;
  white-space: nowrap;
  margin-right: 10px;
}

.reviews-form .review-rating ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.reviews-form .review-rating ul li {
  margin: 0 2px;
  font-size: 16px;
  cursor: pointer;
}

.reviews-form .review-rating ul li i {
  transition: all 0.3s ease 0s;
  color: #ffc600;
}

.reviews-form .review-rating ul li i.hover::before {
  content: "\f005" !important;
}

.reviews-form .form-title {
  font-size: 24px;
  font-weight: 400;
  color: #494949;
}

.reviews-form .form-check {
  margin-top: 30px;
}

.reviews-form .form-check .form-check-input {
  margin-top: 3px;
}

/*--
/*  4.17 - Cart CSS
/*----------------------------------------*/
.cart-table .table {
  margin-bottom: 0;
}

.cart-table .table thead tr th {
  background-color: #eaeaea;
  border: 1px solid #eaeaea;
  border-bottom: 0;
  padding: 10px 20px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  color: #494949;
}

.cart-table .table thead tr th.product-thumb {
  min-width: 150px;
  width: 150px;
}

.cart-table .table thead tr th.product-info {
  min-width: 330px;
  text-align: left;
}

.cart-table .table thead tr th.product-quantity {
  min-width: 120px;
}

.cart-table .table thead tr th.product-total-price {
  min-width: 90px;
}

.cart-table .table thead tr th.product-add-to-cart {
  min-width: 120px;
}

.cart-table .table thead tr th.product-action {
  min-width: 50px;
}

.cart-table .table tbody tr td {
  border: 1px solid #eaeaea;
  padding: 20px 20px;
  vertical-align: middle;
}

.cart-table .table tbody tr td.product-info .name a {
  text-decoration: none;
  font-weight: 600;
  line-height: 1;
  color: #494949;
  font-size: 16px;
  text-transform: capitalize;
  transition: all 0.3s linear;
}

.cart-table .table tbody tr td.product-info .name a:hover {
  color: #f2a100;
}

.cart-table .table tbody tr td.product-info .product-prices {
  margin-top: 15px;
}

.cart-table .table tbody tr td.product-info .product-prices .old-price {
  font-weight: 400;
  line-height: 20px;
  font-size: 16px;
  color: #b1b1b1;
  margin-right: 8px;
  text-decoration: line-through;
  display: inline-block;
}

.cart-table .table tbody tr td.product-info .product-prices .sale-price {
  color: #f2a100;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  display: inline-block;
  margin-right: 8px;
}

.cart-table .table tbody tr td.product-info .product-size-color p {
  font-size: 14px;
  margin-bottom: 0px;
  margin-top: 5px;
}

.cart-table .table tbody tr td.quantity {
  text-align: center;
}

.cart-table .table tbody tr td.quantity .product-quantity {
  position: relative;
  background-color: #494949;
  border-radius: 5px;
}

.cart-table .table tbody tr td.quantity .product-quantity button {
  background: none;
  color: #fff;
  height: 50px;
  border: 0;
  font-weight: 500;
  font-size: 16px;
  padding: 0;
}

.cart-table .table tbody tr td.quantity .product-quantity input {
  width: 35px;
  height: 50px;
  border: 0;
  padding: 0 5px;
  background: none;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  color: #fff;
  text-align: center;
}

.cart-table .table tbody tr td.quantity .product-quantity > *:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-left: 16px;
}

.cart-table .table tbody tr td.quantity .product-quantity > *:last-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-right: 16px;
}

.cart-table .table tbody tr td.product-total-price {
  text-align: center;
}

.cart-table .table tbody tr td.product-total-price .price {
  font-size: 20px;
}

.cart-table .table tbody tr td.product-add-to-cart {
  text-align: center;
}

.cart-table .table tbody tr td.product-add-to-cart .btn {
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  letter-spacing: 0;
}

.cart-table .table tbody tr td.product-action {
  text-align: center;
}

.cart-table .table tbody tr td.product-action a {
  text-decoration: none;
  font-size: 20px;
}

.cart-btn {
  border: 1px solid #ebebeb;
  padding: 10px 20px 20px;
  border-top: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 767px) {
  .cart-btn {
    padding: 10px 10px 20px;
  }
}

@media only screen and (max-width: 575px) {
  .cart-btn {
    display: block;
  }
}

.cart-btn .btn {
  margin-top: 10px;
  margin-right: 15px;
  height: 50px;
  line-height: 50px;
  padding: 0 30px;
  font-weight: 500;
  font-size: 14px;
}

@media only screen and (max-width: 767px) {
  .cart-btn .btn {
    padding: 0 13px;
    margin-right: 5px;
    font-size: 13px;
  }
}

.cart-btn .btn:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 575px) {
  .cart-btn .left-btn .btn {
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .cart-btn .right-btn {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 575px) {
  .cart-btn .right-btn .btn {
    width: 48%;
  }
}

.cart-shipping,
.cart-totals {
  margin-top: 48px;
}

.cart-title .title {
  color: #494949;
  font-size: 24px;
  font-weight: 600;
  text-transform: none;
  margin-bottom: 15px;
}

@media only screen and (max-width: 575px) {
  .cart-title .title {
    font-size: 20px;
  }
}

.cart-totals .cart-total-table {
  background-color: #eaeaea;
  padding: 10px 25px;
}

.cart-totals .cart-total-table .table {
  margin-bottom: 0;
}

.cart-totals .cart-total-table .table tbody tr td {
  padding: 5px 0;
  border-top: 0;
}

.cart-totals .cart-total-table .table tbody tr:last-child td {
  border-bottom: 1px;
}

.cart-totals .cart-total-table .shipping-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.cart-totals .cart-total-table .value {
  font-size: 14px;
  font: 400px;
  color: #000;
  text-transform: capitalize;
}

.cart-totals .cart-total-table .price {
  font-size: 14px;
  font: 400px;
  color: #000;
}

.cart-totals .cart-total-btn {
  margin-top: 30px;
}

.cart-totals .cart-total-btn .btn {
  height: 50px;
  line-height: 50px;
  padding: 0 30px;
  font-weight: 500;
  font-size: 14px;
}

@media only screen and (max-width: 575px) {
  .cart-totals .cart-total-btn .btn {
    margin-right: 5px;
  }
}

.empty-cart .empty-cart-title {
  color: #494949;
  font-size: 35px;
}

@media only screen and (max-width: 767px) {
  .empty-cart .empty-cart-title {
    font-size: 24px;
  }
}

.empty-cart .empty-cart-img {
  width: 110px;
  margin: 0 auto;
  padding-top: 25px;
}

.empty-cart p {
  font-size: 16px;
  text-transform: capitalize;
  margin-top: 30px;
  color: #494949;
}

.empty-cart .btn {
  height: 50px;
  line-height: 50px;
  padding: 0 30px;
  font-weight: 500;
}

/*--
/*  4.18 - Compare CSS
/*----------------------------------------*/
.compare-wrapper .table {
  margin-bottom: 0;
}

.compare-wrapper .table.table-striped > tbody > tr:nth-of-type(2n+1) {
  --bs-table-accent-bg: none;
  color: #333333;
}

.compare-wrapper .table.table-striped > tbody > tr:nth-of-type(2n+2) {
  background-color: #eeeeee;
}

.compare-wrapper .table tbody tr th,
.compare-wrapper .table tbody tr td {
  -webkit-box-flex: 0;
  color: #333333;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  border: 0;
  padding: 15px;
  min-width: 270px;
}

.compare-wrapper .table tbody tr th {
  white-space: nowrap;
}

@media only screen and (max-width: 767px) {
  .compare-wrapper .table tbody tr th {
    display: none;
  }
}

.compare-wrapper .table tbody tr td {
  font-weight: 500;
  font-size: 14px;
}

.compare-product {
  position: relative;
  display: inline-block;
  padding-top: 2.0rem;
  margin-bottom: 30px;
}

.compare-product .pack-product-container .thumb-mask {
  margin-bottom: 20px;
}

.compare-product .pack-product-container .thumb-mask a {
  display: block;
}

.compare-product .pack-product-container .desc-box .product-name {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 500;
  display: block;
}

.compare-product .pack-product-container .desc-box .product-reviews {
  margin-bottom: 10px;
}

.compare-product .pack-product-container .desc-box .product-reviews .review-star {
  position: relative;
  display: inline-block;
}

.compare-product .pack-product-container .desc-box .product-reviews .review-star::before {
  content: "\f005" "\f005" "\f005" "\f005" "\f005";
  font-family: FontAwesome;
  display: inline-block;
  color: #dbdbdb;
  font-weight: 400;
  line-height: 1;
  vertical-align: middle;
  font-size: 14px;
}

.compare-product .pack-product-container .desc-box .product-reviews .review-star .star {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 2;
  overflow: hidden;
}

.compare-product .pack-product-container .desc-box .product-reviews .review-star .star::before {
  content: "\f005" "\f005" "\f005" "\f005" "\f005";
  font-family: FontAwesome;
  display: inline-block;
  color: #ffc600;
  font-weight: 400;
  line-height: 1;
  vertical-align: middle;
  font-size: 14px;
}

.compare-product .pack-product-container .desc-box .product-prices {
  margin-top: 15px;
}

.compare-product .pack-product-container .desc-box .product-prices .old-price {
  font-size: 16px;
  color: #666666;
  font-weight: 400;
  text-decoration: line-through;
  margin-right: 5px;
}

@media only screen and (max-width: 575px) {
  .compare-product .pack-product-container .desc-box .product-prices .old-price {
    font-size: 18px;
  }
}

.compare-product .pack-product-container .desc-box .product-prices .sale-price {
  color: #f2a100;
  font-size: 18px;
  font-weight: 400;
  display: inline-block;
  margin-right: 13px;
}

@media only screen and (max-width: 575px) {
  .compare-product .pack-product-container .desc-box .product-prices .sale-price {
    font-size: 24px;
  }
}

.compare-product .pack-product-container .desc-box .product-cart {
  padding-top: 15px;
}

.compare-product .pack-product-container .desc-box .product-cart .btn {
  height: 45px;
  line-height: 45px;
  padding: 0 40px;
  font-weight: 500;
}

.compare-product .compare-remove {
  padding-right: 20px;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  top: 0;
  right: 0;
  color: #555555;
}

.compare-product .compare-remove::after, .compare-product .compare-remove::before {
  content: " ";
  position: absolute;
  right: 0;
  top: 50%;
  width: 15px;
  height: 2px;
  display: inline-block;
  transform: rotate(0);
  background-color: currentColor;
  -webkit-transition: background-color .2s ease-in-out,transform .2s ease-in-out,width .2s ease-in-out;
  -webkit-transition: background-color .2s ease-in-out,width .2s ease-in-out,-webkit-transform .2s ease-in-out;
  transition: background-color .2s ease-in-out,width .2s ease-in-out,-webkit-transform .2s ease-in-out;
  transition: background-color .2s ease-in-out,transform .2s ease-in-out,width .2s ease-in-out;
  transition: background-color .2s ease-in-out,transform .2s ease-in-out,width .2s ease-in-out,-webkit-transform .2s ease-in-out;
}

.compare-product .compare-remove:hover {
  color: #f2a100;
}

.compare-product .compare-remove:hover::before {
  transform: rotate(45deg);
  background-color: #f2a100;
}

.compare-product .compare-remove:hover::after {
  transform: rotate(-45deg);
  background-color: #f2a100;
}

/*--
/*  4.19 - Checkout CSS
/*----------------------------------------*/
.checkout-info {
  margin-top: 30px;
}

.checkout-info .info-header {
  padding: 14px 30px;
  margin: 0;
  position: relative;
  background-color: #eeeff2;
  list-style: none outside !important;
  width: auto;
  word-wrap: break-word;
  color: #000;
  font-size: 14px;
}

.checkout-info .info-header strong {
  font-weight: 700;
  color: #000;
}

.checkout-info .info-header i {
  font-size: 12px;
  color: #6dde6d;
  margin-right: 10px;
}

.checkout-info .info-header a {
  color: #000;
}

.checkout-info .info-header a:hover {
  color: #f2a100;
}

.checkout-info .info-header.error i {
  color: #c43002;
}

.checkout-info .card-body {
  padding: 0;
  padding-top: 20px;
}

.checkout-info .card-body p {
  font-size: 14px;
  color: #6d707f;
  margin-bottom: 0;
}

.checkout-info .card-body .single-form {
  margin-top: 15px;
}

.checkout-info .card-body .single-form .form-checkbox {
  margin-left: 20px;
}

.checkout-info .card-body .forget {
  margin-top: 15px;
}

.checkout-info .card-body .forget a {
  color: #6d707f;
  font-size: 15px;
}

.checkout-info .card-body .forget a:hover {
  color: #f2a100;
}

.checkout-title .title {
  font-size: 24px;
  color: #131313;
  position: relative;
}

.checkout-title .title::after {
  content: "";
  width: 50px;
  display: block;
  margin-top: 10px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-color: inherit;
}

.checkout-form {
  margin-top: 48px;
}

.checkout-account {
  display: none;
}

.checkout-shipping {
  display: none;
}

.checkout-note textarea {
  border: 0;
  background-color: #f7f7f7;
  height: 150px;
}

.checkout-order {
  border: 2px solid #e1e1e1;
  padding: 40px;
  margin-top: 50px;
}

@media only screen and (max-width: 767px) {
  .checkout-order {
    padding: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .checkout-order {
    padding: 20px;
  }
}

.checkout-order ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.checkout-order .table {
  margin-bottom: 0;
}

.checkout-order .table thead tr th {
  padding: 10px 0;
  border-top: 0;
  border-bottom: 1px solid #e1e1e1;
  font-weight: 400;
  font-size: 14px;
  color: #000;
  vertical-align: middle;
}

.checkout-order .table thead tr th.Product-price {
  text-align: right;
}

.checkout-order .table tbody tr td {
  padding: 5px 0;
  border-top: 0;
  vertical-align: middle;
}

.checkout-order .table tbody tr td p {
  font-weight: 400;
  font-size: 14px;
  color: #000;
}

.checkout-order .table tbody tr td.Product-price {
  text-align: right;
}

.checkout-order .table tbody tr:first-child td {
  padding-top: 20px;
}

.checkout-order .table tbody tr:last-child td {
  padding-bottom: 20px;
}

.checkout-order .table tfoot tr td {
  padding: 10px 0;
  vertical-align: middle;
}

.checkout-order .table tfoot tr td p {
  font-weight: 400;
  font-size: 14px;
  color: #000;
}

.checkout-order .table tfoot tr td.Product-price {
  text-align: right;
}

.checkout-order .table tfoot tr td.total-price {
  text-align: right;
}

.checkout-order .table tfoot tr td.total-price p {
  font-size: 18px;
}

.checkout-order .table tfoot tr td .radio label {
  white-space: nowrap;
  color: #252c51;
}

.checkout-payment {
  margin-top: 30px;
}

.checkout-payment ul li {
  margin-bottom: 3px;
}

.checkout-payment ul li :last-child {
  margin-bottom: 0;
}

.checkout-payment .single-payment .payment-radio [type="radio"] {
  position: relative;
  top: 2px;
  margin-right: 4px;
}

.checkout-payment .single-payment .payment-radio label {
  color: #252c51;
}

.checkout-payment .single-payment .payment-radio label .payment {
  width: 100px;
  padding: 0 2px;
}

.checkout-payment .single-payment .payment-radio label a {
  color: #000;
  text-decoration: underline;
  text-transform: capitalize;
}

.checkout-payment .single-payment .payment-radio .payment-details {
  display: none;
  padding: 10px 0;
}

.checkout-payment .single-payment .payment-radio .payment-details p {
  font-size: 14px;
}

.checkout-payment .checkout-btn {
  margin-top: 20px;
}

/*--
/*  4.20 - My Account CSS
/*----------------------------------------*/
.my-account-menu .account-menu-list {
  background-color: #f7f7f7;
}

.my-account-menu .account-menu-list li a {
  width: 100%;
  padding: 10px 30px;
  font-size: 14px;
  color: #6d707f;
  margin-top: -1px;
  transition: all 0.3s linear;
  display: block;
  text-decoration: none;
}

.my-account-menu .account-menu-list li a i {
  margin-right: 5px;
}

.my-account-menu .account-menu-list li a:hover, .my-account-menu .account-menu-list li a.active {
  background-color: #f2a100;
  color: #fff;
}

.my-account-tab .account-title {
  font-size: 24px;
  font-weight: 500;
  color: #000;
}

.account-table .table thead tr th {
  border: 1px solid #ECECEC;
  border-bottom-color: #ECECEC !important;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  padding: 8px 15px;
}

.account-table .table thead tr th.name {
  min-width: 140px;
}

.account-table .table thead tr th.date {
  min-width: 130px;
}

.account-table .table tbody tr td {
  font-size: 14px;
  color: #000;
  font-weight: 400;
  vertical-align: middle;
  border: 1px solid #ECECEC;
  padding: 0;
}

.account-table .table tbody tr td a {
  padding: 0 15px;
  height: 45px;
  line-height: 45px;
  color: #000;
}

.my-account-dashboard .welcome-dashboard {
  margin-top: 10px;
}

.my-account-dashboard .welcome-dashboard p {
  font-size: 14px;
}

.my-account-dashboard .welcome-dashboard p strong {
  font-weight: 500;
}

.my-account-dashboard .welcome-dashboard p a {
  color: #000;
  transition: 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .my-account-dashboard .welcome-dashboard p a {
    transition: none;
  }
}

.my-account-dashboard .welcome-dashboard p a:hover {
  color: #f2a100;
}

.my-account-payment p {
  font-weight: 500;
  font-size: 14px;
}

.my-account-address .account-address .name {
  font-size: 16px;
  color: #000;
  font-weight: 500;
}

.my-account-address .account-address p {
  margin-top: 20px;
  font-size: 14px;
}

.my-account-address .account-address .btn {
  margin-top: 20px;
  height: 45px;
  line-height: 45px;
}

.my-account-details .account-details {
  margin-top: -15px;
}

.my-account-details .account-details .title {
  font-size: 20px;
  font-weight: 500;
  color: #000;
}

/*--
/*  4.21 - Login & Register CSS
/*----------------------------------------*/
.login-register-wrapper .title {
  font-size: 24px;
  font-weight: 600;
  color: #494949;
}

.login-register-wrapper .form-check {
  margin-bottom: 0;
  min-height: inherit;
  margin-top: 30px;
}

.login-register-wrapper .form-check .form-check-input {
  margin-top: 4px;
}

.login-register-wrapper .single-form .btn {
  width: 160px;
}

.login-register-wrapper p {
  margin-bottom: 0;
  margin-top: 15px;
}

.login-register-wrapper p a {
  text-decoration: none;
}

/*--
/*  4.22 - Blog Details CSS
/*----------------------------------------*/
.blog-details-wrapper {
  margin-top: 50px;
}

.blog-details-wrapper img {
  width: 100%;
}

.blog-details-wrapper .blog-meta {
  display: flex;
  margin-top: 47px;
}

.blog-details-wrapper .blog-meta li {
  font-size: 15px;
  font-weight: 500;
  color: #545454;
  font-family: "Rajdhani", sans-serif;
  display: inherit;
}

.blog-details-wrapper .blog-meta li i {
  font-size: 18px;
  color: #000;
  margin-right: 8px;
}

.blog-details-wrapper .blog-meta li + li {
  margin-left: 20px;
}

.blog-details-wrapper .title {
  margin-bottom: 0;
  margin-top: 10px;
  display: inline-block;
  color: #383838;
  font-weight: 600;
  font-size: 36px;
  line-height: 1.4;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .title {
    font-size: 28px;
  }
}

.blog-details-wrapper p {
  margin-top: 25px;
  margin-bottom: 0;
}

.blog-details-wrapper .blog-dec-images {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-left: -15px;
  padding-top: 20px;
  padding-bottom: 25px;
}

.blog-details-wrapper .blog-dec-images .dec-col-1 {
  padding: 0 15px;
  width: 62.4%;
}

.blog-details-wrapper .blog-dec-images .dec-col-2 {
  padding: 0 15px;
  width: 37.6%;
}

.blog-details-wrapper .blog-dec-images .single-dec-img {
  margin-top: 30px;
}

.blog-details-wrapper .blog-dec-images .single-dec-img img {
  width: 100%;
}

.blog-auhtor-social {
  padding: 0px 20px 7px;
  background-color: #f2f2f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  flex-wrap: wrap;
}

.blog-auhtor-social .blog-auhtor {
  display: flex;
  align-items: center;
  padding-top: 7px;
}

.blog-auhtor-social .blog-auhtor .auhtor-thumb img {
  width: 36px;
  border-radius: 50%;
  border: 1px solid #f2a100;
}

.blog-auhtor-social .blog-auhtor .auhtor-content {
  padding-left: 15px;
}

.blog-auhtor-social .blog-auhtor .auhtor-content p {
  color: #777777;
  font-size: 14px;
  font-family: "Rajdhani", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

.blog-auhtor-social .blog-social {
  display: flex;
  margin-top: 4px;
}

.blog-auhtor-social .blog-social .social {
  display: flex;
}

.blog-auhtor-social .blog-social .social li + li {
  margin-left: 20px;
}

.blog-auhtor-social .blog-social .social li a {
  font-size: 14px;
  color: #000;
}

.blog-auhtor-social .blog-social .social li a:hover {
  color: #f2a100;
}

.blog-auhtor-social .blog-social .share-count {
  font-size: 14px;
  color: #000;
  margin-left: 40px;
}

@media only screen and (max-width: 575px) {
  .blog-auhtor-social .blog-social .share-count {
    margin-left: 20px;
  }
}

.blog-auhtor-social .blog-social .share-count:hover {
  color: #f2a100;
}

.blog-next-previous-post {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 575px) {
  .blog-next-previous-post {
    flex-wrap: wrap;
  }
}

.blog-next-previous-post .blog-previous-post,
.blog-next-previous-post .blog-next-post {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.blog-next-previous-post .blog-previous-post .post-arrow a,
.blog-next-previous-post .blog-next-post .post-arrow a {
  width: 85px;
  height: 85px;
  line-height: 85px;
  text-align: center;
  font-size: 30px;
  color: #f2a100;
  display: block;
  background-color: #f6f6f6;
}

@media only screen and (max-width: 767px) {
  .blog-next-previous-post .blog-previous-post .post-arrow a,
  .blog-next-previous-post .blog-next-post .post-arrow a {
    width: 75px;
    height: 75px;
    line-height: 75px;
  }
}

.blog-next-previous-post .blog-previous-post .post-content,
.blog-next-previous-post .blog-next-post .post-content {
  flex: 1;
  padding: 0 30px;
  max-width: 280px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 767px) {
  .blog-next-previous-post .blog-previous-post .post-content,
  .blog-next-previous-post .blog-next-post .post-content {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-next-previous-post .blog-previous-post .post-content,
  .blog-next-previous-post .blog-next-post .post-content {
    max-width: 230px;
  }
}

.blog-next-previous-post .blog-previous-post .post-content .title,
.blog-next-previous-post .blog-next-post .post-content .title {
  margin-bottom: 0;
}

.blog-next-previous-post .blog-previous-post .post-content .title a,
.blog-next-previous-post .blog-next-post .post-content .title a {
  font-size: 24px;
  font-weight: 700;
  color: #4a4a4a;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-next-previous-post .blog-previous-post .post-content .title a,
  .blog-next-previous-post .blog-next-post .post-content .title a {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-next-previous-post .blog-previous-post .post-content .title a,
  .blog-next-previous-post .blog-next-post .post-content .title a {
    font-size: 16px;
  }
}

.blog-next-previous-post .blog-previous-post .post-content .date,
.blog-next-previous-post .blog-next-post .post-content .date {
  font-size: 18px;
  font-weight: 500;
  font-family: "Rajdhani", sans-serif;
  color: #545454;
  text-transform: uppercase;
  display: block;
  margin-top: 7px;
}

.blog-next-previous-post .blog-previous-post:hover .post-arrow a,
.blog-next-previous-post .blog-next-post:hover .post-arrow a {
  color: #fff;
  background-color: #f2a100;
}

.blog-next-previous-post .blog-previous-post:hover .post-content .title a,
.blog-next-previous-post .blog-next-post:hover .post-content .title a {
  color: #f2a100;
}

@media only screen and (max-width: 575px) {
  .blog-next-previous-post .blog-next-post {
    margin-left: auto;
  }
}

.blog-next-previous-post .blog-next-post .post-content {
  text-align: right;
}

.blog-comment-form .comment-title {
  font-size: 24px;
  font-weight: 700;
  font-family: "Rajdhani", sans-serif;
  color: #000;
  margin-bottom: 0;
}

.blog-comment .comments-items {
  margin-top: -10px;
}

.blog-comment .comments-items > li:last-child .single-comment {
  padding-bottom: 0;
}

.blog-comment .single-comment {
  display: flex;
  padding: 40px 0;
}

@media only screen and (max-width: 575px) {
  .blog-comment .single-comment {
    display: block;
  }
}

.blog-comment .single-comment .comment-author img {
  border-radius: 50%;
}

.blog-comment .single-comment .comment-content {
  flex: 1;
  padding-left: 30px;
  position: relative;
}

@media only screen and (max-width: 575px) {
  .blog-comment .single-comment .comment-content {
    padding-left: 0;
    padding-top: 25px;
  }
}

.blog-comment .single-comment .comment-content .comment-name-date {
  display: flex;
  align-items: center;
}

.blog-comment .single-comment .comment-content .comment-name-date .name {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 0;
}

.blog-comment .single-comment .comment-content .comment-name-date .date {
  font-size: 14px;
  font-weight: 500;
  font-family: "Rajdhani", sans-serif;
  color: #666;
  text-transform: uppercase;
  flex: 1;
  display: block;
  padding-left: 15px;
  position: relative;
}

.blog-comment .single-comment .comment-content .comment-name-date .date::before {
  position: absolute;
  content: '-';
  font-size: 18px;
  font-family: "Rajdhani", sans-serif;
  font-weight: 600;
  color: #333;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.blog-comment .single-comment .comment-content p {
  margin-top: 15px;
  margin-bottom: 0;
}

.blog-comment .single-comment .comment-content .reply {
  position: absolute;
  top: 0;
  right: 0;
  color: #f2a100;
  font-size: 18px;
  font-weight: 500;
  font-family: "Rajdhani", sans-serif;
  display: inline-block;
}

@media only screen and (max-width: 575px) {
  .blog-comment .single-comment .comment-content .reply {
    position: relative;
    margin-top: 8px;
  }
}

.blog-comment .comments-reply {
  padding-left: 130px;
}

@media only screen and (max-width: 575px) {
  .blog-comment .comments-reply {
    padding-left: 0;
  }
}

.blog-comment .comments-reply .single-comment {
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}

/*--
/*  4.23 - Contact CSS
/*----------------------------------------*/
.contact-wrapper {
  background-color: #f5f5f5;
}

.contact-info {
  background-color: #fff9ed;
  padding: 55px 40px 60px;
  height: 100%;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-info {
    padding: 55px 30px 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-info {
    padding: 55px 40px 200px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-info {
    padding: 55px 40px 150px;
  }
}

@media only screen and (max-width: 575px) {
  .contact-info {
    padding: 55px 25px 150px;
  }
}

.contact-info .title {
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  color: #383838;
  margin-bottom: 0;
  padding-left: 1rem;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-info .title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .contact-info .title {
    font-size: 24px;
  }
}

.contact-info .title::before {
  position: absolute;
  content: '';
  width: 4px;
  height: 22px;
  background-color: #f2a100;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.contact-info > p {
  margin-bottom: 0;
  font-weight: 300;
  margin-top: 10px;
}

.contact-info .social {
  display: flex;
  margin-top: 25px;
}

.contact-info .social li + li {
  margin-left: 10px;
}

.contact-info .social li a {
  display: block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 16px;
  background-color: #ffedc8;
  color: #6c788c;
  border-radius: 50%;
}

.contact-info .social li a:hover {
  background-color: #f2a100;
  color: #fff;
}

.contact-info img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.contact-info-items {
  padding-top: 15px;
}

.contact-info-items .single-contact-info {
  display: flex;
  border: 1px solid transparent;
  padding: 8px 20px;
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 575px) {
  .contact-info-items .single-contact-info {
    padding: 8px 15px;
  }
}

.contact-info-items .single-contact-info .info-icon i {
  font-size: 30px;
  color: #f2a100;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .contact-info-items .single-contact-info .info-icon i {
    font-size: 24px;
  }
}

.contact-info-items .single-contact-info .info-content {
  padding-left: 15px;
  flex: 1;
}

.contact-info-items .single-contact-info .info-content p {
  font-family: "Rajdhani", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #6c788c;
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-info-items .single-contact-info .info-content p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 575px) {
  .contact-info-items .single-contact-info .info-content p {
    font-size: 14px;
  }
}

.contact-info-items .single-contact-info:hover {
  border-color: #ffedc8;
}

.contact-form {
  padding: 60px;
  padding-top: 30px;
}

@media only screen and (max-width: 575px) {
  .contact-form {
    padding: 30px;
    padding-top: 0px;
  }
}

.contact-form .single-form input:not([type="checkbox"]):not([type="radio"]),
.contact-form .single-form textarea {
  background-color: transparent;
}

.contact-form .single-form textarea {
  height: 145px;
}

.contact-form .single-form .btn {
  height: 60px;
  line-height: 60px;
  padding: 0 50px;
  font-size: 14px;
  font-weight: 500;
}

@media only screen and (max-width: 575px) {
  .contact-form .single-form .btn {
    height: 50px;
    line-height: 50px;
    padding: 0 30px;
  }
}

.form-message {
  margin-bottom: 0;
}

.form-message.error, .form-message.success {
  font-size: 16px;
  color: #07294d;
  background: #ddd;
  padding: 10px 15px;
  margin: 15px 0 0 15px;
}

.form-message.error {
  color: #ff0000;
}

.contact-map iframe {
  width: 100%;
  height: 490px;
  display: block;
}

/*----------------------------------------*/
/*  05. Widget CSS
/*----------------------------------------*/
/*--
/*  5.1 - Footer Widget CSS
/*----------------------------------------*/
.footer-widget-section {
  background-color: #f1eee5;
  padding-top: 30px;
  padding-bottom: 75px;
}

.footer-widget-title {
  color: #252c51;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 0px;
  padding-bottom: 10px;
}

.footer-widget {
  margin-top: 45px;
}

.footer-widget .widget-about p {
  padding-top: 3px;
}

.footer-widget .widget-about p a {
  font-weight: 500;
  font-size: 22px;
  color: #252c51;
}

.footer-widget .widget-about p a:hover {
  color: #f2a100;
}

.footer-widget .widget-social {
  padding-top: 20px;
}

.footer-widget .widget-social ul {
  display: flex;
}

.footer-widget .widget-social ul li + li {
  margin-left: 30px;
}

.footer-widget .widget-social ul li a {
  font-size: 16px;
  color: #252c51;
  transition: all 0.3s ease 0s;
}

.footer-widget .widget-social ul li a:hover {
  color: #f2a100;
}

.footer-widget .footer-link li {
  margin-top: 3px;
}

.footer-widget .footer-link li a {
  font-size: 16px;
  color: #6d707f;
  transition: all 0.3s ease 0s;
}

.footer-widget .footer-link li a:hover {
  color: #f2a100;
}

.footer-widget-link {
  display: flex;
  justify-content: space-around;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .footer-widget-link {
    justify-content: flex-end;
    flex-wrap: wrap;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .footer-widget-link .footer-widget {
    width: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .footer-widget-link .footer-widget {
    width: 100%;
  }
}

/*--
/*  5.2 - Sidebar Widget CSS
/*----------------------------------------*/
.sidebar-widget {
  margin-top: 50px;
}

.widget-title {
  font-size: 24px;
  font-weight: 600;
  color: #717171;
  margin-bottom: 0;
  margin-top: -5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .widget-title {
    font-size: 22px;
  }
}

.widget-search {
  position: relative;
}

.widget-search input {
  width: 100%;
  height: 60px;
  padding: 0 15px;
  padding-right: 60px;
  border: 1px solid #ececec;
  font-size: 16px;
  font-weight: 500;
  font-family: "Rajdhani", sans-serif;
  color: #969696;
  transition: all 0.3s linear;
  border-radius: 0;
  background-color: #fff;
}

.widget-search input:focus {
  outline: none;
  border-color: #f2a100;
}

.widget-search input::-webkit-input-placeholder {
  opacity: 1;
}

.widget-search input:-moz-placeholder {
  opacity: 1;
}

.widget-search input::-moz-placeholder {
  opacity: 1;
}

.widget-search input:-ms-input-placeholder {
  opacity: 1;
}

.widget-search button {
  position: absolute;
  top: 0px;
  right: 0px;
  border: 0;
  padding: 0;
  width: 50px;
  height: 60px;
  color: #969696;
  text-align: center;
  background: none;
  transition: all 0.3s ease 0s;
}

.widget-search button::before {
  position: absolute;
  content: '';
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 1px;
  height: 25px;
  background-color: #ececec;
}

.widget-checkbox {
  margin-top: 17px;
}

.widget-checkbox .checkbox-items li + li {
  margin-top: 13px;
}

.widget-checkbox .checkbox-items li input[type="checkbox"],
.widget-checkbox .checkbox-items li input[type="radio"] {
  display: none;
}

.widget-checkbox .checkbox-items li input[type="checkbox"] + label,
.widget-checkbox .checkbox-items li input[type="radio"] + label {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 16px;
  color: #666666;
  font-family: "Rajdhani", sans-serif;
  cursor: pointer;
}

.widget-checkbox .checkbox-items li input[type="checkbox"] + label span,
.widget-checkbox .checkbox-items li input[type="radio"] + label span {
  width: 15px;
  height: 15px;
  border-radius: 3px;
  border: 2px solid #888888;
  display: inline-block;
  position: relative;
  top: 2px;
  margin-right: 13px;
  transition: all 0.3s ease 0s;
}

.widget-checkbox .checkbox-items li input[type="checkbox"] + label span::before,
.widget-checkbox .checkbox-items li input[type="radio"] + label span::before {
  position: absolute;
  content: "\f107";
  font-family: FontAwesome;
  font-size: 14px;
  width: 15px;
  height: 15px;
  line-height: 15px;
  top: -2px;
  left: -2px;
  text-align: center;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
}

.widget-checkbox .checkbox-items li input[type="checkbox"]:checked + label span,
.widget-checkbox .checkbox-items li input[type="radio"]:checked + label span {
  background-color: #ffb300;
  border-color: #ffb300;
}

.widget-checkbox .checkbox-items li input[type="checkbox"]:checked + label span::before,
.widget-checkbox .checkbox-items li input[type="radio"]:checked + label span::before {
  opacity: 1;
  visibility: visible;
}

.widget-categories {
  height: 210px;
  overflow-y: scroll;
}

.widget-categories::-webkit-scrollbar-track {
  background-color: #ebebeb;
  border-radius: 10px;
}

.widget-categories::-webkit-scrollbar {
  width: 5px;
  background-color: #ebebeb;
  border-radius: 10px;
}

.widget-categories::-webkit-scrollbar-thumb {
  background-color: #f2a100;
  border-radius: 10px;
}

.widget-price {
  margin-top: 20px;
}

.widget-price .irs .irs-min,
.widget-price .irs .irs-max {
  display: none;
}

.widget-price .irs .irs-from,
.widget-price .irs .irs-to,
.widget-price .irs .irs-single {
  color: #656565;
  font-size: 16px;
  font-weight: 600;
  font-family: "Rajdhani", sans-serif;
  padding: 0;
  background: none;
  border-radius: 0;
  top: -5px;
}

.widget-price .irs .irs-from::before,
.widget-price .irs .irs-to::before,
.widget-price .irs .irs-single::before {
  display: none;
}

.widget-price .irs .irs-line {
  height: 4px;
  background-color: #ebebeb;
}

.widget-price .irs .irs-bar {
  height: 4px;
  background-color: #ffb300;
}

.widget-price .irs .irs-handle {
  width: 15px;
  height: 15px;
  background-color: #fff;
  border: 3px solid #ffb300;
  border-radius: 50%;
  top: 19px;
  cursor: pointer;
}

.widget-price .irs .irs-handle i {
  display: none;
}

.color-blue {
  background-color: #0d6efd !important;
  border-color: #0d6efd !important;
}

.color-blue::before {
  border-color: #0d6efd;
  color: #fff;
}

.color-dark-blue {
  background-color: #004a80 !important;
  border-color: #004a80 !important;
}

.color-dark-blue::before {
  border-color: #004a80;
  color: #fff;
}

.color-lower-blue {
  background-color: #8194d9 !important;
  border-color: #8194d9 !important;
}

.color-lower-blue::before {
  border-color: #8194d9;
  color: #fff;
}

.color-indigo {
  background-color: #6610f2 !important;
  border-color: #6610f2 !important;
}

.color-indigo::before {
  border-color: #6610f2;
  color: #fff;
}

.color-purple {
  background-color: #6f42c1 !important;
  border-color: #6f42c1 !important;
}

.color-purple::before {
  border-color: #6f42c1;
  color: #fff;
}

.color-pink {
  background-color: #d63384 !important;
  border-color: #d63384 !important;
}

.color-pink::before {
  border-color: #d63384;
  color: #fff;
}

.color-red {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.color-red::before {
  border-color: #dc3545;
  color: #fff;
}

.color-orange {
  background-color: #fd7e14 !important;
  border-color: #fd7e14 !important;
}

.color-orange::before {
  border-color: #fd7e14;
  color: #fff;
}

.color-yellow {
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}

.color-yellow::before {
  border-color: #ffc107;
  color: #fff;
}

.color-green {
  background-color: #198754 !important;
  border-color: #198754 !important;
}

.color-green::before {
  border-color: #198754;
  color: #fff;
}

.color-teal {
  background-color: #20c997 !important;
  border-color: #20c997 !important;
}

.color-teal::before {
  border-color: #20c997;
  color: #fff;
}

.color-cyan {
  background-color: #0dcaf0 !important;
  border-color: #0dcaf0 !important;
}

.color-cyan::before {
  border-color: #0dcaf0;
  color: #fff;
}

.color-white {
  background-color: #fff !important;
  border-color: #fff !important;
}

.color-white::before {
  border-color: #fff;
  color: #fff;
}

.color-gray {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

.color-gray::before {
  border-color: #6c757d;
  color: #fff;
}

.color-gray-dark {
  background-color: #343a40 !important;
  border-color: #343a40 !important;
}

.color-gray-dark::before {
  border-color: #343a40;
  color: #fff;
}

.widget-tags .tags-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -7px;
  margin-right: -7px;
  padding-top: 5px;
}

.widget-tags .tags-list li {
  padding: 15px 7px 0;
}

.widget-tags .tags-list li a {
  height: 35px;
  line-height: 35px;
  padding: 0 30px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Rajdhani", sans-serif;
  background-color: #f6f6f6;
  display: block;
  color: #7e7e7e;
  transition: all 0.3s ease 0s;
  border-radius: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .widget-tags .tags-list li a {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 575px) {
  .widget-tags .tags-list li a {
    padding: 0 20px;
  }
}

.widget-tags .tags-list li a:hover {
  background-color: #f2a100;
  color: #fff;
}

.sidebar-widget-02 {
  margin-top: 50px;
  padding: 30px;
  border: 1px solid #f1f1f1;
}

.widget-title-02 {
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  color: #383838;
  margin-bottom: 0;
  padding-left: 1rem;
}

.widget-title-02::before {
  position: absolute;
  content: '';
  width: 2px;
  height: 14px;
  background-color: #f2a100;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.widget-search-02 {
  position: relative;
}

.widget-search-02 input {
  width: 100%;
  height: 50px;
  padding: 0 15px;
  padding-right: 75px;
  border: 1px solid #d9d9d9;
  font-size: 14px;
  font-weight: 500;
  font-family: "Rajdhani", sans-serif;
  color: #919090;
  transition: all 0.3s linear;
  border-radius: 0;
  background-color: #fff;
}

.widget-search-02 input:focus {
  outline: none;
  border-color: #f2a100;
}

.widget-search-02 input::-webkit-input-placeholder {
  opacity: 1;
}

.widget-search-02 input:-moz-placeholder {
  opacity: 1;
}

.widget-search-02 input::-moz-placeholder {
  opacity: 1;
}

.widget-search-02 input:-ms-input-placeholder {
  opacity: 1;
}

.widget-search-02 button {
  position: absolute;
  top: 0px;
  right: 0px;
  border: 0;
  padding: 0;
  width: 64px;
  height: 50px;
  color: #fff;
  text-align: center;
  background: none;
  transition: all 0.3s ease 0s;
  background-color: #f2a100;
}

.widget-recent-post {
  padding-top: 5px;
}

.single-recent-post {
  padding: 25px 0;
  border-bottom: 1px dashed #f1f1f1;
}

.single-recent-post:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}

.single-recent-post .blog-meta {
  display: flex;
}

.single-recent-post .blog-meta li {
  font-size: 15px;
  font-weight: 500;
  color: #545454;
  font-family: "Rajdhani", sans-serif;
  display: inherit;
}

.single-recent-post .blog-meta li i {
  font-size: 18px;
  color: #000;
  margin-right: 8px;
}

.single-recent-post .blog-meta li + li {
  margin-left: 20px;
}

.single-recent-post .title {
  margin-bottom: 0;
}

.single-recent-post .title a {
  margin-top: 8px;
  display: inline-block;
  color: #383838;
  font-weight: 600;
  font-size: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-recent-post .title a {
    font-size: 16px;
  }
}

.single-recent-post .title a:hover {
  color: #f2a100;
}

.widget-link {
  padding-top: 12px;
}

.widget-link li {
  padding: 10px 0;
  border-bottom: 1px dashed #f1f1f1;
}

.widget-link li:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}

.widget-link li a {
  font-size: 18px;
  font-weight: 500;
  color: #545454;
  font-family: "Rajdhani", sans-serif;
  transition: all 0.3s ease 0s;
}

.widget-link li a:hover {
  color: #f2a100;
}

.widget-subscribe {
  margin-top: 30px;
}

.widget-subscribe input {
  width: 100%;
  height: 50px;
  padding: 0 20px;
  font-size: 14px;
  font-family: "Rajdhani", sans-serif;
  font-weight: 600;
  color: #c0c0c0;
  transition: all 0.3s ease 0s;
  border: 1px solid #d7d7d7;
  border-radius: 0;
  background-color: #fff;
  text-align: center;
}

.widget-subscribe input::-webkit-input-placeholder {
  opacity: 1;
}

.widget-subscribe input:-moz-placeholder {
  opacity: 1;
}

.widget-subscribe input::-moz-placeholder {
  opacity: 1;
}

.widget-subscribe input:-ms-input-placeholder {
  opacity: 1;
}

.widget-subscribe input:focus {
  border-color: #f2a100;
  outline: none;
}

.widget-subscribe .btn {
  height: 50px;
  line-height: 50px;
  padding: 0 40px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  border-radius: 0;
  margin-top: 1rem;
  width: 100%;
}

.widget-admin {
  text-align: center;
}

.widget-admin img {
  width: 100%;
}

.widget-admin .admin-name {
  font-size: 18px;
  font-weight: 600;
  color: #2e2a31;
  margin-bottom: 0;
  margin-top: 22px;
}

.widget-admin .designation {
  font-size: 16px;
  font-weight: 600;
  font-family: "Rajdhani", sans-serif;
  color: #5f5f5f;
  display: block;
}

.widget-admin .signechar {
  width: auto;
}

/*----------------------------------------*/
/*  06. Footer CSS
/*----------------------------------------*/
.footer-top {
  background-color: #e9e1f7;
  padding-top: 10px;
  padding-bottom: 40px;
}

.footer-logo {
  margin-top: 30px;
}

.footer-contact-payment {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .footer-contact-payment {
    display: block;
  }
}

.footer-contact-payment .footer-contact {
  display: flex;
  margin-top: 30px;
}

.footer-contact-payment .footer-contact .contact-content {
  flex: 1;
  padding-left: 20px;
  margin-bottom: -5px;
}

.footer-contact-payment .footer-contact .contact-content .title {
  font-size: 24px;
  color: #252c51;
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 0;
}

.footer-contact-payment .footer-contact .contact-content p {
  font-size: 24px;
  color: #252c51;
  font-weight: 600;
  font-family: "Rajdhani", sans-serif;
  line-height: 1.1;
}

.footer-contact-payment .footer-payment {
  margin-top: 30px;
}

.footer-subscribe {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
}

@media only screen and (max-width: 575px) {
  .footer-subscribe {
    display: block;
  }
}

.footer-subscribe .title {
  font-size: 24px;
  font-weight: 600;
  font-family: "Rajdhani", sans-serif;
  color: #252c51;
  white-space: nowrap;
  padding-right: 15px;
}

.footer-subscribe .subscribe-form {
  position: relative;
  max-width: 276px;
  width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .footer-subscribe .subscribe-form {
    max-width: 100%;
  }
}

.footer-subscribe .subscribe-form input {
  width: 100%;
  height: 46px;
  padding: 0 15px;
  padding-right: 60px;
  background-color: #f4edff;
  font-family: "Rajdhani", sans-serif;
  font-size: 14px;
  color: #919090;
  border: 1px solid #f4edff;
  font-weight: 600;
  outline: none;
  transition: all 0.3s ease 0s;
}

.footer-subscribe .subscribe-form input::-webkit-input-placeholder {
  opacity: 1;
  color: #919090;
}

.footer-subscribe .subscribe-form input:-moz-placeholder {
  opacity: 1;
  color: #919090;
}

.footer-subscribe .subscribe-form input::-moz-placeholder {
  opacity: 1;
  color: #919090;
}

.footer-subscribe .subscribe-form input:-ms-input-placeholder {
  opacity: 1;
  color: #919090;
}

.footer-subscribe .subscribe-form input:focus {
  border-color: #f2a100;
}

.footer-subscribe .subscribe-form button {
  width: 46px;
  height: 46px;
  line-height: 50px;
  padding: 0;
  border: 0;
  background-color: #f2a100;
  color: #fff;
  font-size: 26px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
}

.copyright {
  background-color: #fff;
}

.copyright-text {
  text-align: center;
  padding: 12px 0;
}

.copyright-text p {
  color: #8690a1;
  font-size: 16px;
  font-weight: 300;
}

.copyright-text p span {
  color: #f2a100;
  text-transform: uppercase;
}

.copyright-text p i {
  color: #ff0000;
}

.copyright-text p a {
  color: #f2a100;
  transition: all 0.3s ease 0s;
  font-weight: 500;
}

.copyright-text p a:hover {
  color: #f2a100;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 30px;
  width: 40px;
  height: 40px;
  line-height: 45px;
  border-radius: 50%;
  z-index: 99;
  text-align: center;
  display: none;
  box-shadow: 0px 0px 30px 0px rgba(156, 156, 156, 0.25);
  transition: all 0.3s linear;
  color: #fff;
  background-color: #f2a100;
}

.back-to-top:hover {
  color: #fff;
  background-color: #494949;
}
