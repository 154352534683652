/*--
/*  4.13 - Gallery CSS
/*----------------------------------------*/


.image-gallery{
    margin-top: 30px;

    & img{
        width: 100%;
    }
}





